import {
  endOfWeek,
  format,
  formatDistance,
  intervalToDuration,
} from 'date-fns';
import { TFunction } from 'i18next';
import { padStart } from 'lodash';

import { SHORT_DAYS_OF_WEEK } from 'features/reports/constants';
import { TimeGranularity } from 'features/reports/utils';
import { getDateFnsLocale } from 'packages/i18n';

const getGranularityDateOptions = (
  date: Date,
  granularity?: TimeGranularity
): Intl.DateTimeFormatOptions & {
  endPeriod?: Date;
  isLongFormat?: boolean;
} => {
  switch (granularity) {
    case 'day':
      return {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
    case 'week':
      return {
        endPeriod: endOfWeek(date, { weekStartsOn: 1 }),
        day: 'numeric',
        month: 'short',
      };
    case 'month':
      return {
        year: '2-digit',
        month: 'short',
      };
    default:
      return {
        isLongFormat: true,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
  }
};

export const formatDatetime = (
  timestamp: string | number,
  granularity?: TimeGranularity
) => {
  const date = new Date(timestamp);
  const dateOptions = getGranularityDateOptions(date, granularity);

  // Get the default locale from the browser
  const defaultLocale = navigator.language;

  // Format the date and time in needed format with the default locale
  const formattedDate = `${date.toLocaleDateString(
    defaultLocale,
    dateOptions
  )}${
    dateOptions.endPeriod
      ? ` - ${dateOptions.endPeriod.toLocaleDateString(
          defaultLocale,
          dateOptions
        )}`
      : ''
  }`;

  const formattedTime =
    dateOptions.isLongFormat &&
    date.toLocaleTimeString(defaultLocale, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Use 24-hour format
    });

  return dateOptions.isLongFormat
    ? `${formattedTime}, ${formattedDate}`
    : formattedDate;
};

export const formatNumber = (value: number) => {
  const formattedNumber = new Intl.NumberFormat().format(value);
  return formattedNumber;
};

export const toDistanceFromNowString = (
  date: number | Date,
  language: string
) =>
  formatDistance(date, new Date(), {
    addSuffix: true,
    locale: getDateFnsLocale(language),
  });

export const toShortDateTime = (date: number | Date, language: string) =>
  format(date, 'd MMM HH:mm', {
    locale: getDateFnsLocale(language),
  });

export const toShortDate = (date: number | Date, language: string) =>
  format(date, 'EEE dd MMM', { locale: getDateFnsLocale(language) });

export const toLongDate = (date: number | Date, language: string) =>
  format(date, 'EEEE do MMMM', { locale: getDateFnsLocale(language) });

export const secondsToDurationString = (s: number) => {
  if (s === 0) return '0s';

  const { years, months, weeks, days, hours, minutes, seconds } =
    intervalToDuration({
      start: 0,
      end: s * 1000,
    });

  const yearsString = years && years > 0 ? `${years}y ` : '';
  const monthsString = months && months > 0 ? `${months}M ` : '';
  const weeksString = weeks && weeks > 0 ? `${weeks}w ` : '';
  const daysString = days && days > 0 ? `${days}d ` : '';
  const hoursString = hours && hours > 0 ? `${hours}h ` : '';
  const minutesString = minutes && minutes > 0 ? `${minutes}m ` : '';
  const secondsString = seconds && seconds > 0 ? `${seconds}s` : '';

  return [
    yearsString,
    monthsString,
    weeksString,
    daysString,
    hoursString,
    minutesString,
    secondsString,
  ]
    .join('')
    .trim();
};

export type FormatType =
  | 'duration'
  | 'number'
  | 'percent'
  | 'float'
  | 'hour'
  | 'day';

export const valueFormatters: Record<
  FormatType,
  (value: number, t: TFunction) => string
> = {
  duration: (value) => secondsToDurationString(value),
  number: (value) => value.toLocaleString(),
  percent: (value) => `${value.toFixed(0)}%`,
  float: (value) => value.toFixed(2),
  hour: (value) => `${padStart(value.toString(), 2, '0')}:00`,
  day: (value, t) => t(SHORT_DAYS_OF_WEEK[value]),
};
