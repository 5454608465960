import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { useSignIn as useSignInAuthKit } from 'react-auth-kit';

import { getUserIdFromTokenString } from 'api/utils';
interface TokenResponse {
  access: string;
  refresh: string;
}
export const useSignIn = () => {
  const signIn = useSignInAuthKit();

  return (tokenResponse: AxiosResponse<TokenResponse>) => {
    signIn({
      token: tokenResponse.data.access,
      expiresIn: 60 * 24 * 7,
      tokenType: 'Bearer',
      authState: {},
      refreshToken: tokenResponse.data.refresh,
      refreshTokenExpireIn: 30,
    });
    // TODO: call `Sentry.setUser(null)` upon signing out (intentionally or not)
    Sentry.setUser({ id: getUserIdFromTokenString(tokenResponse.data.access) });
  };
};
