import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import * as htmlToImage from 'html-to-image';
import noop from 'lodash/noop';
import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigurableChartOptions } from '..';
import { ConfigurableChartDeleteMenuItem } from '../configurable-chart-delete-menu-item';
import { ConfigurableChartDuplicateMenuItem } from '../configurable-chart-duplicate-menu-item';
import { ConfigurableChartRenameMenuItem } from '../configurable-chart-rename-menu-item';
import { onDownloadCSV } from '../utils';

import { QueryResult } from 'cube';
import { Column, download } from 'features/reports/utils';

interface ActionsProps {
  areOptionsOpen?: boolean;
  onAreOptionsOpenChange: (value: boolean) => void;
  options: ConfigurableChartOptions;
  id: string;
  title: string;
  onTitleChange?: (title: string) => void;
  onDuplicateChart?: () => void;
  onDeleteChart?: (id: string) => void;
  columns: Column[];
  data?: QueryResult;
  chartRef: RefObject<HTMLDivElement>;
}
export const Actions = ({
  areOptionsOpen,
  onAreOptionsOpenChange,
  options,
  id,
  title,
  onTitleChange = noop,
  onDuplicateChart = noop,
  onDeleteChart = noop,
  columns,
  data,
  chartRef,
}: ActionsProps) => {
  const { t } = useTranslation('reports');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onDownloadSnapshot = async () => {
    const chartElement = chartRef.current;
    if (!chartElement) {
      return null;
    }
    const dataUrl = await htmlToImage.toPng(chartElement, {
      filter: (node: HTMLElement) => {
        return !['chart-options', 'MuiButtonBase-root'].some((className) =>
          node.classList?.contains(className)
        );
      },
    });
    download(dataUrl, `${title}.png`);
  };

  const onDownloadData = () => {
    if (!data) {
      return;
    }
    onDownloadCSV({
      t,
      rawData: data.rawData(),
      columns,
      title,
      unit: options.granularity,
    });
  };

  const onDownload = async () => {
    if (options.type === 'table') {
      onDownloadData();
    } else {
      await onDownloadSnapshot();
    }
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={() => onAreOptionsOpenChange(!areOptionsOpen)}
        data-cy="options-visibility-toggle"
      >
        {areOptionsOpen && t('Hide options')}
        {!areOptionsOpen && t('Show options')}
      </Button>
      <IconButton
        color="primary"
        onClick={onDownload}
        data-cy="file-download-icon"
      >
        <FileDownloadIcon />
      </IconButton>
      <IconButton
        data-cy="chart-more-button"
        color="primary"
        onClick={(event) => handleMoreClick(event)}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`chart-menu-${id}`}
        data-cy={`chart-menu-${id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onBlur={handleMenuClose}
        onClick={handleMenuClose}
        keepMounted
      >
        <ConfigurableChartRenameMenuItem
          title={title}
          onTitleChange={onTitleChange}
        />
        <MenuItem data-cy="chart-menu-download" onClick={onDownloadData}>
          {t('Download data')}
        </MenuItem>
        <ConfigurableChartDuplicateMenuItem onDuplicate={onDuplicateChart} />
        <ConfigurableChartDeleteMenuItem
          title={title}
          onDelete={() => onDeleteChart(id)}
        />
      </Menu>
    </>
  );
};
