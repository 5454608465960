import { Box, alpha, useTheme } from '@mui/material';
import { ScriptableContext, TooltipItem, defaults } from 'chart.js';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { Bar, Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { Column, TimeGranularity, getFormattedValue } from '../utils';

import { LoadingBackdrop } from 'design-system/components';
import { formatDatetime } from 'design-system/formatting';

interface CubeChartProps {
  height?: number;
  isLoading: boolean;
  labels: string[];
  datasets: {
    label: string;
    data: unknown[];
    serieColumn?: Column;
  }[];
  unit?: TimeGranularity;
  type: 'line' | 'bar';
}

type ExtendedTooltipContext = (TooltipItem<'line'> | TooltipItem<'bar'>) & {
  dataset: {
    serieColumn?: Column;
  };
};

const palette = [
  '#7E2CA2',
  '#00A983',
  '#EE6677',
  '#CCBB44',
  '#123C4A',
  '#66CCEE',
  '#B3F506',
  '#0081A1',
];

const getColor = (index: number) => palette[index % palette.length];

const getYAxisLabel = (format: string) => {
  switch (format) {
    case 'duration':
      return 'Minutes';
    case 'percent':
      return '%';
    default:
      return '';
  }
};

export const CubeChart = ({
  height,
  isLoading,
  labels,
  datasets,
  unit,
  type,
}: CubeChartProps) => {
  const { t } = useTranslation('reports');
  const theme = useTheme();

  defaults.font.family = theme.typography.fontFamily;
  const dataFormat = datasets[0]?.serieColumn?.format;

  const isLineChart = type === 'line';
  const ChartComponent = isLineChart ? Line : Bar;

  return (
    <>
      <Box position="relative">
        {isLoading && <LoadingBackdrop />}
        <ChartComponent
          data-cy={`${type}-chart`}
          height={height}
          data={{
            labels,
            datasets: datasets.map((dataset, index) => ({
              ...dataset,
              borderColor: getColor(index),
              backgroundColor: getColor(index),
            })),
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: unit
                ? {
                    time: { unit },
                    type: 'time',
                    adapters: {
                      date: 'date-fns',
                    },
                  }
                : {
                    type: 'category',
                  },
              y: {
                type: 'linear',
                grid: {
                  drawTicks: true,
                  drawOnChartArea: false,
                },
                ticks: {
                  callback: (value) => {
                    return dataFormat === 'duration'
                      ? Math.floor(Number(value) / 60)
                      : value;
                  },
                },
              },
            },
            plugins: {
              colors: {
                enabled: false,
              },
              tooltip: {
                enabled: true,
                backgroundColor: '#000', // Black background for the tooltip
                titleColor: '#fff', // White text for the title
                bodyColor: '#fff', // White text for the body
                titleFont: {
                  size: 12,
                  lineHeight: '18.84px',
                  weight: 'bold',
                },
                bodyFont: {
                  size: 12,
                  lineHeight: '17.64px',
                },
                caretPadding: 20,
                caretSize: 0,
                position: 'average',
                padding: 10,
                cornerRadius: 4,
                displayColors: datasets.length > 1,
                usePointStyle: true,
                boxPadding: 8,
                callbacks: {
                  title: (context: ExtendedTooltipContext[]) => {
                    // Use x-axis value that equals required timestamp for tooltip title
                    return unit
                      ? formatDatetime(context[0].parsed.x, unit)
                      : context[0].label;
                  },
                  label: (context: ExtendedTooltipContext) => {
                    // Customize the label here based on the context
                    const column = context.dataset.serieColumn;
                    const formattedValue = column
                      ? getFormattedValue(context.raw, column, t, unit)
                      : context.formattedValue;
                    return `${context.dataset.label}: ${formattedValue}`;
                  },
                },
              },
              legend: {
                position: 'bottom',
                align: 'center',
                labels: {
                  color: theme.palette.text.secondary,
                  font: {
                    size: 12,
                    lineHeight: '16.8px', // doesn't seem to have any effect
                  },

                  // can't use theme.spacing() here as it expects a number
                  padding: 24,

                  boxWidth: 10,
                  boxHeight: 10,
                  usePointStyle: true,

                  boxPadding: 16,
                },
              },
              title: {
                display: true,
                text: dataFormat && getYAxisLabel(dataFormat),
                color: theme.palette.text.secondary,
                font: {
                  weight: 'normal',
                },
                align: 'start',
                padding: {
                  bottom: 20,
                },
              },
            },
            interaction: {
              mode: 'index',
              intersect: false,
            },
            hover: {
              mode: 'index',
              intersect: false,
            },
            elements: {
              point: {
                radius: 0,
                borderWidth: 0,

                hoverRadius: 6,
                hoverBorderWidth: 4,
                hoverBorderColor: `#FFFFFFD0`,
              },
              line: {
                borderWidth: 2,
                backgroundColor: 'red',
                fill: (context: unknown) => {
                  const typedContext = context as ScriptableContext<'line'>;

                  return {
                    target: datasets.length === 1,
                    above: alpha(
                      typedContext.dataset.backgroundColor?.toString() ||
                        'transparent',
                      0.08
                    ),
                    below: 'transparent',
                  };
                },
              },
              bar: {
                // adding the type to the context directly results in a type error
                // hoverBackgroundColor is expecting ScriptableContext<'line'>
                // because the chart could be either a line or a bar chart
                hoverBackgroundColor: (context: unknown): string => {
                  const typedContext = context as ScriptableContext<'bar'>;

                  return alpha(
                    typedContext.dataset.backgroundColor?.toString() ||
                      'transparent',
                    0.5
                  );
                },
              },
            },
          }}
        />
      </Box>
    </>
  );
};
