import { Series } from '@cubejs-client/core';
import { TFunction } from 'i18next';

import { formatLabel, getFormattedValue } from '../utils';

import { ConfigurableChartFilters } from './configurable-chart';

import { ConfigurableChartOptions } from '.';

import { CubeSchema, RawDataRow, queryBuilder } from 'cube';
import {
  Column,
  DEFAULT_SCHEMA,
  TimeGranularity,
  download,
} from 'features/reports/utils';

export const useBuilder = (
  filters: ConfigurableChartFilters,
  options: ConfigurableChartOptions
) => {
  const { granularity, measures, dimension, segment } = options;
  const { timeRange, buildings, workingHours } = filters;
  const buildingIds = buildings ?? [];

  const builder = queryBuilder<CubeSchema>(DEFAULT_SCHEMA);

  if (measures) {
    measures.forEach((m) => builder.addMeasure(m));
  }

  if (buildingIds?.length) {
    builder.addFilter(
      DEFAULT_SCHEMA.DIMENSIONS.BUILDING_ID.name,
      'contains',
      buildingIds
    );
  }

  if (workingHours !== 'all') {
    if (workingHours === 'building_specific') {
      builder.addFilter(
        DEFAULT_SCHEMA.DIMENSIONS.WORKING_HOURS.name,
        'equals',
        [true]
      );
    } else if (workingHours === 'weekdays') {
      builder.addFilter(
        DEFAULT_SCHEMA.DIMENSIONS.TIMESTAMP_DAY_OF_WEEK.name,
        'equals',
        [1, 2, 3, 4, 5]
      );
    }
  }

  /**
   * Time is a special kind of dimension, because it's both how we can:
   * - breakdown a measure (how many X per day)
   * - group data per hour/day/month/...
   * - filter the time period (how many X from A to B) we show data for
   *
   * The first 2 only make sense if time is the selected dimension, but the filtering should always be applied.
   */
  if (dimension) {
    if (
      dimension === DEFAULT_SCHEMA.TIME_DIMENSIONS.TIMESTAMP_QUARTER_HOUR.name
    ) {
      builder.addTimeDimension(dimension, granularity, [
        timeRange.timeFrom,
        timeRange.timeTo,
      ]);
    } else {
      builder.addDimension(dimension);
      builder.addFilter(
        DEFAULT_SCHEMA.TIME_DIMENSIONS.TIMESTAMP_QUARTER_HOUR.name,
        'inDateRange',
        [timeRange.timeFrom, timeRange.timeTo]
      );
    }
  }

  if (dimension) {
    // this works for the heatmap - will need refining further what the logic should be
    if (
      dimension === DEFAULT_SCHEMA.DIMENSIONS.TIMESTAMP_DAY_OF_WEEK.name &&
      !!segment
    ) {
      builder.addOrderBy(dimension, 'desc');
    } else {
      builder.addOrderBy(dimension, 'asc');
    }
  }

  if (segment) {
    builder.addDimension(segment);
    builder.addOrderBy(segment, 'asc');
  }

  return builder;
};

export const generateChartLabels = <T extends { x: string }>(
  series: Series<T> | undefined,
  t: TFunction,
  dimension?: string
) => {
  if (!series) {
    return [];
  }

  return series.series.map((s) => formatLabel(t, s.x, dimension));
};

export const onDownloadCSV = ({
  rawData,
  columns,
  unit,
  title,
  t,
}: {
  rawData: RawDataRow[];
  columns: Column[];
  title: string;
  unit?: TimeGranularity;
  t: TFunction;
}) => {
  const columnsContent = columns.map((column) => column.headerName).join(',');

  const rowsContent = rawData
    .map((rowData: RawDataRow) => {
      const values = Object.entries(rowData).reduce(
        (acc: string[], [key, value]) => {
          const column = columns.find((column) => column.field === key);

          if (value && column) {
            const cellValue = getFormattedValue(value, column, t, unit);

            acc.push(cellValue);
          }

          return acc;
        },
        []
      );

      return values.join(',') as string;
    })
    .join('\n');

  const csvContent =
    'data:text/csv;charset=utf-8,' + columnsContent + '\n' + rowsContent;

  download(csvContent, `${title}.csv`);
};
