import { LoadingButton } from '@mui/lab';
import {
  Document,
  Image,
  Page,
  StyleSheet,
  View,
  pdf,
} from '@react-pdf/renderer';
import * as htmlToImage from 'html-to-image';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
  },
});
const exportPDF = async (reportName: string) => {
  const headerElement = document.querySelector<HTMLElement>(
    '[data-cy="page-header"]'
  );
  const chartElements = document.querySelectorAll<HTMLDivElement>(
    '[data-cy="configurable-chart"]'
  );

  if (!headerElement) {
    return null;
  }

  const headerData = await htmlToImage.toPng(headerElement);
  const chartsData = await Promise.all(
    [...chartElements].map(
      async (chartTitleElement) =>
        await htmlToImage.toPng(chartTitleElement, {
          filter: (element: HTMLElement) =>
            !['MuiButtonBase-root', 'chart-options'].some((classname) =>
              element.classList?.contains(classname)
            ),
        })
    )
  );
  // Create a document instance
  const doc = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image src={headerData} />
        </View>
        {chartsData.map((dataUrl, index) => (
          <View key={index} style={styles.section} wrap={false}>
            <Image src={dataUrl} />
          </View>
        ))}
      </Page>
    </Document>
  );
  const blob = await pdf(doc).toBlob();

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${reportName}.pdf`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

interface ExportReportButton {
  reportName: string;
}
export const ExportReportButton = ({ reportName }: ExportReportButton) => {
  const { t } = useTranslation('reports');
  const [isLoading, setIsLoading] = useState(false);
  const onExportPDF = async (reportName: string) => {
    setIsLoading(true);
    await exportPDF(reportName);
    setIsLoading(false);
  };
  return (
    <LoadingButton
      data-cy="export-report"
      variant="contained"
      onClick={() => onExportPDF(reportName)}
      loading={isLoading}
    >
      {t('Export')}
    </LoadingButton>
  );
};
