import { Stack, Typography } from '@mui/material';

import { LocationSelector } from 'design-system/components';

interface ReportConfigurationStep2 {
  buildingIds: string[];
  setBuildingIds: (value: string[], isAllSelected: boolean) => void;
  isAllLocationsSelected?: boolean;
  withTitle?: boolean;
}

export const ReportConfigurationStep2 = ({
  buildingIds,
  setBuildingIds,
  isAllLocationsSelected,
  withTitle = true,
}: ReportConfigurationStep2) => {
  return (
    <Stack data-cy="report-config-step-2" spacing={2}>
      {withTitle && (
        <Typography variant="subtitle1" data-cy="report-config-step-2-title">
          Choose locations
        </Typography>
      )}
      <LocationSelector
        value={buildingIds}
        onChange={setBuildingIds}
        isAllLocationsSelected={isAllLocationsSelected}
      />
    </Stack>
  );
};
