import { Box, ThemeProvider } from '@mui/material';

import { CommonContent } from '../common-content';

import { darkTheme } from 'design-system';
import background from 'design-system/images/background2.svg';

export const PublicErrorBoundary = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          width: '100vw',
          height: '100dvh',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            background: darkTheme.palette.background.default,
            color: darkTheme.palette.common.white,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            textAlign: 'center',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100dvh',
              background: `url(${background}) right bottom no-repeat`,
            },
          }}
        >
          <Box sx={{ pt: darkTheme.spacing(20), zIndex: 2 }}>
            <CommonContent />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
