import { RequireAuth } from 'react-auth-kit';
import { Outlet } from 'react-router-dom';

export const ProtectedRoute = () => {
  return (
    <RequireAuth loginPath="/login">
      <Outlet />
    </RequireAuth>
  );
};
