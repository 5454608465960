import { useSWRConfig } from 'swr';

import { PaginatedResponse } from './types';
import {
  useDeleteCoreApi,
  useGetCoreAPI,
  usePostCoreApi,
  usePutCoreApi,
} from './utils';

import { ChartType } from 'features/reports';
import { ReportTypeId, BuildingHourId } from 'features/reports/utils';
import { registerTranslationKey } from 'packages/i18n';

export interface Report {
  id: number;
  name: string;
  created_at: string;
  created_by: number;
  created_by_name: string;
  building_count: number;
  floor_count: number;
  space_count: number;
  report_type: string;
  can_edit: boolean;
}

export interface ReportDetailed {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  created_by: number;
  created_by_name: string;
  building_ids: number[];
  floor_ids: number[];
  space_ids: number[];
  report_type: ReportTypeId;
  can_edit: boolean;
  building_working_hours: BuildingHourId;
  countries: string[];
  duration?: number;
  date_from?: string;
  date_to?: string;
  rolling_date: boolean;
}

interface ReportQueryParams {
  name?: string;
  report_type?: string;
  created_by?: string;
  page: number;
  page_size: number;
  ordering: string;
}

export interface CreateReportBody {
  name: string;
  space_ids: number[];
  floor_ids: number[];
  building_ids: number[];
  report_type: string;
  building_working_hours: BuildingHourId;
  countries: string[];
  duration?: number;
  date_from?: string;
  date_to?: string;
  rolling_date: boolean;
}

export const REPORTS_ENDPOINT = 'smart-cleaning-v2-reports';

export const useReports = (params?: ReportQueryParams) => {
  const { mutate } = useSWRConfig();
  const finalParams = params && { ...params, page: params?.page + 1 };
  const api = useGetCoreAPI<PaginatedResponse<Report[]>>(
    REPORTS_ENDPOINT,
    finalParams
  );
  return {
    ...api,
    revalidate: () => {
      mutate(
        (key: [string, string]) => !!key && key[0] === REPORTS_ENDPOINT,
        undefined,
        { revalidate: true }
      );
    },
  };
};

export const useReport = (id: number | null) => {
  const endpoint = id === null ? null : `${REPORTS_ENDPOINT}/${id}`;
  return useGetCoreAPI<ReportDetailed>(endpoint);
};

export const useCreateReport = (callback: () => void = () => {}) => {
  const { trigger, isMutating } = usePostCoreApi<CreateReportBody, Report>(
    REPORTS_ENDPOINT,
    callback
  );
  const createReport = async (body: CreateReportBody) =>
    await trigger({ body });

  return { trigger: createReport, isMutating };
};

export const useDuplicateReport = (callback: () => void = () => {}) => {
  const { trigger, isMutating: isCreatingChart } = usePostCoreApi<
    CreateChartBody,
    Chart
  >(REPORTS_ENDPOINT, callback);
  const { trigger: onCreateReport, isMutating: isCreatingReport } =
    useCreateReport();

  const duplicateReport = async (report: ReportDetailed, charts?: Chart[]) => {
    const {
      id: _id,
      can_edit: _can_edit,
      ...duplicateReportBody
    } = {
      ...report,
      ...{
        name: `${report?.name} - ${registerTranslationKey('COPY', {
          ns: 'reports',
        })}`,
      },
    };
    const duplicateReport = await onCreateReport(duplicateReportBody);

    if (!charts || charts.length === 0) return callback();

    const chartBodies: CreateChartBody[] = charts.map((chart) => {
      return {
        configuration: chart.configuration,
      };
    });

    for (const body of chartBodies) {
      await trigger({
        body,
        id: duplicateReport.id.toString(),
        entity: 'charts',
      });
    }
  };

  return {
    trigger: duplicateReport,
    isMutating: isCreatingReport || isCreatingChart,
  };
};

export const useDeleteReport = (callback: () => void = () => {}) => {
  return useDeleteCoreApi(`${REPORTS_ENDPOINT}`, callback);
};

export const useUpdateReport = (
  reportId: number,
  callback: () => void = () => {}
) => {
  const { trigger: onUpdate, isMutating: isUpdating } =
    usePutCoreApi<CreateReportBody>(`${REPORTS_ENDPOINT}`, callback);

  const updateReport = async (body: CreateReportBody) =>
    await onUpdate({ id: reportId.toString(), body });

  return { trigger: updateReport, isMutating: isUpdating };
};

export interface Chart {
  id: string;
  configuration: {
    title: string;
    measures: string[];
    dimension?: string;
    // granularity is expected if `dimension` is `time`
    granularity?: 'hour' | 'day' | 'week' | 'month';
    type: ChartType;
  };
}
export type CreateChartBody = Omit<Chart, 'id'>;

export const useCharts = (reportId: number | null) => {
  const endpoint =
    reportId === null ? null : `${REPORTS_ENDPOINT}/${reportId}/charts`;
  return useGetCoreAPI<PaginatedResponse<Chart[]>>(endpoint);
};

export const useCreateChart = (
  reportId: number,
  callback: () => void = () => {}
) => {
  const { trigger, isMutating } = usePostCoreApi<CreateChartBody, Chart>(
    `${REPORTS_ENDPOINT}/${reportId}/charts`,
    callback
  );
  const createChart = async (body: CreateChartBody) => await trigger({ body });
  return { trigger: createChart, isMutating };
};

export const useUpdateChart = (
  reportId: number,
  callback: () => void = () => {}
) => {
  const { trigger: onUpdate, isMutating: isUpdating } =
    usePutCoreApi<CreateChartBody>(
      `${REPORTS_ENDPOINT}/${reportId}/charts`,
      callback
    );

  const updateChart = async (chartId: string, body: CreateChartBody) =>
    await onUpdate({ id: chartId, body });

  return { trigger: updateChart, isMutating: isUpdating };
};

export const useDeleteChart = (
  reportId: number,
  callback: () => void = () => {}
) => {
  return useDeleteCoreApi(`${REPORTS_ENDPOINT}/${reportId}/charts`, callback);
};
