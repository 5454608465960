import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SvgIconTypeMap,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useSignOut } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCurrentOrganization } from '../../../api/user';
import { Logo } from '../logo';

interface NavigationMenuItemProps {
  label: string;
  notificationCount?: number;
  active?: boolean;
  onClick?: () => void;
}
const NavigationMenuItem = ({
  active,
  label,
  notificationCount,
  onClick,
}: NavigationMenuItemProps) => {
  const theme = useTheme();
  return (
    <Button
      variant={active ? 'contained' : 'text'}
      color={active ? 'primary' : 'inherit'}
      sx={{
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '18px',
        color: theme.palette.common.white,
        minWidth: 'auto',
        padding: theme.spacing(1),
        textTransform: 'none',
      }}
      onClick={onClick}
    >
      {label}
      {!!notificationCount && <Badge color="error" badgeContent="8" />}
    </Button>
  );
};

interface IconMenuItemProps {
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  'data-cy'?: string;
}
const IconMenuItem = ({ Icon, onClick, ...others }: IconMenuItemProps) => {
  const icon = (
    <Icon
      sx={{
        width: 24,
        height: 24,
      }}
      {...others}
    />
  );
  return (
    <IconButton onClick={onClick} sx={{ padding: 0 }}>
      {icon}
    </IconButton>
  );
};

export const Navigation = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const location = useLocation();
  const { t } = useTranslation();
  const { isLoading, data } = useCurrentOrganization();
  const shouldRenderMenu = useMediaQuery(theme.breakpoints.up('md'));

  const onSignOut = () => {
    if (signOut()) {
      navigate('/login');
    }
  };

  const [userMenuEl, setUserMenuEl] = useState<HTMLElement | null>();
  const handleUserMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuEl(null);
  };

  return (
    <AppBar component="nav" elevation={0}>
      <Toolbar sx={{ height: 64 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          data-cy="navigation-pages"
        >
          <Logo />
          {shouldRenderMenu && (
            <>
              <NavigationMenuItem
                label={t('Home')}
                active={location.pathname === '/'}
                onClick={() => navigate('/')}
              />
              <NavigationMenuItem
                label={t('Tasks', { ns: 'tasks' })}
                active={location.pathname === '/tasks'}
                onClick={() => navigate('/tasks')}
              />
              <NavigationMenuItem
                label={t('Reports', { ns: 'reports' })}
                active={location.pathname === '/reports'}
                onClick={() => navigate('/reports')}
              />
            </>
          )}
        </Stack>
        {/* fill the space between the 2 menus on desktop */}
        {shouldRenderMenu && <Box flex="1" />}
        <Stack
          direction="row"
          spacing={shouldRenderMenu ? 3 : 0}
          alignItems="center"
          flex={!shouldRenderMenu ? '1' : undefined}
        >
          <Typography
            variant="body2"
            data-cy="navigation-organization-name"
            flex={!shouldRenderMenu ? '1' : undefined}
            align="center"
          >
            {!isLoading && data?.name}
          </Typography>
          {shouldRenderMenu && <IconMenuItem Icon={SettingsIcon} />}
          <IconMenuItem
            data-cy="navigation-user-menu"
            Icon={AccountCircleIcon}
            onClick={(e) => handleUserMenuOpen(e)}
          />
          <Menu
            id="navigation-user-menu"
            anchorEl={userMenuEl}
            open={Boolean(userMenuEl)}
            onClose={handleUserMenuClose}
            onBlur={handleUserMenuClose}
            onClick={handleUserMenuClose}
            keepMounted
          >
            <MenuItem>
              <ListItemText primary="My profile" />
              <ListItemIcon sx={{ paddingRight: 0, paddingLeft: 2 }}>
                <OpenInNewIcon fontSize="small" />
              </ListItemIcon>
            </MenuItem>
            <MenuItem onClick={onSignOut} data-cy="navigation-logout">
              Log out
            </MenuItem>
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
