import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, TimeGranularity, getFormattedValue } from '../utils';

import type { RawDataRow } from 'cube/types';
import { LoadingBackdrop, Table } from 'design-system/components';

interface CubeTableProps {
  columns: Column[];
  isLoading: boolean;
  rows: RawDataRow[];
  unit?: TimeGranularity;
}

export const CubeTable = ({
  columns,
  isLoading,
  rows,
  unit,
}: CubeTableProps) => {
  const { t } = useTranslation('reports');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  return (
    <>
      <Box position="relative">
        {isLoading && <LoadingBackdrop />}
        <Table
          columns={
            columns.map<GridColDef>((column) => ({
              field: column.field,
              headerName: column.headerName,
              valueGetter: (params) =>
                getFormattedValue(params.value, column, t, unit),
              width: 200,
            })) ?? []
          }
          rows={rows.map((row, id) => ({ ...row, id })) ?? []}
          mode="client"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
    </>
  );
};
