import { LoadingButton } from '@mui/lab';
import { MenuItem, Button, TextField, MenuItemProps } from '@mui/material';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'design-system/components';

interface ConfigurableChartRenameMenuItemProps extends MenuItemProps {
  title: string;
  onTitleChange: (title: string) => void;
}

export const ConfigurableChartRenameMenuItem = forwardRef<
  HTMLLIElement,
  ConfigurableChartRenameMenuItemProps
>(({ title, onTitleChange, ...props }, ref) => {
  const { t } = useTranslation('reports');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartTitle, setChartTitle] = useState(title);
  const onClose = () => {
    setIsModalOpen(false);
    setChartTitle(title);
  };

  return (
    <>
      <MenuItem
        ref={ref}
        data-cy="chart-menu-rename"
        onClick={() => setIsModalOpen(true)}
        {...props}
      >
        {t('Rename')}
      </MenuItem>
      <Modal
        isOpen={isModalOpen}
        onClose={onClose}
        title={t('Rename chart')}
        actions={
          <>
            <Button
              data-cy="rename-chart-cancel"
              variant="text"
              onClick={onClose}
            >
              {t('Cancel')}
            </Button>
            <LoadingButton
              data-cy="rename-chart-save"
              variant="contained"
              color="primary"
              onClick={() => {
                onTitleChange(chartTitle);
                setIsModalOpen(false);
              }}
            >
              {t('Save')}
            </LoadingButton>
          </>
        }
      >
        <TextField
          label={t('Name')}
          margin="normal"
          value={chartTitle}
          onChange={(e) => setChartTitle(e.target.value)}
          sx={{ width: 552 }}
          autoFocus
          data-cy="rename-chart-input"
        />
      </Modal>
    </>
  );
});

ConfigurableChartRenameMenuItem.displayName = 'ConfigurableChartRenameMenuItem';
