import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { forwardRef } from 'react';

import { ChartType } from 'features/reports';

interface ChartTypePickerProps {
  value: ChartType;
  onChange: (newValue: ChartType) => void;
}

interface IconToggleButtonProps
  extends React.ComponentProps<typeof ToggleButton> {
  children: React.ReactNode;
  value: ChartType;
}
// we need to forward ref here as ToggleButtonGroup orchestrate the selected ToggleButton
const IconToggleButton = forwardRef<HTMLButtonElement, IconToggleButtonProps>(
  ({ children, value, ...props }: IconToggleButtonProps, ref) => {
    return (
      <ToggleButton
        ref={ref}
        size="large"
        value={value}
        data-cy={`chart-type-${value}`}
        {...props}
        className="MuiIconButton-root"
      >
        {children}
      </ToggleButton>
    );
  }
);

IconToggleButton.displayName = 'IconToggleButton';

export const ChartTypePicker = ({ value, onChange }: ChartTypePickerProps) => {
  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={value}
      exclusive
      // when clicking on the selected item, the toggle tries to unselect it
      // so we fallback on the current value
      onChange={(_e, newValue) => onChange(newValue ?? value)}
    >
      <IconToggleButton value="bar">
        <BarChartIcon />
      </IconToggleButton>
      <IconToggleButton value="line">
        <SsidChartIcon />
      </IconToggleButton>
      <IconToggleButton value="table">
        <TableChartIcon />
      </IconToggleButton>
    </ToggleButtonGroup>
  );
};
