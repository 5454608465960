import Info from '@mui/icons-material/Info';
import { Box, Button, Link, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSetupTable } from '../user-setup-table';

import { useUserLocations } from 'api/user';
import { CircleLoader } from 'design-system/components';
import SETTINGS from 'settings';

export const UserSetupTab = () => {
  const { t } = useTranslation('tasks');

  const tooltipContent = t(
    'New users first need to be set up in Infogrid before they can use Smart Cleaning Pro. Once you have saved user changes in Infogrid, please refresh this page.'
  );

  const userManagementHref = `${SETTINGS.LEGACY_WEBCLIENT}/organization/accounts/`;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const {
    data: userLocations,
    isLoading,
    mutate: mutateUserLocations,
  } = useUserLocations({
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
  });

  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <Link href={userManagementHref} rel="noopener" target="_blank">
            <Button data-cy="user-management" variant="text">
              {t('Add/manage users')}
            </Button>
          </Link>
          <Tooltip
            PopperProps={{
              // @ts-expect-error: We can pass this even though it's not an accepted prop here, for checking existence
              'data-cy': 'user-management-tooltip',
            }}
            placement="top"
            title={tooltipContent}
          >
            <Info color="primary" data-cy="user-management-help" />
          </Tooltip>
        </Box>
      </Box>
      {isLoading && <CircleLoader />}
      {userLocations && (
        <UserSetupTable
          rows={userLocations.results}
          onPaginationModelChange={setPaginationModel}
          paginationModel={paginationModel}
          onSave={mutateUserLocations}
        />
      )}
    </Stack>
  );
};
