import { Paper, Tab, Tabs, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from 'components';
import { CompleteTasksTab } from 'features/task-management/complete-tasks-tab';
import { OpenTasksTab } from 'features/task-management/open-tasks-tab';
import { UserSetupTab } from 'features/task-management/user-setup-tab';

const tabComponents = [OpenTasksTab, CompleteTasksTab, UserSetupTab];

export const TasksPage = () => {
  const { t } = useTranslation('tasks');
  const theme = useTheme();

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  const tabs = (
    <Tabs value={tabIndex} onChange={handleChange}>
      <Tab data-cy="open-tab" id="open-tab" label={t('Open')} />
      <Tab data-cy="complete-tab" id="complete-tab" label={t('Complete')} />
      <Tab
        data-cy="set-up"
        id="set-up-tab"
        label={t('Set up')}
        sx={{ marginLeft: 'auto' }}
      />
    </Tabs>
  );

  const TabComponent = tabComponents[tabIndex];

  return (
    <PageContainer
      headerProps={{
        primary: t('Tasks'),
        tabs,
      }}
    >
      <Paper sx={{ p: { xs: theme.spacing(5, 2), md: theme.spacing(5) } }}>
        <TabComponent />
      </Paper>
    </PageContainer>
  );
};
