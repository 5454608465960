import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Menu,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridRowsProp,
  GridSortItem,
  GridValidRowModel,
} from '@mui/x-data-grid';
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  NoResultsRowsOverlay,
  NoResultsRowsOverlayProps,
} from './no-results-overlay';

export interface TableProps<T> {
  columns: GridColDef<GridValidRowModel>[];
  rows: GridRowsProp;
  isSelectable?: boolean;
  actionsMinWidth?: number;
  actions?: (row: T) => React.ReactNode[];
  more?: (row: T, isOpen: boolean) => React.ReactNode[];
  isLoading?: boolean;
  rowCount?: number;
  paginationModel?: GridPaginationModel;
  onPaginationModelChange?: Dispatch<
    SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
  onSortModelChange?: Dispatch<SetStateAction<GridSortItem[]>>;
  noResultsRowsOverlayConfig?: NoResultsRowsOverlayProps;
  mode?: 'server' | 'client';
  onCellClick?: GridEventListener<'cellClick'>;
  'data-cy'?: string;
}
export const Table = <T,>({
  isSelectable,
  columns,
  rows,
  actionsMinWidth = 140,
  actions,
  more,
  isLoading,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  onSortModelChange,
  noResultsRowsOverlayConfig,
  mode = 'server',
  onCellClick,
  'data-cy': dataCy,
}: TableProps<T>) => {
  const [anchorElMap, setAnchorElMap] = useState<{
    [id: string]: HTMLElement | null;
  }>({});

  const handleMoreClick = (event: MouseEvent<HTMLElement>, rowId: string) => {
    setAnchorElMap({
      ...anchorElMap,
      [rowId]: event.currentTarget,
    });
  };

  const handleMenuClose = (rowId: string) => {
    setAnchorElMap({
      ...anchorElMap,
      [rowId]: null,
    });
  };

  // From MUI docs - https://mui.com/x/react-data-grid/pagination/#basic-implementation:~:text=Since%20the,loading%20as%20follows%3A
  const [rowCountState, setRowCountState] = useState(rowCount);
  useEffect(() => {
    setRowCountState((prevRowCountState) => rowCount ?? prevRowCountState);
  }, [paginationModel, rowCount, setRowCountState]);

  const noResultsOrRowsOverlay = () =>
    NoResultsRowsOverlay({
      isFilterApplied: noResultsRowsOverlayConfig?.isFilterApplied ?? false,
      noResultsText: noResultsRowsOverlayConfig?.noResultsText,
      noRowsText: noResultsRowsOverlayConfig?.noRowsText,
      noRowsButton: noResultsRowsOverlayConfig?.noRowsButton,
    });

  return (
    <Box
      sx={{
        // headers + all rows + pagination
        height: 56 + 52 * (paginationModel?.pageSize ?? 10) + 52,
      }}
      data-cy={dataCy}
    >
      <DataGrid
        disableColumnMenu
        disableRowSelectionOnClick
        rowCount={rowCountState}
        checkboxSelection={isSelectable}
        onCellClick={onCellClick}
        sx={{
          border: 'none',
          '& .MuiDataGrid-columnHeaders': {
            fontWeight: 500,
          },
          '& .MuiTablePagination-toolbar > *:not(.MuiSvgIcon-root)': {
            fontSize: 12,
          },
          '& .MuiTablePagination-toolbar > .MuiInputBase-root': {
            lineHeight: '16px',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 0,
          },
          '--DataGrid-overlayHeight': '224px',
          '& .MuiDataGrid-overlayWrapperInner': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .MuiDataGrid-overlayWrapper': {
            position: 'static',
          },
        }}
        loading={isLoading}
        paginationMode={mode}
        sortingMode={mode}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
        pageSizeOptions={[10, 25, 100]}
        slots={{
          loadingOverlay: () => (
            <Backdrop
              open
              sx={{
                position: 'absolute',
                zIndex: 1,
              }}
            >
              <CircularProgress color="primary" />
            </Backdrop>
          ),
          noRowsOverlay: noResultsOrRowsOverlay,
          noResultsOverlay: noResultsOrRowsOverlay,
          ...(noResultsRowsOverlayConfig &&
            rows.length === 0 &&
            !noResultsRowsOverlayConfig.isFilterApplied && {
              columnHeaders: () => null,
            }),
        }}
        columns={[
          ...columns.map((c) => ({ ...c, flex: 1 })),
          ...(actions || more
            ? [
                {
                  field: 'actions',
                  headerName: '',
                  sortable: false,
                  minWidth: actionsMinWidth,

                  renderCell: (params) => (
                    <>
                      {actions && actions(params.row as T)}
                      {more && (
                        <>
                          <IconButton
                            data-cy={`table-more-button-${params.row.id}`}
                            size="small"
                            color="primary"
                            onClick={(event) =>
                              handleMoreClick(event, params.row.id)
                            }
                          >
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu
                            id={`actions-menu-${params.row.id}`}
                            data-cy={`actions-menu-${params.row.id}`}
                            anchorEl={anchorElMap[params.row.id]}
                            open={Boolean(anchorElMap[params.row.id])}
                            onClose={() => handleMenuClose(params.row.id)}
                            onBlur={() => handleMenuClose(params.row.id)}
                            onClick={() => handleMenuClose(params.row.id)}
                            keepMounted
                          >
                            {more(
                              params.row as T,
                              params.row.id in anchorElMap
                            )}
                          </Menu>
                        </>
                      )}
                    </>
                  ),
                } as GridColDef<GridValidRowModel>,
              ]
            : []),
        ]}
        rows={rows}
      ></DataGrid>
    </Box>
  );
};
