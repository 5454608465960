import { Stack, Typography, useTheme } from '@mui/material';

import { registerTranslationKey } from 'packages/i18n';

export interface NoResultsRowsOverlayProps {
  isFilterApplied: boolean;
  noResultsText?: string;
  noRowsText?: string;
  noRowsButton?: React.ReactNode;
}

export const NoResultsRowsOverlay = ({
  isFilterApplied,
  noResultsText = registerTranslationKey(
    'No results to show. Some results may be hidden due to filters.'
  ),
  noRowsText = registerTranslationKey('No results to show.'),
  noRowsButton,
}: NoResultsRowsOverlayProps) => {
  const theme = useTheme();
  return (
    <Stack
      spacing={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography
        variant="h5"
        color={theme.palette.text.secondary}
        alignContent="center"
        data-cy="no-results-rows-text"
      >
        {isFilterApplied ? noResultsText : noRowsText}
      </Typography>
      {!isFilterApplied && noRowsButton}
    </Stack>
  );
};
