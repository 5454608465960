import axios from 'axios';
import type { AxiosPromise, AxiosRequestConfig, Method } from 'axios';

export function createRequest<T>({
    method,
    url,
    signal,
    options = {},
}: {
    method: Method;
    url: string;
    signal?: AbortSignal;
    options?: AxiosRequestConfig;
}): AxiosPromise<T> {
    return axios({
        signal,
        method,
        url,
        ...options,
        // withCredentials: true,
    });
}
