import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import SETTINGS from '../../settings';

import { LoginContainer } from './components';
import { useSignIn } from './utils';

export const LoginPage = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState<string | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const onLogin = () => {
    setIsLoggingIn(true);
    axios
      .post(`${SETTINGS.COREAPI_API_URL}auth/token/`, {
        email: login,
        password,
      })
      .then((response) => signIn(response))
      .catch((error) => {
        setLoginError(
          error?.response?.data?.detail || t('An unknown error occured')
        );
        setIsLoggingIn(false);
      });
  };

  if (isAuthenticated()) {
    return <Navigate to="/" />;
  }

  const ssoLogin =
    SETTINGS.AUTH0_DOMAIN && SETTINGS.AUTH0_CLIENT_ID ? (
      <>
        <Divider />
        <Typography
          variant="subtitle1"
          justifyContent="center"
          display="flex"
          alignItems="center"
        >
          {t('Log in with Single Sign-On')}
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(`/login-sso`)}
          data-cy="navigate-sso-login"
        >
          {t('Login with SSO')}
        </Button>
      </>
    ) : null;

  return (
    <LoginContainer>
      <Typography variant="h4" align="center">
        {t('Log in to Infogrid')}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="username">{t('Username')}</InputLabel>
        <OutlinedInput
          id="username"
          label={t('Username')}
          autoFocus
          value={login}
          onChange={(e) => setLogin(e.currentTarget.value)}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="password">{t('Password')}</InputLabel>
        <OutlinedInput
          id="password"
          label={t('Password')}
          type="password"
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
      </FormControl>
      {loginError && (
        <Grid item>
          <Alert severity="error" data-cy="login-error">
            {loginError}
          </Alert>
        </Grid>
      )}

      <Box display="flex" alignContent="space-between" alignItems="center">
        <Typography
          color="secondary"
          flex="1"
          variant="body2"
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            window.open(`${SETTINGS.LEGACY_WEBCLIENT}/auth/forgot-password`)
          }
        >
          {t('Forgot password?')}
        </Typography>
        <LoadingButton
          data-cy="login-button"
          color="primary"
          variant="contained"
          onClick={onLogin}
          loading={isLoggingIn}
        >
          {t('Login')}
        </LoadingButton>
      </Box>
      {ssoLogin}
    </LoginContainer>
  );
};
