import { LoadingButton } from '@mui/lab';
import { Button, Tab, Tabs } from '@mui/material';
import { formatISO, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportConfigurationSteps } from '../enums';
import { ReportConfigurationStep2 } from '../report-configuration-modal/report-configuration-step-2';

import { ReportDetailed, useUpdateReport } from 'api/reports';
import { convertIntToUuid, convertUuidToInt } from 'cube';
import { Modal } from 'design-system/components';
import { ReportConfigurationStep1 } from 'features/reports';
import {
  BuildingHourId,
  ReportDateType,
  TimePeriodId,
  TimePeriods,
  getEffectiveTimePeriod,
} from 'features/reports/utils';

interface ReportConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  report: ReportDetailed;
  onSave: () => void;
}
const DefaultBuildingHourId = 'building_specific';
const DefaultReportDateType = 'rolling';
const DefaultTimePeriodId = '90d';
const DefaultBuildingIds: string[] = [];

export const ReportConfigurationEditModal = ({
  isOpen,
  onClose,
  report,
  onSave,
}: ReportConfigurationModalProps) => {
  const { t } = useTranslation('reports');

  const [customReportName, setCustomReportName] = useState<string>('');
  const [customReportTimePeriod, setCustomReportTimePeriod] =
    useState<TimePeriodId>(DefaultTimePeriodId);
  const [customReportDateType, setCustomReportDateType] =
    useState<ReportDateType>(DefaultReportDateType);
  const [customReportBuildingHour, setCustomReportBuildingHour] =
    useState<BuildingHourId>(DefaultBuildingHourId);
  const [customBuildingIds, setCustomBuildingIds] =
    useState<string[]>(DefaultBuildingIds);
  const [isAllLocationsSelected, setIsAllLocationsSelected] =
    useState<boolean>(false);

  const { trigger: updateReport, isMutating: isUpdatingReport } =
    useUpdateReport(report.id);

  const [activeStep, setActiveStep] = useState(
    ReportConfigurationSteps.DETAILS_STEP
  );
  const handleStepChange = (_event: React.SyntheticEvent, newStep: number) => {
    setActiveStep(newStep);
  };

  // upon re-opening the modal, reset the form with the report configuration
  useEffect(() => {
    if (isOpen) {
      setCustomReportName(report.name);
      setCustomReportTimePeriod(
        TimePeriods.find((p) => p.value === report.duration)!.id
      );
      setCustomReportDateType(report.rolling_date ? 'rolling' : 'fixed');
      setCustomReportBuildingHour(
        report.building_working_hours as BuildingHourId
      );
      setCustomBuildingIds(report.building_ids.map(convertIntToUuid));
      setIsAllLocationsSelected(report.building_ids.length === 0);
    }
  }, [
    isOpen,
    report.name,
    report.duration,
    report.rolling_date,
    report.building_working_hours,
    report.building_ids,
  ]);

  const handleClose = () => {
    onClose();
    setActiveStep(ReportConfigurationSteps.DETAILS_STEP);
  };

  const onUpdateReport = async () => {
    const duration = TimePeriods.find(
      (p) => p.id === customReportTimePeriod
    )!.value;
    const period = getEffectiveTimePeriod(
      parseISO(report.created_at),
      duration ?? 0
    );

    await updateReport({
      name: customReportName,
      report_type: report.report_type,
      duration,
      building_working_hours: customReportBuildingHour,
      rolling_date: customReportDateType === 'rolling',
      ...(customReportDateType === 'fixed' && {
        date_to: formatISO(period.date_to),
        date_from: formatISO(period.date_from),
      }),
      building_ids: isAllLocationsSelected
        ? []
        : customBuildingIds.map(convertUuidToInt),
      floor_ids: report.floor_ids,
      space_ids: report.space_ids,
      countries: report.countries,
    });

    onSave();
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t('Edit report')}
      actions={
        <>
          <Button
            onClick={handleClose}
            data-cy="edit-report-cancel"
            variant="text"
          >
            {t('Cancel')}
          </Button>
          <LoadingButton
            disabled={
              customReportName?.trim() === '' ||
              (customBuildingIds.length === 0 && !isAllLocationsSelected)
            }
            variant="contained"
            onClick={onUpdateReport}
            data-cy="edit-report-save"
            loading={isUpdatingReport}
          >
            {t('Save')}
          </LoadingButton>
        </>
      }
      maxWidth="md"
      fullWidth
      minHeight={680}
      secondaryHeader={
        <Tabs value={activeStep} onChange={handleStepChange}>
          <Tab label={t('Details')} data-cy="details-tab" />
          <Tab label={t('Locations')} data-cy="locations-tab" />
        </Tabs>
      }
    >
      {activeStep === ReportConfigurationSteps.DETAILS_STEP && (
        <ReportConfigurationStep1
          name={customReportName}
          setName={setCustomReportName}
          timePeriod={customReportTimePeriod}
          setTimePeriod={setCustomReportTimePeriod}
          dateType={customReportDateType}
          setDateType={setCustomReportDateType}
          buildingHour={customReportBuildingHour}
          setBuildingHour={setCustomReportBuildingHour}
        />
      )}
      {activeStep === ReportConfigurationSteps.LOCATIONS_STEP && (
        <ReportConfigurationStep2
          buildingIds={customBuildingIds}
          setBuildingIds={(locations, isAllSelected) => {
            setIsAllLocationsSelected(isAllSelected);
            setCustomBuildingIds(locations);
          }}
          isAllLocationsSelected={isAllLocationsSelected}
          withTitle={false}
        />
      )}
    </Modal>
  );
};
