import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCurrentOrganization } from '../../../api/user';

export const MobileNavigation = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading } = useCurrentOrganization();
  const shouldRender = useMediaQuery(theme.breakpoints.down('md'));
  const currentValue = location.pathname.split('/')[1] ?? '';
  return (
    shouldRender &&
    !isLoading && (
      <BottomNavigation
        data-cy="mobile-navigation"
        showLabels
        value={currentValue}
        sx={{ zIndex: 1 }}
        onChange={(_event, newValue) => {
          navigate(`/${newValue}`);
        }}
      >
        <BottomNavigationAction
          data-cy="mobile-navigation-home"
          label={t('Home')}
          icon={<HomeIcon />}
          value=""
        />
        <BottomNavigationAction
          data-cy="mobile-navigation-tasks"
          label={t('Tasks', { ns: 'tasks' })}
          icon={<TaskAltIcon />}
          value="tasks"
        />
        <BottomNavigationAction
          data-cy="mobile-navigation-reports"
          label={t('Reports')}
          icon={<BarChartIcon />}
          value="reports"
        />
        <BottomNavigationAction
          data-cy="mobile-navigation-more"
          label={t('More')}
          icon={<MoreHorizIcon />}
          value=" "
        />
      </BottomNavigation>
    )
  );
};
