import { CssBaseline, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { AuthProvider, createRefresh } from 'react-auth-kit';
import { ErrorBoundary } from 'react-error-boundary';
import { SWRConfig } from 'swr';

import { AppRouter } from './components/app-router';
import SETTINGS from './settings';

import { PublicErrorBoundary } from 'components/error-boundaries';
import { lightTheme } from 'design-system';
import sentryConfig from 'packages/sentry';

import 'react-multi-carousel/lib/styles.css';
import 'packages/i18n/i18nConfig';
import './App.css';

Sentry.init(sentryConfig);

const refreshApi = createRefresh({
  interval: 10, // Refreshs the token in every 10 minutes
  refreshApiCallback: async ({ authToken, refreshToken }) => {
    try {
      // TODO: move this to API /src/api
      const response = await axios.post(
        `${SETTINGS.COREAPI_API_URL}auth/token/refresh`,
        { refresh: refreshToken },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return {
        isSuccess: true,
        newAuthToken: response.data.access as string,
        newAuthTokenExpireIn: 10,
        newRefreshTokenExpiresIn: 60,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return {
        isSuccess: false,
        newAuthToken: '',
      };
    }
  },
});

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={lightTheme}>
        <ErrorBoundary fallback={<PublicErrorBoundary />}>
          <SWRConfig
            value={{
              dedupingInterval: 100,
              revalidateOnFocus: false,
            }}
          >
            <AuthProvider
              authType="localstorage"
              authName="_auth"
              cookieDomain={window.location.hostname}
              cookieSecure={window.location.protocol === 'https:'}
              refresh={refreshApi}
            >
              <AppRouter />
            </AuthProvider>
          </SWRConfig>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
}

export default App;
