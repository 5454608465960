import {
  Button,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridColDef, GridSortItem, GridValidRowModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { Report, useReports } from 'api/reports';
import { TeamUser } from 'api/types';
import { PageContainer } from 'components';
import { Table } from 'design-system/components';
import { formatDatetime } from 'design-system/formatting';
import {
  ReportCardCarousel,
  ReportConfigurationModal,
  ReportDeleteMenuItem,
  ReportDuplicateMenuItem,
  ReportLocationsSummary,
  ReportsFilters,
} from 'features/reports';
import { ReportTypeId, ReportTypeValue } from 'features/reports/utils';

export const ReportsPage = () => {
  const { t } = useTranslation('reports');
  const theme = useTheme();

  const [isCustomReportModalOpen, setIsCustomReportModalOpen] = useState(false);

  const [reportOwners, setReportOwners] = useState<TeamUser[]>();
  const [reportTypes, setReportTypes] = useState<ReportTypeValue[]>();
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearch] = useDebounce(searchInput, 500);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  // to reset pagination when filters change - currently it makes a request with the current page, which fails, before updating the page to 0
  const reportTypesCount = (reportTypes ?? []).length;
  const reportOwnersCount = (reportOwners ?? []).length;
  useEffect(
    () => setPaginationModel({ page: 0, pageSize: 10 }),
    [debouncedSearch, reportOwnersCount, reportTypesCount]
  );

  const isFilterApplied =
    !!debouncedSearch || !!reportTypes?.length || !!reportOwners?.length;

  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'created_at',
      sort: 'desc',
    },
  ]);

  const defaultSort = '-created_at';

  const {
    data: reports,
    isLoading,
    revalidate,
  } = useReports({
    name: debouncedSearch || undefined,
    report_type: reportTypes?.map((type) => type.id).join(',') || undefined,
    created_by: reportOwners?.map((owner) => owner.id).join(',') || undefined,
    page: paginationModel.page,
    page_size: paginationModel.pageSize,
    ordering:
      sortModel.length === 0
        ? defaultSort
        : `${sortModel[0].sort === 'desc' ? '-' : ''}${sortModel[0].field}`,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const onReportCardClick = (reportTypeId: ReportTypeId) => {
    if (reportTypeId === 'custom') {
      setIsCustomReportModalOpen(true);
    }
  };

  return (
    <PageContainer
      headerProps={{
        primary: `Reports`,
        actions: [
          <Button
            key="page-header-create-report"
            data-cy="create-report"
            variant="contained"
            onClick={() => setIsCustomReportModalOpen(true)}
          >
            {t('Build report')}
          </Button>,
        ],
      }}
    >
      <ReportConfigurationModal
        isOpen={isCustomReportModalOpen}
        onClose={() => setIsCustomReportModalOpen(false)}
        onRevalidateReports={revalidate}
      />

      <Typography variant="h3" gutterBottom>
        {t('Build new report')}
      </Typography>
      <ReportCardCarousel onCardClick={onReportCardClick} />
      <Typography variant="h3" gutterBottom>
        {t('All reports')}
      </Typography>
      <Paper
        sx={{ padding: { xs: theme.spacing(5, 3), md: theme.spacing(5) } }}
      >
        <Stack spacing={2}>
          <ReportsFilters
            search={searchInput}
            setSearch={setSearchInput}
            reportOwners={reportOwners}
            setReportOwners={setReportOwners}
            reportTypes={reportTypes}
            setReportTypes={setReportTypes}
          />
          <Table<Report>
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            onSortModelChange={setSortModel}
            rowCount={reports?.count}
            actionsMinWidth={114}
            actions={() => [
              <Button
                variant="text"
                color="primary"
                size="small"
                key="button-export"
              >
                {t('Export')}
              </Button>,
            ]}
            more={(row, isOpen) => {
              return [
                <ReportDuplicateMenuItem
                  key="menu-duplicate"
                  report={row}
                  isOpen={isOpen}
                  onDuplicated={revalidate}
                />,
                <ReportDeleteMenuItem
                  key="menu-delete"
                  report={row}
                  onDeleted={revalidate}
                />,
              ];
            }}
            columns={[
              {
                field: 'name',
                headerName: t('Name'),
                minWidth: 150,
                maxWidth: 500,
                renderCell: (params) => {
                  return (
                    <Link onClick={() => navigate(`${params.row.id}`)}>
                      {params.value}
                    </Link>
                  );
                },
              },
              {
                field: 'created_at',
                headerName: t('Created'),
                minWidth: 150,
                valueFormatter: (params) => formatDatetime(params.value),
              },
              ...(!isMobile
                ? [
                    {
                      field: 'created_by_name',
                      headerName: t('Owner'),
                      minWidth: 200,
                    },
                    {
                      // Here we use ` ` instead of `` because otherwise the header look slightly darker
                      // Suspect this is a bug in the MUI component.
                      field: ' ',
                      headerName: t('Locations'),
                      minWidth: 200,
                      renderCell: (params) => {
                        const row = params.row as Report;
                        return (
                          <ReportLocationsSummary
                            buildingCount={row.building_count}
                            floorCount={row.floor_count}
                            spaceCount={row.space_count}
                          />
                        );
                      },
                      sortable: false,
                    } as GridColDef<GridValidRowModel>,
                  ]
                : []),
            ]}
            rows={reports?.results ?? []}
            isLoading={isLoading}
            noResultsRowsOverlayConfig={{
              isFilterApplied,
              noResultsText: t(
                'No reports to show. Some reports may be hidden due to filters.'
              ),
              noRowsText: t('No reports to show.'),
              noRowsButton: (
                <Button
                  variant="outlined"
                  size="medium"
                  data-cy="no-rows-button"
                >
                  {t('Create a new report')}
                </Button>
              ),
            }}
          />
        </Stack>
      </Paper>
    </PageContainer>
  );
};
