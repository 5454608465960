import { createContext, useContext } from 'react';

import { UserEdit } from '../types';

export const UserEditContext = createContext<UserEdit>({
  userDetail: {
    id: 0,
    name: '-',
    job_role: '-',
    phone_number: '-',
    email: '-',
  },
  locationIds: [],
  setLocationIds: () => {},
});

export const useUserEditContext = (): UserEdit => {
  const context = useContext<UserEdit>(UserEditContext);

  if (!context) {
    throw new Error('useUserEditContext must be used within UserEditProvider');
  }

  return context;
};
