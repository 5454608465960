import * as Sentry from '@sentry/react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import {
  AuthenticatedErrorBoundary,
  PublicErrorBoundary,
} from './error-boundaries';
import { ProtectedRoute } from './protected-route';

import { HomePage } from 'pages/home';
import { LoginPage } from 'pages/login';
import { LoginSSOPageWrapper } from 'pages/login/login-sso';
import { ReportPage } from 'pages/report';
import { ReportsPage } from 'pages/reports';
import { TasksPage } from 'pages/tasks';

// https://dev.to/sanjayttg/jwt-authentication-in-react-with-react-router-1d03
export const AppRouter = () => {
  const routesForNotAuthenticatedOnly = [
    {
      children: [
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/login-sso',
          element: <LoginSSOPageWrapper />,
        },
      ],
    },
  ];
  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <ProtectedRoute />,
      errorElement: <AuthenticatedErrorBoundary />,
      children: [
        {
          path: '/',
          element: <HomePage />,
        },
        {
          path: '/tasks',
          element: <TasksPage />,
        },
        {
          path: '/reports',
          element: <ReportsPage />,
        },
        {
          path: '/reports/:id',
          element: <ReportPage />,
        },
      ],
    },
  ];

  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  // Combine and conditionally include routes based on authentication status
  const router = sentryCreateBrowserRouter([
    {
      errorElement: <PublicErrorBoundary />,
      children: [
        // ...routesForPublic,
        ...routesForNotAuthenticatedOnly,
        ...routesForAuthenticatedOnly,
      ],
    },
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};
