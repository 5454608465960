import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownInput } from 'design-system/components';
import { ReportTypeValue, ReportTypes } from 'features/reports/utils';

interface ReportTypePickerProps {
  value?: ReportTypeValue[];
  onChange?: (value?: ReportTypeValue[]) => void;
}

export const ReportTypePicker = ({
  value,
  onChange = () => {},
}: ReportTypePickerProps) => {
  const { t } = useTranslation('reports');

  const internalOnChange = useCallback(
    (newSelection: string[]) => {
      onChange(ReportTypes.filter((type) => newSelection.includes(type.id)));
    },
    [onChange]
  );

  return (
    <DropdownInput
      id="report-types"
      label={t('Report type')}
      allLabel={t('All types')}
      value={value?.map((v) => v.id) ?? []}
      onChange={internalOnChange}
      values={ReportTypes}
      menuPosition="right"
    />
  );
};
