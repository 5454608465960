import {
  CONTEXT_SEPARATOR,
  DEFAULT_LOCALE,
  DEFAULT_NAMESPACE,
  LANGUAGES,
  NAMESPACE,
  PLURAL_SEPARATOR,
} from '../constants';

export const options = {
  debug: false,

  fallbackLng: DEFAULT_LOCALE,
  nsSeparator: false as const,
  keySeparator: false as const,

  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}_{{lng}}.json',
  },

  supportedLngs: Object.keys(LANGUAGES),

  ns: Object.values(NAMESPACE),
  defaultNS: DEFAULT_NAMESPACE,

  fallbackNS: DEFAULT_NAMESPACE,

  contextSeparator: CONTEXT_SEPARATOR,

  pluralSeparator: PLURAL_SEPARATOR,

  interpolation: {
    escapeValue: false, // not needed for react
    formatSeparator: ':', // Using `:` format separator instead of default `,` to mimic Typescript typing
  },
  react: {
    useSuspense: false,
  },
  returnEmptyString: false,
};
