import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface PageHeaderProps {
  primary: string;
  secondary?: React.ReactNode | string;
  actions?: React.ReactNode;
  backButton?: {
    label: string;
    href: string;
  };
  tabs?: React.ReactNode;
}
export const PageHeader = ({
  primary,
  secondary,
  actions,
  backButton,
  tabs,
}: PageHeaderProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Paper elevation={0} square sx={{ padding: 0 }}>
      <Container maxWidth="lg">
        {backButton && (
          <Stack direction="row" justifyContent="space-between" paddingTop={2}>
            <Button
              variant="text"
              onClick={() => navigate(backButton.href)}
              startIcon={<ArrowBackIcon />}
              data-cy="page-header-back-button"
            >
              {backButton.label}
            </Button>
            <Stack direction="row" spacing={1} data-cy="page-header-actions">
              {actions}
            </Stack>
          </Stack>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          padding={theme.spacing(4, 0)}
        >
          <Stack data-cy="page-header">
            <Typography variant="h1" data-cy="page-header-primary">
              {primary}
            </Typography>
            {typeof secondary === 'string' ? (
              <Typography variant="h5" data-cy="page-header-secondary">
                {secondary}
              </Typography>
            ) : (
              secondary
            )}
          </Stack>
          {!backButton && (
            <Box
              alignContent="center"
              display="flex"
              flexWrap="wrap"
              data-cy="page-header-actions-inline"
            >
              {actions}
            </Box>
          )}
        </Stack>
        {tabs}
      </Container>
    </Paper>
  );
};
