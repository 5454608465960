import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BuildingHourId, BuildingHours } from 'features/reports/utils';

interface BuildingHourPicker {
  value: BuildingHourId;
  onChange: (newValue: BuildingHourId) => void;
}
export const BuildingHourPicker = ({ value, onChange }: BuildingHourPicker) => {
  const { t } = useTranslation('reports');
  const id = 'building-hour';
  return (
    <FormControl>
      <InputLabel htmlFor={id} data-cy={`dropdown-label-${id}`} shrink={true}>
        {t('Building hours')}
      </InputLabel>
      <Select
        notched={true}
        data-cy={`dropdown-${id}`}
        labelId={`${id}-label`}
        id={id}
        label={t('Building hours')}
        value={value}
        onChange={(event) => onChange(event.target.value as BuildingHourId)}
        sx={{ minWidth: 300 }}
      >
        {BuildingHours?.map((item) => {
          return (
            <MenuItem
              key={item.id}
              value={item.id}
              data-cy={`menu-${id}-${item.id}`}
              onClick={(e) => {
                onChange(item.id);
                e.stopPropagation();
              }}
            >
              <ListItemText primary={item.label} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
