import type { Query } from '@cubejs-client/core';
import { ResultSet } from '@cubejs-client/core';

import type { RawDataRow } from './types';

interface LoadResponseLike<T extends RawDataRow = RawDataRow> {
    data: T[];
    query: Query;
    annotation?: Record<string, any>;
}

export const toResultSet = <T extends RawDataRow>(loadResponse: LoadResponseLike<T>) => {
    return ResultSet.deserialize<T>(
        {
            loadResponse,
        },
        { parseDateMeasures: true },
    );
};
