import { Box, Chip, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { TASK_STATUSES } from '../../constants';
import { TaskStatus } from '../../types';

export interface BaseTaskCardProps {
  buildingName: string | null;
  children: ReactNode;
  'data-cy'?: string;
  floorName: string | null;
  status: TaskStatus;
  title: string;
}

export const BaseTaskCard = ({
  buildingName,
  children,
  'data-cy': dataCy,
  floorName,
  status,
  title,
}: BaseTaskCardProps) => {
  const { t } = useTranslation('tasks');

  const theme = useTheme();

  return (
    <Box
      data-cy={dataCy}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        py: 5,
        '&:not(:last-child)': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ mb: 1 }} variant="h5">
          {title}
        </Typography>

        {buildingName && floorName && (
          <Typography sx={{ my: 1 }} variant="body1">
            {buildingName + ' / ' + floorName}
          </Typography>
        )}

        {children}
      </Box>

      <Box ml={1}>
        <Chip
          color={status === TASK_STATUSES.COMPLETE ? 'success' : 'error'}
          label={status === TASK_STATUSES.COMPLETE ? t('Complete') : t('Open')}
          size="small"
          sx={{
            color: theme.palette.common.black,
            textTransform: 'uppercase',
            '&.MuiChip-colorError': {
              backgroundColor: `${theme.palette.error.main}4D`,
            },
            '&.MuiChip-colorSuccess': {
              backgroundColor: `${theme.palette.success.main}4D`,
            },
          }}
        />
      </Box>
    </Box>
  );
};
