import type { Namespace } from './constants';

// We don't know keys ahead of time because of interpolation
interface RegisterKeyOptions {
  ns?: Namespace;
  [key: string]: unknown;
}

/*
For usage see:
https://infogrid.atlassian.net/wiki/spaces/DOC/pages/2198077529/Frontend+i18n
 */

// No-op function to make translation parse detect translation keys
// and keep them in translation dicts. To be used on constants and
// other keys outisde of component context. See app/i18next-parser.config.js
// for config details.
export const registerTranslationKey = (
  key: string,
  _0?: RegisterKeyOptions
): string => key;
