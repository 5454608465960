/*
    THIS FILE IS NOT IDENTICAL TO CURRENT `webclient`
*/

// import type { AxiosParsedError } from "./axios";
import { useContext } from "react";
// import type { QueryKey, UseQueryOptions } from "react-query";
// import { useQuery } from "react-query";

import { CubeContext } from "./CubeContext";
import type { CubeSchema, QueryBuilder } from "./types";
// import { wrapBuilder } from "./wrapBuilder";
import useSWR from "swr";
import { FetcherResponse } from "swr/_internal";

import hash from "hash-it";

// export type UseCubeOptions<TData = QueryResult> = Omit<
//   UseQueryOptions<QueryResult, AxiosParsedError, TData, QueryKey>,
//   "queryKey" | "queryFn"
// >;

/**
 * Hook to allow easy use of Cube.dev. If this fails during a test
 * you probably forgot to wrap your test in a <CubeContext.Provider>
 */
export const useCube = <QueryResult, TSchema extends CubeSchema = CubeSchema>(
  endpointKey: string,
  builder: QueryBuilder<TSchema>,
  options?: { signal?: AbortSignal, enabled?: boolean, keepPreviousData?: boolean }
) => {
  const runner = useContext(CubeContext);
  const enabled = options?.enabled ?? true;
  const key = hash(builder.generateQueryKey()).toString();
  return useSWR<QueryResult>(
    enabled ? key : null,
    () =>
      runner.runQuery(
        builder,
        { signal: options?.signal },
        endpointKey
      ) as FetcherResponse<QueryResult>,
    { keepPreviousData: !!options?.keepPreviousData }
  );
};
