import ArrowBack from '@mui/icons-material/ArrowBack';
import { Button, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const CommonContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h1">{t("Something's gone wrong")}</Typography>
      <Typography sx={{ my: 3 }}>
        {t(
          'This page could not be loaded. All your data is secure. Please go back and try again.'
        )}
      </Typography>
      <Link href="/">
        <Button startIcon={<ArrowBack />} variant="outlined">
          {t('Go back')}
        </Button>
      </Link>
    </>
  );
};
