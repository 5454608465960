import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BaseTaskCard } from '../base-task-card';
import { TaskCardItem } from '../task-card-item';

import {
  toDistanceFromNowString,
  toShortDateTime,
} from 'design-system/formatting';
import { TASK_STATUSES } from 'features/task-management/constants';

export interface CompleteTaskCardProps {
  buildingName: string | null;
  dateCompleted: string | null;
  dateCreated: string;
  floorName: string | null;
  title: string;
}

export const CompleteTaskCard = ({
  buildingName,
  dateCreated,
  dateCompleted,
  floorName,
  title,
}: CompleteTaskCardProps) => {
  const {
    i18n: { language },
    t,
  } = useTranslation('tasks');

  const dateCreatedString = toShortDateTime(new Date(dateCreated), language);

  const dateCompletedString = dateCompleted
    ? toShortDateTime(new Date(dateCompleted), language)
    : null;

  const distanceToNowString = toDistanceFromNowString(
    new Date(dateCreated),
    language
  );

  return (
    <BaseTaskCard
      buildingName={buildingName}
      data-cy="complete-task-card"
      floorName={floorName}
      status={TASK_STATUSES.COMPLETE}
      title={title}
    >
      {dateCompleted && (
        <TaskCardItem title={t('Date completed')}>
          <Typography variant="body2">{dateCompletedString}</Typography>
        </TaskCardItem>
      )}
      <TaskCardItem title={t('Date created')}>
        <Typography variant="body2">
          {`${dateCreatedString} (${distanceToNowString})`}
        </Typography>
      </TaskCardItem>
    </BaseTaskCard>
  );
};
