import { Box, CircularProgress, SxProps, Theme } from '@mui/material';

interface CircleLoaderProps {
  sx?: SxProps<Theme>;
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

export const CircleLoader = ({
  sx = {},
  color = 'primary',
}: CircleLoaderProps) => (
  <Box
    data-cy="circle-loader"
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      ...sx,
    }}
  >
    <CircularProgress color={color} />
  </Box>
);
