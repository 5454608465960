import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Building, useBuildings } from 'api/hierarchy';
import { UserBuilding, UserLocationAssociation } from 'api/user';
import { Table } from 'design-system/components';
import { TableProps } from 'design-system/components/table';
import { UserConfigurationEditModal } from 'features/task-management/user-configuration-edit-modal';

const renderLocationsCell = (
  locations: { [key: string]: UserBuilding },
  buildingData: Building[],
  t: TFunction
): ReactNode => {
  const associatedBuildings = Object.values(locations);
  if (
    !associatedBuildings ||
    associatedBuildings.length === 0 ||
    !buildingData ||
    buildingData.length === 0
  ) {
    return (
      <Typography color="error" variant="body3">
        {t('Unset')}
      </Typography>
    );
  }

  return (
    <Typography
      variant="body3"
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      {associatedBuildings
        .map((b) => {
          const loadedBuilding = buildingData.find((item) => item.id === b.id);
          if (!loadedBuilding) {
            return null;
          }

          return `${b.name} - ${t('All floors')}`;
        })
        .filter((b) => b !== null)
        .join('; ')}
    </Typography>
  );
};
const renderHoursCell = (isSet: boolean, t: TFunction) => {
  return (
    <Typography variant="body3" color={isSet ? 'inherit' : 'error'}>
      {isSet ? t('Set') : t('Unset')}
    </Typography>
  );
};
const renderNotificationsCell = (
  name: string,
  emailEnabled: boolean,
  smsEnabled: boolean,
  t: TFunction
) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
        {name}
      </Typography>
      <Typography variant="body3">
        {[
          emailEnabled ? t('Email') : undefined,
          smsEnabled ? t('SMS') : undefined,
        ].join(', ')}
      </Typography>
    </Box>
  );
};

interface UserSetupTableProps {
  onPaginationModelChange: TableProps<UserLocationAssociation>['onPaginationModelChange'];
  paginationModel: TableProps<UserLocationAssociation>['paginationModel'];
  rows: UserLocationAssociation[];
  onSave: () => void;
}

export const UserSetupTable = ({
  onPaginationModelChange,
  paginationModel,
  rows,
  onSave,
}: UserSetupTableProps) => {
  const { t } = useTranslation('tasks');

  const { data: buildingData = [], isLoading: isLoadingBuildings } =
    useBuildings();

  const columns: TableProps<UserLocationAssociation>['columns'] = [
    {
      field: 'full_name',
      headerName: t('User'),
      minWidth: 150,
      maxWidth: 500,
      renderCell: ({ row }) => (
        <Typography variant="body2">{row.full_name}</Typography>
      ),
    },
    {
      field: 'locations',
      headerName: t('Locations'),
      minWidth: 300,
      maxWidth: 500,
      renderCell: ({ row }) =>
        renderLocationsCell(row.locations.buildings, buildingData, t),
    },
    {
      field: 'hours',
      headerName: t('Hours'),
      minWidth: 100,
      renderCell: () => renderHoursCell(false, t),
    },
    {
      field: 'notifications_new_task',
      headerName: t('Notifications'),
      minWidth: 150,
      sortable: false,
      renderCell: () => renderNotificationsCell(t('New task'), true, true, t),
    },
    {
      field: 'notifications_start_shift',
      headerName: '',
      minWidth: 100,
      sortable: false,
      renderCell: () =>
        renderNotificationsCell(t('Start of shift'), true, true, t),
    },
    {
      field: 'notifications_shift_summary',
      headerName: '',
      minWidth: 100,
      sortable: false,
      renderCell: () =>
        renderNotificationsCell(t('Shift summary'), true, true, t),
    },
  ];

  const [editingUserRow, setEditingUserRow] =
    useState<UserLocationAssociation>();

  if (isLoadingBuildings) {
    return <CircularProgress />;
  }

  return (
    <>
      <Table<UserLocationAssociation>
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        rows={rows}
        actions={(row: UserLocationAssociation) => [
          <Button
            variant="text"
            color="primary"
            size="small"
            key="button-edit"
            onClick={() => setEditingUserRow(row)}
          >
            {t('Edit')}
          </Button>,
        ]}
        data-cy="user-setup-table"
      />
      {editingUserRow && (
        <UserConfigurationEditModal
          editingUserRow={editingUserRow}
          isOpen={!!editingUserRow}
          onClose={() => setEditingUserRow(undefined)}
          onSave={onSave}
        />
      )}
    </>
  );
};
