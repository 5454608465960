import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { SORT_OPTIONS, TASK_STATUSES } from '../constants';
import { SortByFilter } from '../sort-by-filter';
import { OpenTaskCard } from '../task-cards/open-task-card';
import { TaskListContainer } from '../task-list-container/task-list-container';
import { TaskSection } from '../task-section';
import { SortOption } from '../types';
import { generateBuildingQueryString, groupTasksByDate } from '../utils';

import { Building } from 'api/hierarchy';
import { useInfiniteTaskList, useTaskCount } from 'api/tasks';
import { BuildingPicker } from 'design-system/components';

export const OpenTasksTab = () => {
  const { t } = useTranslation('tasks');

  const [sortBy, setSortBy] = useState<SortOption>(SORT_OPTIONS.OLDEST_CREATED);
  const [buildings, setBuildings] = useState<Building[]>();

  const { ref, inView } = useInView();

  const buildingQueryString = useMemo(
    () => generateBuildingQueryString(buildings ?? []),
    [buildings]
  );

  const params = useMemo(
    () => ({
      location: buildingQueryString,
      ordering_field: sortBy,
      status: TASK_STATUSES.OPEN,
    }),
    [buildingQueryString, sortBy]
  );

  const { hasMoreData, isLoading, isValidating, onFetchMore, tasks } =
    useInfiniteTaskList(params);

  const { data: taskCountData, isLoading: isLoadingCount } = useTaskCount({
    location: buildingQueryString,
    status: TASK_STATUSES.OPEN,
  });

  useEffect(() => {
    if (inView && !isValidating && hasMoreData) {
      onFetchMore();
    }
  }, [hasMoreData, isValidating, inView, onFetchMore]);

  const groupedTasks = groupTasksByDate(tasks, 'created_at', 'asc');

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 4,
        flexDirection: { xs: 'column', md: 'row' },
        paddingRight: { xs: 0, md: 10 },
        paddingLeft: { xs: 0, md: 10 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row', md: 'column' },
          gap: 3,
        }}
      >
        <SortByFilter
          onChange={setSortBy}
          options={[SORT_OPTIONS.OLDEST_CREATED, SORT_OPTIONS.RECENTLY_CREATED]}
          value={sortBy}
        />
        <BuildingPicker
          label={t('Locations')}
          onChange={setBuildings}
          sx={{ width: 220 }}
          value={buildings}
        />
      </Box>

      <TaskListContainer
        hasMoreData={hasMoreData}
        isEmpty={!isLoading && Object.keys(groupedTasks).length === 0}
        isLoading={isLoading}
        isValidating={isValidating}
        title={t('Open tasks')}
      >
        <Typography data-cy="open-tasks-count" sx={{ mt: 1, minHeight: 24 }}>
          {tasks.length > 0 && !isLoadingCount && (
            <>
              <Box component="span" display="inline" fontWeight="bold">
                {t('{{count}} tasks', {
                  count: taskCountData?.count,
                  defaultValue___one: `${taskCountData?.count} task`,
                  defaultValue___other: `${taskCountData?.count} tasks`,
                })}
              </Box>{' '}
              {t('need to be completed')}
            </>
          )}
        </Typography>

        {Object.entries(groupedTasks).map(([date, tasks]) => (
          <TaskSection key={`tasks_${date}`} date={date}>
            {tasks.map((task) => (
              <OpenTaskCard
                key={task.id}
                buildingName={task.buildingName}
                floorName={task.floorName}
                dateCreated={task.dateCreated}
                title={task.title}
              />
            ))}
          </TaskSection>
        ))}
        <div key="lazy_loader" ref={ref} />
      </TaskListContainer>
    </Box>
  );
};
