import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import ApexCharts from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { Column } from '../../utils';

import { LoadingBackdrop } from 'design-system/components';
import { valueFormatters } from 'design-system/formatting';

interface HeatmapChartProps {
  isLoading: boolean;
  labels: string[];
  datasets: {
    label: string;
    data: unknown[];
    serieColumn?: Column;
  }[];
  height?: number;
  columns: Column[];
}
export const HeatmapChart = ({
  isLoading,
  labels,
  datasets,
  height,
  columns,
}: HeatmapChartProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [yColumn, xColumn] = columns;

  const series = labels.reduce<ApexAxisChartSeries>((acc, label, index) => {
    const keys = label.split(',');
    const y = keys[0];
    const x = keys[1];

    let formattedX = x;
    if (xColumn.columnType !== 'string') {
      formattedX = valueFormatters[xColumn.format ?? 'number'](Number(x), t);
    }
    let formattedY = y;
    if (yColumn.columnType !== 'string') {
      formattedY = valueFormatters[yColumn.format ?? 'number'](Number(y), t);
    }

    const value = datasets[0].data[index];
    const group = datasets[0].label;

    let seriesItem = acc.find((item) => item.name === formattedY);
    if (!seriesItem) {
      seriesItem = { group, name: formattedY, data: [] };
      acc.push(seriesItem);
    }
    // TODO: research how we can remove use of `any` here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    seriesItem.data.push({ x: formattedX, y: value } as any);
    return acc;
  }, []);

  const options: ApexOptions = {
    grid: {
      padding: {
        top: -32,
        right: 0,
        bottom: 0,
        left: 10,
      },
    },
    states: {
      active: {
        filter: {
          // this is to avoid cells to be selectable visually
          type: 'none',
        },
      },
      hover: {
        filter: {
          // ideally we would change opacity but I can't find how to do that
          type: 'lighten',
        },
      },
    },
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      selection: {
        enabled: false,
        type: 'xy',
      },
    },
    stroke: {
      show: true,
      width: 4,
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            '#E7DDEC',
            '#D8C7E3',
            '#CAB1DA',
            '#BB9AD0',
            '#AC81C6',
            '#9D67BA',
            '#8C49AD',
            '#752E96',
            '#4E2468',
            '#271436',
          ].reduce<
            { from: number; to: number; color: string; foreColor: string }[]
          >((acc, color, index) => {
            acc.push({
              from: 10 * (index + 1) - 10,
              to: 10 * (index + 1),
              color,
              foreColor: theme.palette.getContrastText(color),
            });
            return acc;
          }, []),
        },
      },
    },
    dataLabels: {
      style: {
        fontFamily: theme.typography.fontFamily,
      },
      formatter: function (value: number) {
        return `${value.toFixed(0)}%`;
      },
    },
    legend: {
      show: false,
      height: 0,
      containerMargin: {
        top: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      marker: { show: true },
      onDatasetHover: {
        highlightDataSeries: true,
      },

      // Customizing the tooltip content
      // warning this function doesn't hot reload so might need a refresh to apply
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const xValue = w.globals.labels[dataPointIndex];
        const yValue = series[seriesIndex][dataPointIndex];
        const name = w.globals.seriesNames[seriesIndex];
        const group = w.globals.initialSeries[seriesIndex].group;
        return (
          `<div class="apexcharts-tooltip-title" style="font-family: 'Karla';">` +
          `<b>${name} ${xValue}</b><br/>` +
          `<div class="value">${group}: ${yValue.toFixed(0)}%</div>` +
          `</div>`
        );
      },
      style: {
        fontSize: '12px',
        fontFamily: theme.typography.fontFamily,
      },
    },
    xaxis: {
      type: 'category',
      labels: {
        style: {
          fontFamily: theme.typography.fontFamily,
          colors: theme.palette.text.disabled,
        },
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        offsetY: 8,
      },
      tickAmount: 5,
    },
    yaxis: {
      labels: {
        show: !isLoading,
        padding: 12,
        style: {
          fontFamily: theme.typography.fontFamily,
          colors: theme.palette.text.disabled,
        },
      },
    },
  };

  return (
    <Box sx={{ display: 'flex', flex: '1' }}>
      <Box
        position="relative"
        flex="1"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: `${height}px`,
          '& .apexcharts-tooltip': {
            border: 'none',
          },
          '& .apexcharts-tooltip-title': {
            padding: 1,
            fontSize: 12,
            marginBottom: 0,
            borderBottom: 'none',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.common.black,
            '& .value': {
              marginTop: 1,
            },
          },
        }}
      >
        {isLoading && <LoadingBackdrop />}
        <ApexCharts
          options={options}
          series={series}
          type="heatmap"
          // used 80% here to allow space for the legend, this is far from ideal
          height={series.length === 1 ? '20%' : '80%'}
        />
        {/* rendering the legend separately as the component doesn't do what we need out of the box */}
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: 'center', marginTop: 1 }}
        >
          {options.plotOptions?.heatmap?.colorScale?.ranges?.map(
            (range, index) => (
              <Grid
                key={index}
                item
                sx={{ display: 'flex', alignItems: 'center' }}
                gap={1}
              >
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '2px',
                    backgroundColor: range.color,
                  }}
                />
                <Typography variant="body3" color={theme.palette.text.disabled}>
                  {range.from === 0 ? 0 : range.from! + 1}-{range.to}%
                </Typography>
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </Box>
  );
};
