import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportTypePicker } from './report-type-picker';

import { TeamUser } from 'api/types';
import { Modal, SearchInput, UserPicker } from 'design-system/components';
import { ReportTypeValue } from 'features/reports/utils';

interface DensePickerProps {
  reportOwners?: TeamUser[];
  setReportOwners: (value?: TeamUser[] | undefined) => void;
  reportTypes?: ReportTypeValue[];
  setReportTypes: (value?: ReportTypeValue[] | undefined) => void;
}
const DensePickers = ({
  reportOwners,
  setReportOwners,
  reportTypes,
  setReportTypes,
}: DensePickerProps) => {
  const { t } = useTranslation('reports');
  const [isOpen, setIsOpen] = useState(false);

  const summaryOwners = reportOwners?.length
    ? t('{{count}} owner(s)', {
        count: reportOwners?.length,
      })
    : t('All owners');
  const summaryTypes = reportTypes?.length
    ? t('{{count}} type(s)', {
        count: reportTypes?.length,
      })
    : t('All types');
  const summary = `${summaryOwners}, ${summaryTypes}`;

  const [internalReportOwners, setInternalReportOwners] = useState<
    TeamUser[] | undefined
  >(reportOwners);
  const [internalReportTypes, setInternalReportTypes] = useState<
    ReportTypeValue[] | undefined
  >(reportTypes);

  const onSave = () => {
    setReportOwners(internalReportOwners);
    setReportTypes(internalReportTypes);
    setIsOpen(false);
  };
  const onCancel = () => {
    setInternalReportOwners(reportOwners);
    setInternalReportTypes(reportTypes);
    setIsOpen(false);
  };
  return (
    <>
      <OutlinedInput
        data-cy="dense-pickers"
        value={summary}
        onClick={() => setIsOpen(true)}
        sx={{
          flex: 1,
          '&:hover, &:hover *': {
            cursor: 'pointer',
          },
          '& input': {
            pointerEvents: 'none',
            userSelect: 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        inputProps={{
          // the `blur()` is to avoid the input to be focused
          // and the `setTimeout({},0)` is to avoid the input to stay focused after clicking it (and closing the modal)
          onFocus: (e) => setTimeout(() => e.target.blur(), 0),
        }}
        endAdornment={
          <InputAdornment position="end">
            <FilterListIcon />
          </InputAdornment>
        }
      />
      <Modal
        isOpen={isOpen}
        onClose={onCancel}
        title={t('Filters')}
        actions={
          <>
            <Button
              variant="text"
              onClick={onCancel}
              data-cy="dense-filter-cancel"
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={onSave}
              data-cy="dense-filter-save"
            >
              {t('Save')}
            </Button>
          </>
        }
      >
        <Stack spacing={3}>
          <UserPicker
            label="Report owner"
            allLabel="All owners"
            value={internalReportOwners}
            onChange={setInternalReportOwners}
          />
          <ReportTypePicker
            value={internalReportTypes}
            onChange={setInternalReportTypes}
          />
        </Stack>
      </Modal>
    </>
  );
};

interface ReportsFilters {
  search: string;
  setSearch: (newValue: string) => void;
  reportOwners?: TeamUser[];
  setReportOwners: (value?: TeamUser[] | undefined) => void;
  reportTypes?: ReportTypeValue[];
  setReportTypes: (value?: ReportTypeValue[] | undefined) => void;
}
export const ReportsFilters = ({
  search,
  setSearch,
  reportOwners,
  setReportOwners,
  reportTypes,
  setReportTypes,
}: ReportsFilters) => {
  const { t } = useTranslation('reports');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return isDesktop ? (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <SearchInput
        id="search-reports"
        value={search}
        onChange={setSearch}
        placeholder={t('Search reports...')}
      />
      <Box flex="1" />
      <Stack direction="row" spacing={2}>
        <UserPicker
          label="Report owner"
          allLabel="All owners"
          value={reportOwners}
          onChange={setReportOwners}
          menuPosition="right"
        />
        <ReportTypePicker value={reportTypes} onChange={setReportTypes} />
      </Stack>
    </Stack>
  ) : (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      sx={{
        '& .MuiFormControl-root': {
          flex: 1,
        },
      }}
    >
      <SearchInput
        id="search-reports"
        value={search}
        onChange={setSearch}
        placeholder={t('Search reports...')}
      />
      <DensePickers
        reportOwners={reportOwners}
        setReportOwners={setReportOwners}
        reportTypes={reportTypes}
        setReportTypes={setReportTypes}
      />
    </Stack>
  );
};
