import { ThemeOptions, createTheme, lighten } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { deepmerge } from '@mui/utils';

// random colors that aren't explicitely part of the theme but used nonetheless
export const extraPalette = {
  teal: {
    main: '#123C4A',
    dark: '#001F29',
    darker: '#004D62',
  },
};

// The below is the implementation of
// https://www.figma.com/file/KLKPErRt5KXFw5Avoz36BB/%F0%9F%A6%AC-Buffalo-(MUI-Theme-v5.14)?type=design&node-id=6649-50219&mode=design&t=sgdVhmT1bOo3yViy-0

// the light/main palette
export const palette = {
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    text: {
      primary: '#162029',
      secondary: '#434F58',
      disabled: '#798288',
    },
    primary: {
      main: '#0081A1',
      dark: '#006781',
      light: '#1594B2',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#B3F506',
      dark: '#93D204',
      light: '#DBFC68',
      contrastText: '#000000',
    },
    error: {
      main: '#EA1022',
      dark: '#C40E1D',
      light: '#F45B69',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#DA760B',
      dark: '#B36109',
      light: '#F7B267',
      contrastText: '#000000',
    },
    success: {
      main: '#2E7D32',
      dark: '#1B5E20',
      light: '#4CAF50',
      contrastText: '#000000',
    },
    background: {
      default: '#F4F7F7',
    },
    divider: '#0000001F',
  },
};

// This is to merge the palette with other component overrides.
// The palette might make sense to be call in external components,
// but the base here is specifically to build light and dark themes.
const base = deepmerge(palette, {
  typography: {
    fontFamily: 'Karla, sans serif',
    body3: {
      fontFamily: 'Karla, sans serif',
      fontSize: 12,
      fontWeight: 400,
    },
  },
  components: {
    MuiPaper: {
      defaultProps: { elevation: 0, square: true },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'F37 Attila, sans serif',
          fontSize: 60,
          fontWeight: 300,
          letterSpacing: -0.5,
        },
        h2: {
          fontSize: 48,
          fontWeight: 400,
        },
        h3: {
          fontSize: 30,
          fontWeight: 500,
        },
        h4: {
          fontSize: 24,
          fontWeight: 500,
        },
        h5: {
          fontSize: 20,
          fontWeight: 500,
        },
        h6: {
          fontSize: 18,
          fontWeight: 500,
        },
        subtitle1: {
          fontSize: 16,
          fontWeight: 500,
        },
        subtitle2: {
          fontSize: 14,
          fontWeight: 500,
        },
        body1: {
          fontSize: 16,
          fontWeight: 400,
        },
        body2: {
          fontSize: 14,
          fontWeight: 400,
        },
        caption: {
          fontSize: 12,
          fontWeight: 400,
        },
        overline: {
          fontSize: 12,
          fontWeight: 400,
          letterSpacing: 1,
        },
        root: {
          '&.MuiLink-root': {
            cursor: 'pointer',
            textDecoration: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          lineHeight: '24px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '24px',
          fontWeight: 600,
        },
        sizeSmall: {
          fontSize: 13,
          lineHeight: '22px',
        },
        sizeLarge: {
          fontSize: 15,
          lineHeight: '26px',
        },
      },
    },
  },
} as ThemeOptions);

// This is to be able to call `spacing` in derived themes
const baseTheme = createTheme(base);

const inputOverrides = (
  color: string,
  borderColor: string,
  focusColor: string
) => {
  const defaultColor: string = `${color}B2`;
  const defaultBorderColor: string = lighten(borderColor, 0.77);

  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          color: defaultColor,
          '& label.Mui-focused': {
            color: focusColor,
          },
          '& .MuiSvgIcon-root': {
            color: `${color}8F`,
          },
          '& .MuiInputBase-adornedEnd.Mui-focused': {
            width: 400,
          },
          '& .search-input-expanded': {
            width: 400,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: defaultColor,
          '&.Mui-focused': {
            color: focusColor,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: defaultBorderColor,
          color: `${color}2E`,
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: color,
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: color,
            border: `2px solid ${focusColor}`,
          },
          [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: defaultBorderColor,
            border: `1px solid ${defaultBorderColor}`,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&::before, &::after': {
            borderBottom: `1px solid ${defaultBorderColor}`,
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${color}`,
          },
          '&.Mui-focused:after': {
            borderBottom: `2px solid ${color}`,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&::before, &::after': {
            borderBottom: `1px solid ${defaultBorderColor}`,
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${color}`,
          },
          '&.Mui-focused:after': {
            borderBottom: `2px solid ${color}`,
          },
        },
      },
    },
  };
};

// used on most of the app
export const lightTheme = createTheme(
  deepmerge(base, {
    components: {
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            alignItems: 'center',
            gap: baseTheme.spacing(1),
          },
          grouped: {
            '&:not(:last-of-type),&:not(:first-of-type)': {
              borderColor: baseTheme.palette.divider,
              borderRadius: baseTheme.spacing(0.5, 0.5, 0.5, 0.5),
              '&:hover': {
                background: `${baseTheme.palette.common.black}9`,
                borderColor: baseTheme.palette.common.black,
              },
              '&:focus': {
                background: `${baseTheme.palette.common.black}12`,
                borderColor: baseTheme.palette.common.black,
              },
              '&.Mui-selected': {
                borderColor: baseTheme.palette.primary.main,
              },
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: baseTheme.palette.text.secondary,
            padding: baseTheme.spacing(1, 1.5),
            '&.MuiIconButton-root': {
              padding: baseTheme.spacing(1),
            },
            '& .MuiSvgIcon-root': {
              color: `${baseTheme.palette.common.black}8F`,
            },
            '&:hover': {
              background: `${baseTheme.palette.common.black}9`,
              borderColor: baseTheme.palette.common.black,
            },
            '&:focus': {
              background: `${baseTheme.palette.common.black}12`,
              borderColor: baseTheme.palette.common.black,
            },
            '&.Mui-selected': {
              borderColor: baseTheme.palette.primary.main,
              '& .MuiSvgIcon-root': {
                color: baseTheme.palette.primary.main,
              },
            },
          },
          sizeSmall: {
            padding: baseTheme.spacing(0.75, 1.25),
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            color: palette.palette.text.primary,
            marginBottom: baseTheme.spacing(1),
          },
          h5: {
            color: palette.palette.text.secondary,
          },
        },
      },
      ...inputOverrides(
        palette.palette.common.black,
        palette.palette.common.black,
        palette.palette.primary.main
      ),
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: palette.palette.common.white,
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            background: palette.palette.common.white,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiTab-root': {
              marginRight: baseTheme.spacing(3),
              '&:last-child': {
                marginRight: 0,
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: 20,
            textTransform: 'none',
            padding: baseTheme.spacing(1, 0),
            minWidth: 0,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            '&.clickable': {
              cursor: 'pointer',
              boxSizing: 'content-box',
              padding: 2,
              '&:hover': {
                border: `1px solid ${palette.palette.primary.main}`,
                padding: 1,
              },
              '&:focus': {
                border: `2px solid ${palette.palette.primary.main}`,
                padding: 0,
              },
              '&:active': {
                border: `1px solid ${palette.palette.primary.main}`,
                padding: 1,
                background: palette.palette.background.default,
              },
            },
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(0.5),
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.text.primary,
            color: baseTheme.palette.common.white,
            '& .MuiButtonBase-root': {
              color: baseTheme.palette.common.white,
            },
            '& .MuiButton-containedPrimary': {
              backgroundColor: extraPalette.teal.darker,
            },
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.text.primary,
            '& .MuiButtonBase-root': {
              color: `${baseTheme.palette.common.white}B2`,
              padding: baseTheme.spacing(1, 2),
              '&.Mui-selected': {
                color: baseTheme.palette.secondary.main,
              },
            },
            '& .Mui-selected': {
              backgroundColor: baseTheme.palette.primary.main,
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            slotProps: {
              paper: {
                style: {
                  maxHeight: 360,
                },
              },
            },
          },
        },
        styleOverrides: {
          outlined: {
            // 56 is the intended size, minus twice vertical padding
            height: `calc(56px - ${baseTheme.spacing(2 * 2)})`,
            padding: baseTheme.spacing(2),
            '& .MuiListItemText-root': {
              marginTop: 0,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          sizeSmall: {
            padding: baseTheme.spacing(0, 0.5),
          },
        },
      },
      MuiListItem: {
        defaultProps: {
          disablePadding: true,
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: { padding: baseTheme.spacing(1, 4) },
        },
      },
      MuiMenu: {
        defaultProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          slotProps: { paper: { square: false } },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(1, 3),
          },
          icon: { alignItems: 'center' },
        },
      },
    },
  } as ThemeOptions)
);

// used only in the login pages for now
export const darkTheme = createTheme(
  deepmerge(baseTheme, {
    palette: {
      text: {
        primary: palette.palette.common.white,
        secondary: '#FFFFFFB2',
        disabled: '#FFFFFF61',
      },
      primary: {
        main: palette.palette.secondary.main,
        dark: palette.palette.secondary.dark,
        light: palette.palette.secondary.light,
        contrastText: palette.palette.secondary.contrastText,
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: palette.palette.common.white,
      },
      warning: {
        main: '#FFA726',
        dark: '#F57C00',
        light: '#FFB74D',
        contrastText: palette.palette.common.black,
      },
      success: {
        main: '#66BB6A',
        dark: '#388E3C',
        light: '#81C784',
        contrastText: palette.palette.common.black,
      },
      background: {
        default: extraPalette.teal.main,
      },
      divider: '#FFFFFF1F',
    },
    components: {
      ...inputOverrides(
        palette.palette.common.white,
        palette.palette.common.black,
        palette.palette.secondary.main
      ),
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            textTransform: 'uppercase',
            letterSpacing: 1,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              backgroundColor: `${baseTheme.palette.secondary.main} !important`,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: extraPalette.teal.dark,
          },
        },
      },
    },
  })
);
