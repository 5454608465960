import ApartmentIcon from '@mui/icons-material/Apartment';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReportLocationsSummary } from '..';
import { getEffectiveTimePeriod } from '../utils';

import { ReportDetailed } from 'api/reports';
import { toShortDate } from 'design-system/formatting';

interface ReportTitleSecondaryProps {
  report: ReportDetailed;
}
export const ReportTitleSecondary = ({ report }: ReportTitleSecondaryProps) => {
  const theme = useTheme();
  const {
    i18n: { language },
    t,
  } = useTranslation('reports');

  const getTimePeriodText = (
    dateFrom?: string,
    dateTo?: string,
    duration?: number
  ) => {
    if (dateFrom && dateTo) {
      const from = new Date(dateFrom);
      const to = new Date(dateTo);

      if (duration === 1) {
        return toShortDate(from, language);
      }
      return `${toShortDate(from, language)} - ${toShortDate(to, language)}`;
    }

    if (duration) {
      const { date_to, date_from } = getEffectiveTimePeriod(
        new Date(),
        duration
      );

      const durationString = t('Last {{count}} Days', { count: duration });

      if (duration === 1) {
        return `${durationString} (${toShortDate(date_from, language)})`;
      }

      return `${durationString} (${toShortDate(date_from, language)} - ${toShortDate(date_to, language)})`;
    }
  };

  const getWorkingHoursText = (buildingHours: string) => {
    switch (buildingHours) {
      case 'building_specific':
        return t('Working hours');
      case 'weekdays':
        return t('Weekdays only (24 hours)');
      case 'all':
        return t('Every day (24 hours)');
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ lineHeight: 2, color: theme.palette.text.secondary }}
      data-cy="report-title-secondary"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <CalendarTodayOutlinedIcon fontSize="small" />
        <Typography
          variant="body2"
          lineHeight="inherit"
          data-cy="report-time-period-text"
        >
          {getTimePeriodText(report.date_from, report.date_to, report.duration)}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <ApartmentIcon fontSize="small" />
        <ReportLocationsSummary
          buildingCount={report.building_ids.length}
          floorCount={report.floor_ids.length}
          spaceCount={report.space_ids.length}
        />
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <ScheduleIcon fontSize="small" />
        <Typography
          variant="body2"
          lineHeight="inherit"
          data-cy="report-building-hours-text"
        >
          {getWorkingHoursText(report.building_working_hours)}
        </Typography>
      </Stack>
    </Stack>
  );
};
