import type {
  BinaryOperator,
  DateRange,
  Filter,
  Query,
  QueryOrder,
  TQueryOrderArray,
  TimeDimension,
  TimeDimensionGranularity,
  UnaryOperator,
} from "@cubejs-client/core";

import { format } from "date-fns";
import { filterBuilder } from "./filterBuilder";
import type {
  CubeDescriptor,
  CubeQueryKey,
  CubeSchema,
  FilterBuilder,
  QueryBuilder,
  QueryEnhancer,
} from "./types";

class ConcreteQueryBuilder<T extends CubeSchema> implements QueryBuilder<T> {
   
  protected cube: CubeDescriptor;
  protected measures: T["measures"] = [];
  protected dimensions: T["dimensions"] = [];
  protected timeDimensions: TimeDimension[] = [];
  protected orderBy: TQueryOrderArray = [];
  protected filters: Filter[] = []; // Sort out the type of this
  protected limit: number | undefined;
   

  constructor(cube: CubeDescriptor) {
    this.cube = cube;
  }

  // @inheritdoc
  addMeasure(measure: T["measures"][number]) {
    this.measures.push(measure);

    return this;
  }

  // @inheritdoc
  addDimension(dimension: T["dimensions"][number]) {
    this.dimensions.push(dimension);

    return this;
  }

  // @inheritdoc
  addOrderBy(
    measureOrDimension: T["dimensions"][number] | T["measures"][number],
    direction: QueryOrder
  ) {
    this.orderBy.push([measureOrDimension, direction]);

    return this;
  }

  // @inheritdoc
  addLimit(count: number) {
    this.limit = count;

    return this;
  }

  // @inheritdoc
  addTimeDimension(
    dimension: T["timeDimensions"][number],
    granularity: TimeDimensionGranularity | undefined,
    dateRange: string | [Date, Date]
  ) {
    let range: DateRange;

    const formatISO8601 = (value: Date) => format(value, "yyyy-MM-dd'T'HH:mm:ss'Z'");
    if (typeof dateRange === "string") {
      range = dateRange;
    } else {
      range = [formatISO8601(dateRange[0]), formatISO8601(dateRange[1])];
    }

    this.timeDimensions.push({
      dimension,
      granularity,
      dateRange: range,
    });

    return this;
  }

  // @inheritdoc
  addFilter(
    dimension: T["dimensions"][number],
    operator: BinaryOperator | UnaryOperator,
    compareValues?: (string | number | boolean | Date)[]
  ) {
    // This also doesn't currently offer the flexability of an OR or AND filter...
    this.filters.push({
      member: dimension,
      operator,
      values: compareValues,
    } as Filter);

    return this;
  }

  // @inheritdoc
  addComplexFilter(transform: (fb: FilterBuilder<T>) => Filter) {
    const builder = filterBuilder();
    const filter = transform(builder);

    this.filters.push(filter);

    return this;
  }

  // @inheritdoc
  enhance(visit: QueryEnhancer) {
    visit(this);

    return this;
  }

  // @inheritdoc
  buildQuery(): Query {
    return {
      measures: this.measures,
      dimensions: this.dimensions,
      order: this.orderBy,
      timeDimensions: this.timeDimensions,
      filters: this.filters,
      limit: this.limit,
    };
  }

  // @inheritdoc
  generateQueryKey(): CubeQueryKey {
    return {
      measures: this.measures,
      dimensions: this.dimensions,
      order: this.orderBy,
      timeDimensions: this.timeDimensions,
      filters: this.filters,
      limit: this.limit,
    };
  }
}

export const queryBuilder = <T extends CubeSchema>(
  cube: CubeDescriptor
): QueryBuilder<T> => {
  return new ConcreteQueryBuilder(cube);
};
