import { TFunction } from 'i18next';

import { SPACE_TYPE_LABELS } from '../constants';

import { Column, DEFAULT_SCHEMA, TimeGranularity } from '.';

import {
  FormatType,
  formatDatetime,
  valueFormatters,
} from 'design-system/formatting';
import { registerTranslationKey } from 'packages/i18n';

const UNKNOWN_SERIES_IDENTIFIER = '∅';
const UNKNOWN = registerTranslationKey('Unknown');
export const formatLabel = (
  t: TFunction,
  value: string,
  dimension?: string
) => {
  if (dimension === DEFAULT_SCHEMA.DIMENSIONS.SPACE_TYPE.name) {
    return value === UNKNOWN_SERIES_IDENTIFIER
      ? t(UNKNOWN)
      : t(SPACE_TYPE_LABELS[value]);
  }
  const actualDimension = Object.values(DEFAULT_SCHEMA.DIMENSIONS).find(
    (d) => d.name === dimension
  );
  if (['day', 'hour'].includes(actualDimension?.meta?.format as FormatType)) {
    return valueFormatters[actualDimension?.meta?.format as FormatType](
      Number(value),
      t
    );
  }

  return value;
};
export const getFormattedValue = (
  value: unknown | null,
  column: Column,
  t: TFunction,
  granularity?: TimeGranularity
) => {
  // Translate space type name into applicable frontend string
  if (
    // this array condition should be based on format rather than dimensions
    [
      DEFAULT_SCHEMA.DIMENSIONS.SPACE_TYPE.name,
      DEFAULT_SCHEMA.DIMENSIONS.TIMESTAMP_DAY_OF_WEEK.name,
      DEFAULT_SCHEMA.DIMENSIONS.TIMESTAMP_HOUR_OF_DAY.name,
    ].includes(column.field)
  ) {
    return formatLabel(t, String(value), column.field);
  }

  if (value === null) return '-';

  if (column?.columnType === 'time')
    // value looks like '2022-01-01T00:00:00.000'
    return formatDatetime(String(value), granularity);

  if (column?.columnType === 'number') {
    // value looks like `0.000000000000` or `0`
    const numericValue = parseFloat(String(value));
    const format = column.format ?? 'number';
    return valueFormatters[format](numericValue, t);
  }

  return String(value);
};
