import type { Filter } from '@cubejs-client/core';

import type { FilterBuilder, CubeSchema } from './types';

export const filterBuilder = <T extends CubeSchema = CubeSchema>(): FilterBuilder<T> => ({
    equals(dimension: T['dimensions'][number], values: string[]) {
        return {
            member: dimension,
            operator: 'equals' as const,
            values,
        };
    },

    null(dimension: T['dimensions'][number]) {
        return {
            member: dimension,
            operator: 'notSet' as const,
        };
    },

    anyOf: (filters: Filter[]) =>
        filters.length === 1
            ? filters[0]
            : {
                  or: filters,
              },

    allOf: (filters: Filter[]) =>
        filters.length === 1
            ? filters[0]
            : {
                  and: filters,
              },
});
