import axios from 'axios';
import { useCallback, useMemo } from 'react';
import useSWR, { Fetcher } from 'swr';
import useSWRImmutable from 'swr/immutable';
import useSWRInfinite from 'swr/infinite';
import useSWRMutation from 'swr/mutation';

import SETTINGS from '../settings';

import { CursorResponse } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Mapper = <T>(data: any) => T;

const getInfogridAxios = () => {
  return axios.create({
    baseURL: SETTINGS.COREAPI_API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('_auth')}`,
      'X-Infogrid-Organization': getCurrentOrganizationId(),
    },
  });
};

// Typescript generics should be infered from `useSWRImmutable<T>()`
const coreApiGetFetcher = <T>(
  endpoint: string,
  params?: unknown,
  mapper: Mapper = (d) => d
) => {
  return getInfogridAxios()
    .get(endpoint, {
      params,
    })
    .then((res) => res.data)
    .then<T>(mapper);
};

const useGetCoreAPIInternal = <T>(
  endpoint: string | null,
  useSWRHook: typeof useSWR | typeof useSWRImmutable,
  params?: unknown,
  mapper?: Mapper
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetcher: Fetcher<any, [string, unknown]> = ([e, p]) =>
    coreApiGetFetcher(e, p, mapper);
  return useSWRHook<T>(
    // this ensures that null is never passed to the fetcher as an endpoint - https://swr.vercel.app/docs/conditional-fetching
    // we use an array as a key as per this: https://swr.vercel.app/docs/arguments#multiple-arguments
    endpoint === null
      ? endpoint
      : [endpoint, params, getUserIdFromToken(), getCurrentOrganizationId()],
    fetcher,
    {
      keepPreviousData: true,
    }
  );
};
export const useGetCoreAPI = <T>(
  endpoint: string | null,
  params?: unknown,
  mapper?: Mapper
) => useGetCoreAPIInternal<T>(endpoint, useSWR, params, mapper);
export const useGetCoreAPIImmutable = <T>(
  endpoint: string | null,
  params?: unknown,
  mapper?: Mapper
) => useGetCoreAPIInternal<T>(endpoint, useSWRImmutable, params, mapper);

export const useGetCoreAPIInfinite = <T, P = Record<string, unknown>>(
  endpoint: string | null,
  params?: P,
  mapper?: Mapper
) => {
  const getKey = useCallback(
    (pageIndex: number, previousPageData: CursorResponse<T> | null) => {
      // Base case for the first page fetch
      if (pageIndex === 0) {
        return [
          endpoint,
          params,
          getUserIdFromToken(),
          getCurrentOrganizationId(),
        ];
      }
      // Check for the next cursor to determine if there are more pages
      if (!previousPageData?.next?.cursor) {
        return null; // Signal to stop fetching
      }
      // Construct the key for the next page fetch
      return [
        endpoint,
        { ...params, cursor: previousPageData.next?.cursor },
        getUserIdFromToken(),
        getCurrentOrganizationId(),
      ];
    },
    [endpoint, params]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetcher: Fetcher<any, [string, string]> = useCallback(
    ([endpoint, serializedParams]) => {
      const deserializedParams = serializedParams;
      return coreApiGetFetcher(endpoint, deserializedParams, mapper);
    },
    [mapper]
  );

  const { data, setSize, size, ...rest } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
  });

  // Leverage the SWR `data` to determine `hasMoreData`
  const hasMoreData = useMemo(() => {
    const lastPageData = data?.[data.length - 1];
    return !!lastPageData?.next?.cursor;
  }, [data]);

  const onFetchMore = useCallback(() => {
    if (hasMoreData) setSize(size + 1);
  }, [hasMoreData, setSize, size]);

  return {
    data,
    setSize,
    size,
    onFetchMore,
    hasMoreData,
    ...rest,
  };
};

const coreApiPost = <T, P>(
  resource: string,
  { arg }: { arg: { body: T; id?: string; entity?: string } }
) => {
  const finalEndpoint =
    arg.id && arg.entity ? `${resource}/${arg.id}/${arg.entity}` : resource;
  return getInfogridAxios()
    .post(finalEndpoint, arg.body)
    .then((r) => r.data as P);
};
export const usePostCoreApi = <T, P>(
  endpoint: string,
  callback: () => void = () => {}
) => {
  return useSWRMutation(endpoint, coreApiPost<T, P>, {
    onSuccess: callback,
  });
};

const coreApiPut = async <T>(
  endpoint: string,
  { arg }: { arg: { id?: string; body: T } }
) => {
  const path = arg.id ? `${endpoint}/${arg.id}` : endpoint;
  return await getInfogridAxios().put(path, arg.body);
};
export const usePutCoreApi = <T>(
  endpoint: string,
  callback: () => void = () => {}
) => {
  return useSWRMutation(endpoint, coreApiPut<T>, {
    onSuccess: callback,
  });
};

const coreApiDelete = async (
  endpoint: string,
  { arg }: { arg: { id: string } }
) => {
  return await getInfogridAxios().delete(`${endpoint}/${arg.id}`);
};
export const useDeleteCoreApi = (
  endpoint: string,
  callback: () => void = () => {}
) => {
  return useSWRMutation(endpoint, coreApiDelete, {
    onSuccess: callback,
  });
};

export const SALES_DEMO_ORG_ID = 442;
const ORG_ID_KEY_NAME = 'infogrid_organization';
export const setOrganizationId = (orgId: number) => {
  localStorage.setItem(ORG_ID_KEY_NAME, orgId.toString());
};
export const getCurrentOrganizationId = () =>
  localStorage.getItem(ORG_ID_KEY_NAME);
export const isOrganizationIdSet = () => {
  const orgId = localStorage.getItem(ORG_ID_KEY_NAME);
  return !!orgId;
};
const getUserIdFromToken = () => {
  const token = localStorage.getItem('_auth');
  if (!token) {
    return null;
  }
  return getUserIdFromTokenString(token);
};
export const getUserIdFromTokenString = (token: string) => {
  const payload = token.split('.')[1];
  const decoded = atob(payload);
  const parsed: { user_id: string } = JSON.parse(decoded);
  return parsed.user_id;
};
