import { MenuItem, MenuItemProps } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Report,
  useCharts,
  useDuplicateReport,
  useReport,
} from '../../../api/reports';

interface ReportDuplicateMenuItemProps extends MenuItemProps {
  report: Report;
  onDuplicated?: () => void;
  isOpen: boolean;
}

export const ReportDuplicateMenuItem = forwardRef<
  HTMLLIElement,
  ReportDuplicateMenuItemProps
>(({ report, onDuplicated = () => {}, isOpen, ...props }, ref) => {
  const { t } = useTranslation('reports');

  const { data: detailedReport } = useReport(isOpen ? report.id : null);
  const { data: charts } = useCharts(isOpen ? report.id : null);
  const { trigger: onDuplicateReport, isMutating: isCreatingDuplicateReport } =
    useDuplicateReport(onDuplicated);

  return (
    <MenuItem
      ref={ref}
      data-cy="menu-duplicate"
      onClick={() =>
        detailedReport &&
        onDuplicateReport(detailedReport, charts?.results ?? [])
      }
      disabled={isCreatingDuplicateReport}
      {...props}
    >
      {t('Duplicate')}
    </MenuItem>
  );
});

ReportDuplicateMenuItem.displayName = 'ReportDuplicateMenuItem';
