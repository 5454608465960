import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import SETTINGS from '../../settings';

import { LoginContainer } from './components';
import { useSignIn } from './utils';

export const LoginSSOPage = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  const [login, setLogin] = useState('');
  const [auth0AccessToken, setAuth0AccessToken] = useState<string | null>(null);
  const [auth0TokenError, setAuth0TokenError] = useState<string | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const {
    isAuthenticated: isAuth0Authenticated,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  // The first part of the login which gets the JWT from Auth0
  const onSsoLogin = useCallback(() => {
    setIsLoggingIn(true);
    const prompt = auth0TokenError ? 'login' : 'select_account';
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${SETTINGS.SMART_CLEANING_URL}login-sso`,
        login_hint: login,
        audience: SETTINGS.AUTH0_AUDIENCE,
        prompt,
      },
    });
  }, [loginWithRedirect, auth0TokenError, login]);

  // The second part of the login where we swap the Auth0 token for one from CoreAPI
  useEffect(() => {
    if (!isAuth0Loading && isAuth0Authenticated && auth0AccessToken === null) {
      getAccessTokenSilently({
        authorizationParams: {
          redirect_uri: `${SETTINGS.SMART_CLEANING_URL}login-sso`,
          audience: SETTINGS.AUTH0_AUDIENCE,
        },
      }).then((token: string) => {
        setAuth0AccessToken(token);
        axios
          .post(`${SETTINGS.COREAPI_API_URL}auth/token/auth0`, undefined, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => signIn(response))
          .catch((error) => {
            setAuth0TokenError(
              error?.response?.data?.detail || t('An unknown error occured')
            );
          });
      });
    }
  }, [
    isAuth0Loading,
    isAuth0Authenticated,
    auth0AccessToken,
    getAccessTokenSilently,
    navigate,
    signIn,
    t,
  ]);

  if (isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return (
    <LoginContainer back={() => navigate(`/login`)}>
      <Typography variant="h4" align="center">
        {t('Log in with Single Sign-On')}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="email">{t('Email')}</InputLabel>
        <OutlinedInput
          id="email"
          label={t('Email')}
          autoFocus
          value={login}
          onChange={(e) => setLogin(e.currentTarget.value)}
        />
      </FormControl>
      {auth0TokenError && (
        <Grid item>
          <Alert severity="error">{auth0TokenError}</Alert>
        </Grid>
      )}
      <Box display="flex" alignContent="space-between" alignItems="center">
        <Typography
          color="secondary"
          flex="1"
          variant="body2"
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            window.open(
              'https://help.infogrid.io/en/articles/6639979-single-sign-on',
              '_blank'
            )
          }
        >
          {t('Help with SSO')}
        </Typography>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={onSsoLogin}
          loading={isLoggingIn}
        >
          {t('Continue')}
        </LoadingButton>
      </Box>
    </LoginContainer>
  );
};

export const LoginSSOPageWrapper = () => {
  if (!SETTINGS.AUTH0_DOMAIN || !SETTINGS.AUTH0_CLIENT_ID) {
    return <Navigate to="/login" />;
  }
  return (
    <Auth0Provider
      domain={SETTINGS.AUTH0_DOMAIN}
      clientId={SETTINGS.AUTH0_CLIENT_ID}
      useCookiesForTransactions
    >
      <LoginSSOPage></LoginSSOPage>
    </Auth0Provider>
  );
};
