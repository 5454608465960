import type { ReactNode } from 'react';

import { UserEdit } from '../types';

import { UserEditContext } from './context';

interface UserEditProviderProps {
  children: ReactNode;
  value: UserEdit;
}

export const UserEditProvider = ({
  children,
  value,
}: UserEditProviderProps) => {
  return (
    <UserEditContext.Provider value={value}>
      {children}
    </UserEditContext.Provider>
  );
};
