import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CircleLoader } from 'design-system/components';

interface TaskListContainerProps {
  children: ReactNode;
  hasMoreData: boolean;
  isEmpty: boolean;
  isLoading: boolean;
  isValidating: boolean;
  title: string;
}

export const TaskListContainer = ({
  children,
  hasMoreData,
  isEmpty,
  isLoading,
  isValidating,
  title,
}: TaskListContainerProps) => {
  const { t } = useTranslation('tasks');

  return (
    <Box sx={{ flex: 1, px: 5 }}>
      <Typography variant="h3">{title}</Typography>

      {isLoading && <CircleLoader sx={{ padding: 20 }} />}

      {isEmpty && (
        <Box
          data-cy="empty-tasks"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Typography>{t('There are no tasks to show.')}</Typography>
        </Box>
      )}

      {!isLoading && children}

      {!isLoading && isValidating && <CircleLoader />}

      {!hasMoreData && !isEmpty && !isValidating && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            opacity: 0.5,
          }}
        >
          <Typography data-cy="no-more-tasks" variant="body2">
            {t('No more tasks to show')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
