import { Box, Typography } from '@mui/material';
import sub from 'date-fns/sub';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { SORT_OPTIONS, TASK_STATUSES } from '../constants';
import { SortByFilter } from '../sort-by-filter';
import { CompleteTaskCard } from '../task-cards/complete-task-card';
import { TaskListContainer } from '../task-list-container/task-list-container';
import { TaskSection } from '../task-section';
import { SortOption } from '../types';
import { generateBuildingQueryString, groupTasksByDate } from '../utils';

import { Building } from 'api/hierarchy';
import { useInfiniteTaskList, useTaskCount } from 'api/tasks';
import { BuildingPicker } from 'design-system/components';

export const CompleteTasksTab = () => {
  const { t } = useTranslation('tasks');

  const [sortBy, setSortBy] = useState<SortOption>(
    SORT_OPTIONS.RECENTLY_COMPLETED
  );
  const [buildings, setBuildings] = useState<Building[]>([]);

  const { ref, inView } = useInView();

  const buildingQueryString = useMemo(
    () => generateBuildingQueryString(buildings),
    [buildings]
  );

  // We memoize here so that we don't infinitely call the API with an ever changing completed_from_date. However, memoizing it
  // based on the location means that we can send an up-to-date completed_from_date when the location changes, which seems like
  // a reasonable compromise
  const taskCountParams = useMemo(
    () => ({
      completed_from_date: sub(new Date(), { days: 2 }).toISOString(),
      location: buildingQueryString,
      status: TASK_STATUSES.COMPLETE,
    }),
    [buildingQueryString]
  );

  const { hasMoreData, isLoading, isValidating, onFetchMore, tasks } =
    useInfiniteTaskList({
      location: buildingQueryString,
      ordering_field: sortBy,
      status: TASK_STATUSES.COMPLETE,
    });

  const { data: taskCountData, isLoading: isLoadingCount } =
    useTaskCount(taskCountParams);

  useEffect(() => {
    if (inView && !isValidating && hasMoreData) {
      onFetchMore();
    }
  }, [hasMoreData, isValidating, inView, onFetchMore]);

  const groupedTasks = groupTasksByDate(tasks, 'status_done_at', 'desc');

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 4,
        flexDirection: { xs: 'column', md: 'row' },
        paddingRight: { xs: 0, md: 10 },
        paddingLeft: { xs: 0, md: 10 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row', md: 'column' },
          gap: 3,
        }}
      >
        <SortByFilter
          onChange={setSortBy}
          options={[
            SORT_OPTIONS.RECENTLY_COMPLETED,
            SORT_OPTIONS.OLDEST_COMPLETED,
          ]}
          value={sortBy}
        />
        <BuildingPicker
          label={t('Locations')}
          onChange={setBuildings}
          sx={{ width: 220 }}
          value={buildings}
        />
      </Box>

      <TaskListContainer
        hasMoreData={hasMoreData}
        isEmpty={!isLoading && Object.keys(groupedTasks).length === 0}
        isLoading={isLoading}
        isValidating={isValidating}
        title={t('Complete tasks')}
      >
        <Typography
          data-cy="complete-tasks-count"
          sx={{ mt: 1, minHeight: 24 }}
        >
          {tasks.length > 0 && !isLoadingCount && (
            <>
              <Box component="span" display="inline" fontWeight="bold">
                {t('{{count}} tasks', {
                  count: taskCountData?.count,
                  defaultValue___one: `${taskCountData?.count} task`,
                  defaultValue___other: `${taskCountData?.count} tasks`,
                })}
              </Box>{' '}
              {t('completed in the last 48 hours')}
            </>
          )}
        </Typography>

        {Object.entries(groupedTasks).map(([date, tasks]) => (
          <TaskSection key={`tasks_${date}`} date={date}>
            {tasks.map((task) => (
              <CompleteTaskCard
                key={task.id}
                buildingName={task.buildingName}
                floorName={task.floorName}
                dateCompleted={task.dateCompleted}
                dateCreated={task.dateCreated}
                title={task.title}
              />
            ))}
          </TaskSection>
        ))}
        <div key="lazy_loader" ref={ref} />
      </TaskListContainer>
    </Box>
  );
};
