import { Grid, Paper } from '@mui/material';
import { Children, ReactNode } from 'react';

interface SummarySectionProps {
  children: ReactNode;
}

export const SummarySection = ({ children }: SummarySectionProps) => (
  <Grid component={Paper} container item sx={{ p: 5 }}>
    {Children.map(children, (child) => (
      <Grid item xs={12} sm={6} md={4} sx={{ mb: { xs: 5, md: 0 } }}>
        {child}
      </Grid>
    ))}
  </Grid>
);
