import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { generateTitle } from './utils';

interface TaskSectionProps {
  children: ReactNode;
  date: string;
}

export const TaskSection = ({ children, date }: TaskSectionProps) => {
  const {
    i18n: { language },
    t,
  } = useTranslation('tasks');

  return (
    <>
      <Typography data-cy="task-section-title" sx={{ mt: 4.5 }} variant="h4">
        {generateTitle(new Date(date), t, language)}
      </Typography>
      <Box>{children}</Box>
    </>
  );
};
