import { registerTranslationKey } from 'packages/i18n';

// Copied from the old webclient, ideally we would have a single point of reference for this

export const SPACE_TYPE_IDS = {
  ACCESSIBLE_BATHROOM_CUBICLE: 'accessible bathroom cubicle',
  COMMUNAL_AREA: 'communal area',
  DESK_AREA: 'desk area',
  GENDER_NEUTRAL_BATHROOM: 'gender neutral bathroom',
  KITCHEN: 'kitchen',
  MEETING_ROOM: 'meeting room',
  MENS_BATHROOM: 'men bathroom',
  OTHER: 'other',
  RECEPTION_AREA: 'reception area',
  SINGLE_CUBICLE: 'single cubicle',
  SINGLE_USER_BATHROOM: 'single user bathroom',
  VERTICAL_CIRCULATION_POINT: 'vertical circulation point',
  WOMEN_BATHROOM: 'women bathroom',
};

export const SPACE_TYPE_LABELS = {
  [SPACE_TYPE_IDS.ACCESSIBLE_BATHROOM_CUBICLE]: registerTranslationKey(
    'Accessible restroom areas'
  ),
  [SPACE_TYPE_IDS.COMMUNAL_AREA]: registerTranslationKey('Communal areas'),
  [SPACE_TYPE_IDS.DESK_AREA]: registerTranslationKey('Desk areas'),
  [SPACE_TYPE_IDS.GENDER_NEUTRAL_BATHROOM]: registerTranslationKey(
    'Gender neutral restrooms'
  ),
  [SPACE_TYPE_IDS.KITCHEN]: registerTranslationKey('Kitchens'),
  [SPACE_TYPE_IDS.MEETING_ROOM]: registerTranslationKey('Meeting rooms'),
  [SPACE_TYPE_IDS.MENS_BATHROOM]: registerTranslationKey("Men's restrooms"),
  [SPACE_TYPE_IDS.OTHER]: registerTranslationKey('Other'),
  [SPACE_TYPE_IDS.RECEPTION_AREA]: registerTranslationKey('Reception areas'),
  [SPACE_TYPE_IDS.SINGLE_CUBICLE]: registerTranslationKey('Single cubicles'),
  [SPACE_TYPE_IDS.SINGLE_USER_BATHROOM]: registerTranslationKey(
    'Single user restrooms'
  ),
  [SPACE_TYPE_IDS.VERTICAL_CIRCULATION_POINT]: registerTranslationKey(
    'Vertical circulation points'
  ),
  [SPACE_TYPE_IDS.WOMEN_BATHROOM]: registerTranslationKey("Women's restrooms"),
};

export const SHORT_DAYS_OF_WEEK: { [key: number]: string } = {
  1: registerTranslationKey('Mon'),
  2: registerTranslationKey('Tue'),
  3: registerTranslationKey('Wed'),
  4: registerTranslationKey('Thu'),
  5: registerTranslationKey('Fri'),
  6: registerTranslationKey('Sat'),
  7: registerTranslationKey('Sun'),
};
