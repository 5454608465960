import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LoadingBackdropProps {
  absolute?: boolean;
}

export const LoadingBackdrop = ({ absolute = true }: LoadingBackdropProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Backdrop
      open={true}
      sx={{
        alignItems: 'center',
        backgroundColor: alpha(theme.palette.common.black, 0.38),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        position: absolute ? 'absolute' : undefined,
        top: 0,
        width: '100%',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: alpha(theme.palette.common.black, 0.7),
          borderRadius: 1,
          color: theme.palette.common.white,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: 3,
          pb: 2,
        }}
      >
        <CircularProgress color="primary" />
        <Typography sx={{ mt: 2 }} variant="caption">
          {t('Loading...')}
        </Typography>
      </Box>
    </Backdrop>
  );
};
