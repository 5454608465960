import {
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BuildingHourPicker } from './building-hour-picker';

import { palette } from 'design-system';
import { TimePeriodPicker } from 'design-system/components';
import {
  BuildingHourId,
  ReportDateType,
  TimePeriodId,
} from 'features/reports/utils';

interface ReportConfigurationStep1 {
  name?: string;
  setName: (value: string) => void;
  timePeriod: TimePeriodId;
  setTimePeriod: (value: TimePeriodId) => void;
  dateType: ReportDateType;
  setDateType: (value: ReportDateType) => void;
  buildingHour: BuildingHourId;
  setBuildingHour: (value: BuildingHourId) => void;
}
export const ReportConfigurationStep1 = ({
  name,
  setName,
  timePeriod,
  setTimePeriod,
  dateType,
  setDateType,
  buildingHour,
  setBuildingHour,
}: ReportConfigurationStep1) => {
  const { t } = useTranslation('reports');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack data-cy="report-config-step-1" direction="column" spacing={3}>
      <Stack direction="column" spacing={2}>
        <Typography variant="subtitle1">{t('Name your report')}</Typography>
        <FormControl fullWidth>
          <InputLabel htmlFor="name">{t('Name')}</InputLabel>
          <OutlinedInput
            data-cy="name"
            id="name"
            label={t('Name')}
            autoFocus
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            inputProps={{ 'data-1p-ignore': true }}
          />
        </FormControl>
      </Stack>

      <Stack direction={isMobile ? 'column' : 'row'} spacing={3}>
        <Stack
          direction="column"
          spacing={2}
          sx={isMobile ? {} : { width: '50%' }}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={isMobile ? {} : { maxWidth: 'min-content' }}
          >
            <Stack sx={isMobile ? {} : { minWidth: 'max-content' }}>
              <TimePeriodPicker value={timePeriod} onChange={setTimePeriod} />
            </Stack>
            <Stack direction="column" spacing={1}>
              <RadioGroup
                row
                aria-labelledby="date-type-radio-buttons-group-\label"
                name="date-type-radio-buttons-group"
                data-cy="radio-group-date-type"
                value={dateType}
                onChange={(e) =>
                  setDateType(e.currentTarget.value as ReportDateType)
                }
              >
                <FormControlLabel
                  value="rolling"
                  control={<Radio />}
                  label={t('Rolling date')}
                />
                <FormControlLabel
                  value="fixed"
                  control={<Radio />}
                  label={t('Fixed date')}
                />
              </RadioGroup>
              <Typography
                data-cy="date-type-description"
                variant="body3"
                color={palette.palette.text.secondary}
              >
                {t(
                  'Rolling dates will continue to update to show the time period, fixed dates will only show data between the start and end dates'
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="column" spacing={2}>
          <Typography variant="subtitle1">{t('Filter hours')}</Typography>
          <BuildingHourPicker value={buildingHour} onChange={setBuildingHour} />
        </Stack>
      </Stack>
    </Stack>
  );
};
