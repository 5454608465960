import { SWRInfiniteResponse } from 'swr/infinite';

import { CursorResponse } from './types';
import {
  getCurrentOrganizationId,
  useGetCoreAPI,
  useGetCoreAPIInfinite,
} from './utils';

import { TRIGGER_TYPES } from 'features/task-management/constants';
import { SortOption, TaskStatus } from 'features/task-management/types';

export interface FloorPlanLocation {
  building_id?: number;
  building_name?: string;
  floor_id?: number;
  floor_name?: string;
  space_id?: number | null;
  space_name?: string | null;
}

export interface AlertSensorDetails {
  device_name: string;
  floorplan_location: FloorPlanLocation | null;
  location: string[] | null;
  profile_id: string;
  profile_name: string | null;
  profile_uuid?: string;
  sensor_state_tooltip: string;
  sensor_state: string;
}

export interface TaskComment {
  id: string;
  task_id: string;
  created_at: string;
  author_id: number;
  author_name: string | null;
  message: string;
}

interface TaskLocation {
  building_id: string | null;
  building_name: string | null;
  floor_id: string | null;
  floor_name: string | null;
  space_id: string | null;
  space_name: string | null;
  id: string;
}

export interface Task {
  id: string;
  title: string;
  status: TaskStatus;
  alert_id: string | null;
  alert_configuration_application_id: string | null;
  alert_configuration_id: string | null;
  folder_id: number | null;
  assignees_user_ids: number[];
  assignees_custom_emails: string[];
  location: TaskLocation | null;
  created_at: string;
  updated_at: string | null;
  status_updated_at: string | null;
  status_updated_by: string | null;
  status_updated_by_email: string | null;
  status_updated_by_name: string | null;
  status_done_at: string | null;
  organization_id: number;
  sensors: AlertSensorDetails[];
  corrigo_workorder: { number: string } | null;
  overdue_delta: string | null;
  comments: TaskComment[] | null;
  total_comments_count: number;
  job_card_id: null;
  trigger_id: null;
  trigger_type: null;
  integration_type: string | null;
  due_date: string | null;
  due_date_delta: string | null;
  autocomplete: boolean;
}

interface TasksQueryParams {
  cursor?: string;
  location?: string;
  ordering_field: SortOption;
  status: TaskStatus;
}

const getTasksEndpoint = () => {
  const organizationId = getCurrentOrganizationId();

  return `org/${organizationId}/tasks`;
};

interface InfiniteTaskListResponse
  extends Omit<SWRInfiniteResponse, 'data' | 'setSize' | 'size'> {
  hasMoreData: boolean;
  onFetchMore: () => void;
  tasks: Task[];
}

interface TaskCountResponse {
  count: number;
}

interface TaskCountQueryParams {
  completed_from_date?: string;
  completed_to_date?: string;
  location?: string;
  overdue?: boolean;
  status: TaskStatus;
}

export const useInfiniteTaskList = (
  params: TasksQueryParams
): InfiniteTaskListResponse => {
  const updatedParams = {
    ...{ cursor: params.cursor ?? undefined },
    ...{ location: params.location || undefined },
    ordering_field: params.ordering_field,
    status: params.status,
    trigger_types: TRIGGER_TYPES.SMART_CLEANING_PRO,
  };

  const { data, ...rest } = useGetCoreAPIInfinite<
    CursorResponse<Task>,
    TasksQueryParams
  >(getTasksEndpoint(), updatedParams);

  const tasks = (data ?? []).flatMap((page) => page.results);

  return {
    ...rest,
    tasks,
  };
};

export const useTaskCount = (params: TaskCountQueryParams) =>
  useGetCoreAPI<TaskCountResponse>(`${getTasksEndpoint()}/count`, {
    ...{ completed_from_date: params.completed_from_date ?? undefined },
    ...{ completed_to_date: params.completed_to_date ?? undefined },
    ...{ location: params.location || undefined },
    overdue: params.overdue,
    status: params.status,
    trigger_types: TRIGGER_TYPES.SMART_CLEANING_PRO,
  });
