import { addDays, addMilliseconds, startOfDay } from 'date-fns';

import { registerTranslationKey } from 'packages/i18n';

export type TimePeriodId = '24h' | '7d' | '30d' | '90d' | 'custom';

export type TimePeriodValue = {
  id: TimePeriodId;
  label: string;
  value?: number;
};

export const TimePeriods: TimePeriodValue[] = [
  {
    id: '24h',
    label: registerTranslationKey('1 D', { ns: 'reports' }),
    value: 1,
  },
  {
    id: '7d',
    label: registerTranslationKey('7 D', { ns: 'reports' }),
    value: 7,
  },
  {
    id: '30d',
    label: registerTranslationKey('30 D', { ns: 'reports' }),
    value: 30,
  },
  {
    id: '90d',
    label: registerTranslationKey('90 D', { ns: 'reports' }),
    value: 90,
  },
  {
    id: 'custom',
    label: registerTranslationKey('Custom', { ns: 'reports' }),
    value: undefined,
  },
];

export const getEffectiveTimePeriod = (reference: Date, duration: number) => {
  const date_to = addMilliseconds(startOfDay(reference), -1);
  const date_from = addDays(startOfDay(reference), -duration);

  return { date_to, date_from };
};
