import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import Carousel, {
  ButtonGroupProps,
  CarouselInternalState,
} from 'react-multi-carousel';

import { ReportCard } from './report-card';

import { ReportTypeId, ReportTypes } from 'features/reports/utils';

const CarouselPagination: React.FC = ({
  next,
  previous,
  carouselState,
  goToSlide = () => {},
}: ButtonGroupProps & { carouselState?: CarouselInternalState }) => {
  const theme = useTheme();
  const { totalItems, slidesToShow, currentSlide } = carouselState!;
  const totalPages = Math.ceil(totalItems / slidesToShow);
  const currentPage = Math.ceil(currentSlide / slidesToShow);
  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        bottom: 0,
        width: '100%',
      }}
    >
      <Button
        color="inherit"
        disabled={currentSlide === 0}
        onClick={previous}
        data-cy="report-card-carousel-previous"
      >
        <ChevronLeftIcon />
      </Button>
      {[...Array(totalPages)].map((_, index) => (
        <Button
          data-cy={`report-card-pagination-${index}`}
          key={index}
          color="inherit"
          sx={{
            background:
              currentPage === index ? theme.palette.action.selected : 'inherit',
          }}
          onClick={() => {
            // passing `onClick={() => goToSlide(index * slidesToShow)}` could end up on a partially empty page
            // e.g. if we have 5 items and 3 slides to show, we'd end up on page 3 with only 1 items
            // the below aligns the behaviour on `next()` which doesn't have this problem
            const slideIndex = index * slidesToShow;
            const isLastPage = index === totalPages - 1;
            const lastSlideIndex = isLastPage
              ? totalItems - slidesToShow
              : slideIndex;
            goToSlide(Math.min(slideIndex, lastSlideIndex));
          }}
        >
          {index + 1}
        </Button>
      ))}
      <Button
        color="inherit"
        disabled={currentSlide === totalPages}
        onClick={next}
        data-cy="report-card-carousel-next"
      >
        <ChevronRightIcon />
      </Button>
    </div>
  );
};

const getMobileReportTypes = () => {
  const arr = [...ReportTypes];
  const custom = arr.find((r) => r.id === 'custom');

  // Check if the element is found
  if (custom) {
    // Find the index of the element
    const index = arr.indexOf(custom);
    // Remove the element from its current position
    arr.splice(index, 1);
    // Add the element to the beginning of the array
    arr.unshift(custom);
  }

  return arr;
};

interface ReportCardCarousel {
  onCardClick: (reportType: ReportTypeId) => void;
}
export const ReportCardCarousel = ({ onCardClick }: ReportCardCarousel) => {
  const theme = useTheme();
  const isResponsive = useMediaQuery(theme.breakpoints.down('lg'));
  const MobileReportTypes = useMemo(() => getMobileReportTypes(), []);

  return !isResponsive ? (
    // on desktop we don't need pagination or any fancy behavior
    <Stack direction="row" spacing={2}>
      {ReportTypes.map((reportType) => (
        <ReportCard
          key={reportType.id}
          onClick={() => onCardClick(reportType.id)}
          chartType={reportType.chartType}
          title={reportType.title}
          description={reportType.description}
        />
      ))}
    </Stack>
  ) : (
    <Box
      sx={{
        '& .carousel-container': {
          overflow: 'hidden',
          position: 'relative',
          // to make room for the pagination
          paddingBottom: `calc(36px + ${theme.spacing(2)})`,
        },
        '& .carousel-item': {
          // to make room for the border (twice 2px - top+bottom)
          paddingBottom: '4px',
          '& .clickable': {
            // to make room for the border (twice 2px - left+right)
            width: `calc(100% - ${theme.spacing(2)} - 4px)`,
            height: '100%',
          },
        },
      }}
    >
      <Carousel
        arrows={false}
        customButtonGroup={<CarouselPagination />}
        containerClass="carousel-container"
        itemClass="carousel-item"
        responsive={{
          large: {
            breakpoint: {
              max: Number.MAX_SAFE_INTEGER,
              min: theme.breakpoints.values.lg,
            },
            items: 5,
          },
          tablet: {
            breakpoint: {
              max: theme.breakpoints.values.lg,
              min: theme.breakpoints.values.md,
            },
            items: 3,
            slidesToSlide: 3,
          },
          mobile: {
            breakpoint: { max: theme.breakpoints.values.md, min: 0 },
            items: 2,
            slidesToSlide: 2,
          },
        }}
      >
        {MobileReportTypes.map((reportType) => (
          <ReportCard
            key={reportType.id}
            onClick={() => onCardClick(reportType.id)}
            chartType={reportType.chartType}
            title={reportType.title}
            description={reportType.description}
          />
        ))}
      </Carousel>
    </Box>
  );
};
