import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUserEditContext } from '../context';

import { LocationSelector } from 'design-system/components';

export const UserConfigurationStep2 = () => {
  const { t } = useTranslation('tasks');
  const { locationIds, setLocationIds } = useUserEditContext();

  return (
    <Stack data-cy="user-config-step-2">
      <Typography variant="subtitle1">
        {t('In which locations does the user operate?')}
      </Typography>
      <Typography sx={{ mb: 2 }}>
        {t('Which locations does the user manage or report on?')}
      </Typography>
      <LocationSelector value={locationIds} onChange={setLocationIds} />
    </Stack>
  );
};
