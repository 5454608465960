import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ReportLocationsSummaryProps {
  buildingCount?: number;
  floorCount?: number;
  spaceCount?: number;
}
export const ReportLocationsSummary = ({
  buildingCount = 0,
  floorCount = 0,
  spaceCount = 0,
}: ReportLocationsSummaryProps) => {
  const { t } = useTranslation('reports');

  const buildingSummary = t('{{count}} building(s)', {
    count: buildingCount,
  });
  const floorSummary = t('{{count}} floor(s)', { count: floorCount });
  const spaceSummary = t('{{count}} space(s)', { count: spaceCount });

  const summary: string[] = [];
  if (buildingCount) {
    summary.push(buildingSummary);
  }
  if (floorCount) {
    summary.push(floorSummary);
  }
  if (spaceCount) {
    summary.push(spaceSummary);
  }

  return (
    <Typography
      variant="body2"
      lineHeight="inherit"
      data-cy="report-locations-summary"
      noWrap
    >
      {!summary.length ? t('All locations') : summary.join(', ')}
    </Typography>
  );
};
