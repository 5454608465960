import { registerTranslationKey } from 'packages/i18n';

export const SORT_OPTIONS = {
  // Open tasks
  OLDEST_CREATED: 'created_at',
  RECENTLY_CREATED: '-created_at',
  // Completed tasks
  OLDEST_COMPLETED: 'status_done_at',
  RECENTLY_COMPLETED: '-status_done_at',
} as const;

export const SORT_OPTIONS_LABELS = {
  [SORT_OPTIONS.OLDEST_COMPLETED]: registerTranslationKey('Oldest first', {
    ns: 'tasks',
  }),
  [SORT_OPTIONS.OLDEST_CREATED]: registerTranslationKey('Oldest first', {
    ns: 'tasks',
  }),
  [SORT_OPTIONS.RECENTLY_CREATED]: registerTranslationKey('Most recent first', {
    ns: 'tasks',
  }),
  [SORT_OPTIONS.RECENTLY_COMPLETED]: registerTranslationKey(
    'Most recent first',
    { ns: 'tasks' }
  ),
};

export const TASK_STATUSES = {
  COMPLETE: 'DONE',
  OPEN: 'TO_DO',
} as const;

export const TRIGGER_TYPES = {
  SMART_CLEANING_PRO: 'smart_cleaning_pro',
} as const;

export const JOB_ROLES = {
  INSTALLER_OR_IT_SUPPORT: 'installer_or_it_support',
  RECEPTION_OR_SECURITY: 'reception_or_security',
  JANITOR: 'janitor',
  ENGINEER: 'engineer',
  CLEANING_SUPERVISOR_OR_MANAGER: 'cleaning_supervisor_or_manager',
  DATA_ANALYST: 'data_analyst',
  SUPERVISOR_OR_TEAM_LEADER: 'supervisor_or_team_leader',
  SERVICE_MANAGER: 'service_manager',
  OPERATIONS_OR_ACCOUNT_DIRECTOR: 'operations_or_account_director',
  ESG_MANAGER: 'esg_manager',
  FACILITIES_MANAGER: 'facilities_manager',
  SENIOR_FACILITIES_MANAGER: 'senior_facilities_manager',
  FACILITIES_DIRECTOR: 'facilities_director',
  INFOGRID_EMPLOYEE_OR_SUPPORT: 'infogrid_employee_or_support',
  OTHER: 'other',
} as const;

export const JOB_ROLE_LABELS: { [key: string]: string } = {
  [JOB_ROLES.INSTALLER_OR_IT_SUPPORT]: registerTranslationKey(
    'Installer/IT Support',
    {
      ns: 'tasks',
    }
  ),
  [JOB_ROLES.RECEPTION_OR_SECURITY]: registerTranslationKey(
    'Reception/Security/Office Manager',
    {
      ns: 'tasks',
    }
  ),
  [JOB_ROLES.JANITOR]: registerTranslationKey('Janitor/Cleaner/Porter', {
    ns: 'tasks',
  }),
  [JOB_ROLES.ENGINEER]: registerTranslationKey('Engineer/Technician', {
    ns: 'tasks',
  }),
  [JOB_ROLES.CLEANING_SUPERVISOR_OR_MANAGER]: registerTranslationKey(
    'Cleaning Supervisor',
    { ns: 'tasks' }
  ),
  [JOB_ROLES.DATA_ANALYST]: registerTranslationKey('Data Analyst', {
    ns: 'tasks',
  }),
  [JOB_ROLES.SUPERVISOR_OR_TEAM_LEADER]: registerTranslationKey(
    'Supervisor/Team Leader',
    {
      ns: 'tasks',
    }
  ),
  [JOB_ROLES.SERVICE_MANAGER]: registerTranslationKey('Service Manager', {
    ns: 'tasks',
  }),
  [JOB_ROLES.OPERATIONS_OR_ACCOUNT_DIRECTOR]: registerTranslationKey(
    'Operations/Account Director',
    {
      ns: 'tasks',
    }
  ),
  [JOB_ROLES.ESG_MANAGER]: registerTranslationKey(
    'Energy/Sustainability/ESG Manager',
    {
      ns: 'tasks',
    }
  ),
  [JOB_ROLES.FACILITIES_MANAGER]: registerTranslationKey('Facilities Manager', {
    ns: 'tasks',
  }),
  [JOB_ROLES.SENIOR_FACILITIES_MANAGER]: registerTranslationKey(
    'Senior Facilities Manager',
    { ns: 'tasks' }
  ),
  [JOB_ROLES.FACILITIES_DIRECTOR]: registerTranslationKey(
    'Facilities Director/Director of Estates',
    {
      ns: 'tasks',
    }
  ),
  [JOB_ROLES.INFOGRID_EMPLOYEE_OR_SUPPORT]: registerTranslationKey(
    'Infogrid Employee/Support',
    {
      ns: 'tasks',
    }
  ),
  [JOB_ROLES.OTHER]: registerTranslationKey('Other', { ns: 'tasks' }),
} as const;
