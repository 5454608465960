import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DropDownValue {
  id: string;
  label: string;
  disabledReason?: string;
}
interface DropdownInputProps {
  id: string;
  label: string;
  allLabel?: string;
  value: string[];
  // This callback should never be inlined - it should either come from `[_, callback] = useState()` or `useCallback()` to avoid rendering loop.
  onChange: (newValue: string[]) => void;
  isLoading?: boolean;
  values?: DropDownValue[];
  supportsAll?: boolean;
  menuPosition?: 'left' | 'right';
  maxDisplayedItems?: number;
  sx?: SxProps;
}
export const DropdownInput = ({
  id,
  label,
  allLabel,
  value,
  onChange,
  isLoading,
  values,
  supportsAll = true,
  menuPosition = 'left',
  maxDisplayedItems = 2,
  sx,
}: DropdownInputProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const availableValues = values?.map((v) => v.id) ?? [];

  /**
   * Default selection state is:
   * - `value` if not empty
   * - all selected if `supportsAll` is true
   * - nothing selected otherwise
   */
  const valueLength = value.length;
  const getDefaultValue = useCallback(
    () => (!valueLength ? (supportsAll ? availableValues : []) : value),
    /**
     * `availableValues` should be in the dependency array but since it is compared by reference `availableValues.length` will do instead.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [valueLength, availableValues.length, supportsAll]
  );
  const [internalValues, setInternalValues] = useState(getDefaultValue());

  // all is shown as selected if all items are selected, but it's not a value in itself
  const isAllSelected = internalValues.length === availableValues.length;
  const toggleAll = () => {
    if (isAllSelected) {
      // if all was virtually selected, upon selection we clear the list
      setInternalValues([]);
    } else {
      // otherwise we select all available values
      setInternalValues(availableValues);
    }
  };

  const toggleValue = (id: string) => {
    if (internalValues.includes(id)) {
      // if the value was selected remove it from the list
      setInternalValues(internalValues.filter((v) => v !== id));
    } else {
      // otherwise add it
      setInternalValues([...internalValues, id]);
    }
  };

  // propagate the final state to the parent
  useEffect(() => {
    if (isAllSelected && supportsAll) {
      return onChange([]);
    }
    if (value.length !== internalValues.length) {
      onChange(internalValues);
    }
  }, [value.length, internalValues, isAllSelected, supportsAll, onChange]);

  // set internal state to default when loading is done
  useEffect(() => {
    if (!isLoading) {
      setInternalValues(getDefaultValue());
    }
  }, [isLoading, setInternalValues, getDefaultValue]);

  return (
    <FormControl sx={sx}>
      <InputLabel htmlFor={id} data-cy={`dropdown-label-${id}`} shrink={true}>
        {label}
      </InputLabel>
      <Select
        multiple
        notched={true}
        data-cy={`dropdown-${id}`}
        MenuProps={{
          // extend the theme defaults, which otherwise would be overriden
          ...theme.components?.MuiSelect?.defaultProps?.MenuProps,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: menuPosition,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: menuPosition,
          },
          // using available props, couldn't use data-cy here for some reason
          id: `menu-${id}`,
        }}
        labelId={`${id}-label`}
        id={id}
        label={label}
        value={value}
        // this is to display `allLabel`, even if the array is empty
        displayEmpty
        renderValue={() => {
          if (!supportsAll) {
            const selectedValues = values?.filter((value) =>
              internalValues.includes(value.id)
            );
            return (
              <Stack direction="row" spacing={1}>
                {selectedValues
                  ?.slice(0, maxDisplayedItems)
                  .map((value, index) => (
                    <Chip key={index} size="small" label={value.label} />
                  ))}
                {selectedValues &&
                  selectedValues.length > maxDisplayedItems && (
                    <Chip size="small" label="..." />
                  )}
              </Stack>
            );
          }
          if (isAllSelected || internalValues.length === 0) {
            return allLabel;
          }

          return t(`{{count}} selected`, {
            count: internalValues.length,
          });
        }}
        sx={{ minWidth: 200 }}
      >
        {isLoading ? (
          <MenuItem data-cy={`menu-${id}-loading`}>
            {/* TODO: review this behaviour with Design */}
            <LinearProgress />
          </MenuItem>
        ) : (
          [
            ...(supportsAll
              ? [
                  <MenuItem
                    key={`menu-${id}-all`}
                    data-cy={`menu-${id}-all`}
                    onClick={() => toggleAll()}
                    selected={isAllSelected}
                    divider={true}
                  >
                    <Checkbox
                      data-cy={`checkbox-${id}-all`}
                      checked={isAllSelected}
                    />
                    <ListItemText primary={allLabel} />
                  </MenuItem>,
                ]
              : []),
            values?.map((item) => {
              const isValueSelected =
                value.indexOf(item.id) > -1 || isAllSelected;
              const isItemDisabled = !!item.disabledReason;
              return (
                <Tooltip
                  key={item.id}
                  title={isItemDisabled ? item.disabledReason : ''}
                  placement="right"
                >
                  {/*
                   *span is required to allow the tooltip to be shown on the disabled menu items
                   *https://stackoverflow.com/questions/61115913/is-it-possible-to-render-a-tooltip-on-a-disabled-material-ui-button-within-a-but
                   */}
                  <span key={item.id} data-cy={`tooltip-span-${item.id}`}>
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      data-cy={`menu-${id}-${item.id}`}
                      onClick={() => toggleValue(item.id)}
                      disabled={isItemDisabled}
                    >
                      <Checkbox
                        data-cy={`checkbox-${id}-${item.id}`}
                        checked={isValueSelected}
                        disabled={isItemDisabled}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  </span>
                </Tooltip>
              );
            }),
          ]
        )}
      </Select>
    </FormControl>
  );
};
