import { Building } from 'api/hierarchy';
import { Task } from 'api/tasks';
import { TaskSummary } from 'features/task-management/types';

export const generateBuildingQueryString = (buildings?: Building[]): string => {
  return (buildings ?? []).map((b) => b.id.toString()).join(',');
};

type SortDirection = 'asc' | 'desc';
type SortField = 'created_at' | 'status_done_at';

export const groupTasksByDate = (
  tasks: Task[],
  sortField: SortField,
  direction: SortDirection
): Record<string, TaskSummary[]> => {
  if (tasks.length === 0) {
    return {};
  }

  const sortTasks = (a: TaskSummary, b: TaskSummary) => {
    const field = sortField === 'created_at' ? 'dateCreated' : 'dateCompleted';

    if (direction === 'asc') {
      return a[field]! < b[field]! ? -1 : 1;
    }

    return a[field]! < b[field]! ? 1 : -1;
  };

  const groupedTasks = tasks.reduce<Record<string, TaskSummary[]>>(
    (records, task) => {
      const dateString = task[sortField]?.substring(0, 10);

      // This would only be the case if a complete task has a status_done_at of null, which shouldn't happen
      if (!dateString) {
        return records;
      }

      if (!Object.keys(records).includes(dateString)) {
        records[dateString] = [];
      }

      records[dateString].push({
        buildingName: task.location?.building_name ?? null,
        floorName: task.location?.floor_name ?? null,
        id: task.id,
        dateCompleted: task.status_done_at,
        dateCreated: task.created_at,
        status: task.status,
        title: task.title,
      });

      return records;
    },
    {}
  );

  const sortedGroupedTasks = Object.entries(groupedTasks).reduce<
    Record<string, TaskSummary[]>
  >((records, [dateKey, tasks]) => {
    records[dateKey] = tasks.sort(sortTasks);

    return records;
  }, {});

  return sortedGroupedTasks;
};
