import { Box, useTheme } from '@mui/material';

import { CommonContent } from '../common-content';

import { PageContainer } from 'components';

export const AuthenticatedErrorBoundary = () => {
  const theme = useTheme();

  return (
    <PageContainer>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          textAlign: 'center',
        }}
      >
        <Box sx={{ pt: theme.spacing(20), zIndex: 2 }}>
          <CommonContent />
        </Box>
      </Box>
    </PageContainer>
  );
};
