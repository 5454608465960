import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  ThemeProvider,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import background from '../../../design-system/images/background2.svg';
import logos from '../../../design-system/images/ig-aq-logos.svg';

import { darkTheme } from 'design-system';

interface LoginContainerProps {
  children: React.ReactNode;
  back?: () => void;
}
export const LoginContainer = ({ children, back }: LoginContainerProps) => {
  const theme = useTheme();
  const { t } = useTranslation('login');

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          background: darkTheme.palette.background.default,
          width: '100vw',
          height: '100dvh',
          display: 'flex',
          flexDirection: 'row',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100dvh',
            background: `url(${background}) right bottom no-repeat`,
          },
          padding: theme.spacing(0, 2),
        }}
      >
        <Box margin="auto" zIndex={0}>
          {!!back && (
            <Grid item>
              <Button
                variant="text"
                color="secondary"
                onClick={back}
                startIcon={<ArrowBackIcon />}
              >
                {t('Back')}
              </Button>
            </Grid>
          )}
          <Grid
            item
            sx={{
              [theme.breakpoints.down('sm')]: {
                flex: 1,
              },
            }}
          >
            <Paper>
              <Stack
                spacing={3}
                padding={5}
                sx={{
                  width: 444,
                  [theme.breakpoints.down('sm')]: {
                    width: 'inherit',
                  },
                }}
              >
                {children}
              </Stack>
            </Paper>
            <Box
              sx={{
                justifyContent: 'center',
                display: 'flex',
                margin: theme.spacing(5, 0),
              }}
            >
              <img src={logos} alt={t('By Infogrid and Aquicore')} />
            </Box>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
