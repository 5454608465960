import { isToday, isYesterday } from 'date-fns';
import { TFunction } from 'i18next';

import { toLongDate } from 'design-system/formatting';

export const generateTitle = (date: Date, t: TFunction, language: string) => {
  if (isToday(date)) {
    return t('Today');
  }

  if (isYesterday(date)) {
    return t('Yesterday');
  }

  return toLongDate(date, language);
};
