import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { formatISO } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReportConfigurationSteps } from '../enums';

import { ReportConfigurationStep1 } from './report-configuration-step-1';
import { ReportConfigurationStep2 } from './report-configuration-step-2';

import { useCreateReport } from 'api/reports';
import { convertUuidToInt } from 'cube';
import { Modal } from 'design-system/components';
import {
  BuildingHourId,
  ReportDateType,
  TimePeriodId,
  TimePeriods,
  getEffectiveTimePeriod,
} from 'features/reports/utils';

const DefaultBuildingHourId = 'building_specific';
const DefaultReportDateType = 'rolling';
const DefaultTimePeriodId = '90d';
const DefaultBuildingIds: string[] = [];

interface ReportConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRevalidateReports: () => void;
}

export const ReportConfigurationModal = ({
  isOpen,
  onClose,
  onRevalidateReports,
}: ReportConfigurationModalProps) => {
  const { t } = useTranslation('reports');
  const navigate = useNavigate();

  const [customReportName, setCustomReportName] = useState<string>('');
  const [customReportTimePeriod, setCustomReportTimePeriod] =
    useState<TimePeriodId>(DefaultTimePeriodId);
  const [customReportDateType, setCustomReportDateType] =
    useState<ReportDateType>(DefaultReportDateType);
  const [customReportBuildingHour, setCustomReportBuildingHour] =
    useState<BuildingHourId>(DefaultBuildingHourId);
  const [buildingIds, setBuildingIds] = useState<string[]>(DefaultBuildingIds);
  const [isAllLocationsSelected, setIsAllLocationsSelected] =
    useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(
    ReportConfigurationSteps.DETAILS_STEP
  );

  const { trigger: createReport, isMutating: isCreatingReport } =
    useCreateReport(() => onRevalidateReports());

  const resetCustomReportFormStep = () => {
    setCustomReportName('');
    setCustomReportTimePeriod(DefaultTimePeriodId);
    setCustomReportDateType(DefaultReportDateType);
    setCustomReportBuildingHour(DefaultBuildingHourId);
    setBuildingIds(DefaultBuildingIds);
    setActiveStep(ReportConfigurationSteps.DETAILS_STEP);
  };

  const handleClose = () => {
    resetCustomReportFormStep();
    onClose();
  };

  const onNext = () => {
    setActiveStep(ReportConfigurationSteps.LOCATIONS_STEP);
  };

  const onBack = () => {
    setActiveStep(ReportConfigurationSteps.DETAILS_STEP);
  };

  const onCreateReport = async () => {
    const duration = TimePeriods.find(
      (p) => p.id === customReportTimePeriod
    )!.value;
    const period = getEffectiveTimePeriod(new Date(), duration ?? 0);
    const report = await createReport({
      name: customReportName,
      report_type: 'custom',
      duration,
      building_working_hours: customReportBuildingHour,
      rolling_date: customReportDateType === 'rolling',
      ...(customReportDateType === 'fixed' && {
        date_to: formatISO(period.date_to),
        date_from: formatISO(period.date_from),
      }),
      building_ids: isAllLocationsSelected
        ? []
        : buildingIds.map(convertUuidToInt),
      floor_ids: [],
      space_ids: [],
      countries: [],
    });

    if (report) {
      navigate(`/reports/${report.id}`);
    }

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t('Build new custom report')}
      actions={
        <>
          {activeStep === ReportConfigurationSteps.DETAILS_STEP && (
            <LoadingButton
              disabled={customReportName.trim() === ''}
              variant="contained"
              onClick={onNext}
              data-cy="create-report-next"
              loading={isCreatingReport}
            >
              {t('Next')}
            </LoadingButton>
          )}

          {activeStep === ReportConfigurationSteps.LOCATIONS_STEP && (
            <Stack direction="row" flex="1" justifyContent="space-between">
              <Button
                variant="text"
                onClick={onBack}
                data-cy="create-report-back"
              >
                {t('Back')}
              </Button>
              <LoadingButton
                disabled={buildingIds.length === 0}
                variant="contained"
                onClick={onCreateReport}
                data-cy="create-report-build"
                loading={isCreatingReport}
              >
                {t('Build report')}
              </LoadingButton>
            </Stack>
          )}
        </>
      }
      maxWidth="md"
      fullWidth
    >
      {activeStep === ReportConfigurationSteps.DETAILS_STEP && (
        <ReportConfigurationStep1
          name={customReportName}
          setName={setCustomReportName}
          timePeriod={customReportTimePeriod}
          setTimePeriod={setCustomReportTimePeriod}
          dateType={customReportDateType}
          setDateType={setCustomReportDateType}
          buildingHour={customReportBuildingHour}
          setBuildingHour={setCustomReportBuildingHour}
        />
      )}

      {activeStep === ReportConfigurationSteps.LOCATIONS_STEP && (
        <ReportConfigurationStep2
          buildingIds={buildingIds}
          setBuildingIds={(locations, isAllSelected) => {
            setIsAllLocationsSelected(isAllSelected);
            setBuildingIds(locations);
          }}
        />
      )}
    </Modal>
  );
};
