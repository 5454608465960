import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { availableDimensions } from 'features/reports/utils';

interface MeasuresPicker {
  value: string;
  onChange: (newValue: string) => void;
}
export const DimensionPicker = ({ value, onChange }: MeasuresPicker) => {
  const { t } = useTranslation('reports');
  const id = 'dimension';
  return (
    <FormControl>
      <InputLabel htmlFor={id} data-cy={`dropdown-label-${id}`} shrink={true}>
        {t('Dimension')}
      </InputLabel>
      <Select
        notched={true}
        data-cy={`dropdown-${id}`}
        labelId={`${id}-label`}
        id={id}
        label={t('Dimension')}
        value={value}
        onChange={(event) => onChange(event.target.value as string)}
        sx={{ minWidth: 200 }}
      >
        {availableDimensions?.map((item) => {
          return (
            <MenuItem
              key={item.id}
              value={item.id}
              data-cy={`menu-${id}-${item.id}`}
              onClick={(e) => {
                onChange(item.id === value ? '' : item.id);
                e.stopPropagation();
              }}
            >
              <ListItemText primary={item.label} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
