import { Grid, Paper, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Building } from 'api/hierarchy';
import { useCurrentOrganization, useUser } from 'api/user';
import { PageContainer } from 'components/page-container';
import { convertIntToUuid } from 'cube';
import {
  BuildingPicker,
  CircleLoader,
  TimePeriodPicker,
} from 'design-system/components';
import { SummaryCard } from 'features/home/summary-card/summary-card';
import { SummarySection } from 'features/home/summary-section';
import { useSummaryStatistics } from 'features/home/utils/hooks';
import { ConfigurableChart } from 'features/reports';
import {
  BuildingHourId,
  DEFAULT_SCHEMA,
  TimeGranularity,
  TimePeriodId,
  TimePeriods,
  getEffectiveTimePeriod,
  timeDimension,
} from 'features/reports/utils';

const getGranularity = (timePeriodId: TimePeriodId): TimeGranularity => {
  if (timePeriodId === '24h') {
    return 'hour';
  }

  if (timePeriodId === '7d') {
    return 'day';
  }

  return 'week';
};

export const HomePage = () => {
  const { t } = useTranslation('home');

  const { data: user } = useUser();
  const { isLoading: isLoadingOrg, data: org } = useCurrentOrganization();

  const [timePeriodId, setTimePeriodId] = useState<TimePeriodId>('24h');
  const [selectedBuildings, setSelectedBuildings] = useState<Building[]>();

  const selectedBuildingIds = selectedBuildings?.map((b) =>
    convertIntToUuid(Number(b.id))
  );

  const daysInRange =
    TimePeriods.find((tp) => tp.id === timePeriodId)?.value ?? 90;

  // Memoization necessary to stop the date being regenerated on the many
  // re-renders of the building dropdown component
  const timeRange = useMemo(() => {
    const { date_from: timeFrom, date_to: timeTo } = getEffectiveTimePeriod(
      new Date(),
      daysInRange
    );
    return { timeFrom, timeTo };
  }, [daysInRange]);

  const filters = {
    buildings: selectedBuildingIds,
    timeRange,
    workingHours: 'all' as BuildingHourId,
  };

  const granularity = getGranularity(timePeriodId);

  const statisticsMeasures = [
    DEFAULT_SCHEMA.MEASURES.TASK_COMPLETION_RATE.name,
    DEFAULT_SCHEMA.MEASURES.RESPONSE_TIME.name,
    DEFAULT_SCHEMA.MEASURES.TASKS_COMPLETED.name,
  ];

  const {
    isLoading: isLoadingStatistics,
    currentStatistics,
    previousStatistics,
  } = useSummaryStatistics({
    duration: daysInRange,
    locations: selectedBuildingIds,
    measures: statisticsMeasures,
  });

  return (
    <PageContainer
      headerProps={{
        primary: t('Welcome back') + `, ${user?.name}`,
        secondary:
          !isLoadingOrg &&
          t("Here's a summary of performance at") + ` ${org?.name}`,
      }}
    >
      <Stack spacing={3}>
        {/* Remove until late tasks are a thing again */}
        {/* <LateTasksBanner /> */}

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{
            alignItems: { sm: 'center' },
            display: 'flex',
            justifyContent: { sm: 'space-between' },
            gap: 3,
          }}
        >
          <TimePeriodPicker
            buttonsOnly
            disableCustom
            value={timePeriodId}
            onChange={setTimePeriodId}
          />
          <BuildingPicker
            value={selectedBuildings}
            onChange={setSelectedBuildings}
          />
        </Stack>
        <Grid container>
          {isLoadingStatistics && (
            <Paper sx={{ p: 5, width: '100%' }}>
              <CircleLoader sx={{ padding: 10, width: '100%' }} />
            </Paper>
          )}

          {!isLoadingStatistics && (
            <SummarySection>
              <SummaryCard
                currentValue={
                  currentStatistics[
                    DEFAULT_SCHEMA.MEASURES.TASK_COMPLETION_RATE.name
                  ] ?? 0
                }
                previousValue={
                  previousStatistics[
                    DEFAULT_SCHEMA.MEASURES.TASK_COMPLETION_RATE.name
                  ]
                }
                data-cy="completion-rate"
                title={t('Completion rate')}
                type="percent"
              />
              <SummaryCard
                currentValue={
                  currentStatistics[
                    DEFAULT_SCHEMA.MEASURES.RESPONSE_TIME.name
                  ] ?? 0
                }
                previousValue={
                  previousStatistics[DEFAULT_SCHEMA.MEASURES.RESPONSE_TIME.name]
                }
                data-cy="response-time"
                title={t('Response time')}
                type="duration"
              />
              <SummaryCard
                currentValue={
                  currentStatistics[
                    DEFAULT_SCHEMA.MEASURES.TASKS_COMPLETED.name
                  ] ?? 0
                }
                previousValue={
                  previousStatistics[
                    DEFAULT_SCHEMA.MEASURES.TASKS_COMPLETED.name
                  ]
                }
                data-cy="tasks-completed"
                title={t('Total tasks completed')}
                type="number"
              />
            </SummarySection>
          )}
        </Grid>

        <div>
          <Grid columns={2} container item spacing={3}>
            <Grid item xs={2} md={1}>
              <ConfigurableChart
                canvasHeight={400}
                filters={filters}
                id="space-usage"
                requestKey="space-usage"
                isReadOnly
                options={{
                  dimension: timeDimension.name,
                  granularity,
                  measures: [
                    DEFAULT_SCHEMA.MEASURES.TASK_COMPLETION_RATE.name,
                    DEFAULT_SCHEMA.MEASURES.SPACE_UTILISATION.name,
                  ],
                  type: 'bar',
                }}
                title={t('How did space usage impact cleaning?')}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ConfigurableChart
                canvasHeight={400}
                filters={filters}
                id="response-time"
                requestKey="response-time"
                isReadOnly
                options={{
                  dimension: timeDimension.name,
                  granularity,
                  measures: [DEFAULT_SCHEMA.MEASURES.RESPONSE_TIME.name],
                  type: 'line',
                }}
                title={t('How quickly did teams respond to tasks?')}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ConfigurableChart
                canvasHeight={400}
                filters={{ ...filters, workingHours: 'building_specific' }}
                id="busiest-periods"
                requestKey="busiest-periods"
                isReadOnly
                options={{
                  dimension:
                    DEFAULT_SCHEMA.DIMENSIONS.TIMESTAMP_DAY_OF_WEEK.name,
                  segment: DEFAULT_SCHEMA.DIMENSIONS.TIMESTAMP_HOUR_OF_DAY.name,
                  measures: [DEFAULT_SCHEMA.MEASURES.SPACE_UTILISATION.name],
                  type: 'heatmap',
                }}
                title={t('When were the busiest periods?')}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <ConfigurableChart
                canvasHeight={400}
                filters={filters}
                id="space-types"
                requestKey="space-types"
                isReadOnly
                options={{
                  dimension: DEFAULT_SCHEMA.DIMENSIONS.SPACE_TYPE.name,
                  measures: [DEFAULT_SCHEMA.MEASURES.TIME_SPENT_CLEANING.name],
                  type: 'bar',
                }}
                title={t('Which space types were cleaned most?')}
              />
            </Grid>
          </Grid>
        </div>
      </Stack>
    </PageContainer>
  );
};
