import { LoadingButton } from '@mui/lab';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserEditProvider } from './context.provider';
import { UserConfigurationStep1 } from './user-configuration-step-1';
import { UserConfigurationStep2 } from './user-configuration-step-2';
import { UserConfigurationStep3 } from './user-configuration-step-3';
import { UserConfigurationStep4 } from './user-configuration-step-4';

import {
  useUserDetail,
  UserLocationAssociation,
  useUpdateUserLocations,
} from 'api/user';
import { convertUuidToInt } from 'cube';
import { Modal, CircleLoader } from 'design-system/components';

const stepComponents = [
  UserConfigurationStep1,
  UserConfigurationStep2,
  UserConfigurationStep3,
  UserConfigurationStep4,
];

interface UserConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  editingUserRow: UserLocationAssociation;
  onSave: () => void;
}

export const UserConfigurationEditModal = ({
  isOpen,
  onClose,
  onSave,
  editingUserRow,
}: UserConfigurationModalProps) => {
  const { t } = useTranslation('tasks');

  const { isLoading, data: userDetail } = useUserDetail(editingUserRow.id);
  const { trigger: updateUserLocations, isMutating: isSaving } =
    useUpdateUserLocations(editingUserRow.id);

  const [activeStep, setActiveStep] = useState(0);

  const Step = useMemo(() => stepComponents[activeStep], [activeStep]);

  const initialLocationIds = Object.values(
    editingUserRow.locations.buildings
  ).map((building) => building.id);
  const [locationIds, setLocationIds] = useState<string[]>(initialLocationIds);

  const onUpdateUser = async () => {
    await updateUserLocations({
      location_ids: locationIds.map(convertUuidToInt),
    });

    onSave();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('Smart Cleaning Pro user management')}
      maxWidth="md"
      fullWidth
      minHeight={680}
      actions={
        <>
          {activeStep > 0 && (
            <LoadingButton
              variant="text"
              onClick={() => setActiveStep(activeStep - 1)}
              data-cy="edit-user-back"
              loading={isLoading}
              disabled={isSaving}
            >
              {t('Back')}
            </LoadingButton>
          )}
          {activeStep < stepComponents.length - 1 && (
            <LoadingButton
              variant="contained"
              onClick={() => setActiveStep(activeStep + 1)}
              data-cy="edit-user-next"
              loading={isLoading}
              disabled={isSaving}
            >
              {t('Next')}
            </LoadingButton>
          )}
          {activeStep == stepComponents.length - 1 && (
            <LoadingButton
              variant="contained"
              onClick={onUpdateUser}
              data-cy="edit-user-save"
              loading={isLoading}
              disabled={isSaving}
            >
              {t('Save')}
            </LoadingButton>
          )}
        </>
      }
    >
      {isLoading && <CircleLoader sx={{ padding: 20 }} />}
      {!isLoading && userDetail && (
        <UserEditProvider value={{ userDetail, locationIds, setLocationIds }}>
          <Step />
        </UserEditProvider>
      )}
    </Modal>
  );
};
