import { MenuItem, MenuItemProps } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfigurableChartDuplicateMenuItemProps extends MenuItemProps {
  onDuplicate: () => void;
}

export const ConfigurableChartDuplicateMenuItem = forwardRef<
  HTMLLIElement,
  ConfigurableChartDuplicateMenuItemProps
>(({ onDuplicate, ...props }, ref) => {
  const { t } = useTranslation('reports');
  return (
    <MenuItem
      ref={ref}
      data-cy="chart-menu-duplicate"
      {...props}
      onClick={onDuplicate}
    >
      {t('Duplicate')}
    </MenuItem>
  );
});

ConfigurableChartDuplicateMenuItem.displayName =
  'ConfigurableChartDuplicateMenuItem';
