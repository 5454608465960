import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SORT_OPTIONS_LABELS } from '../constants';
import { SortOption } from '../types';

interface SortByFilterProps {
  onChange: (value: SortOption) => void;
  options: SortOption[];
  value: SortOption;
}

export const SortByFilter = ({
  onChange,
  options,
  value,
}: SortByFilterProps) => {
  const { t } = useTranslation('tasks');

  const label = t('Sort by');

  const handleChange = useCallback(
    (event: SelectChangeEvent<SortOption>) => {
      onChange(event.target.value as SortOption);
    },
    [onChange]
  );

  const selectedOptionEntries = Object.entries(SORT_OPTIONS_LABELS).filter(
    ([key]) => options.includes(key as SortOption)
  );

  return (
    <FormControl data-cy="sort-by-filter" sx={{ width: 220 }}>
      <InputLabel id="sort-by-label">{label}</InputLabel>
      <Select
        labelId="sort-by-label"
        id="sort-by"
        value={value}
        label={label}
        onChange={handleChange}
      >
        {selectedOptionEntries.map(([value, label]) => (
          <MenuItem key={value} data-cy={`sort-by-${value}`} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
