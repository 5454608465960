import { Box, Stack, useTheme } from '@mui/material';
import noop from 'lodash/noop';
import { useCallback } from 'react';

import { ConfigurableChartOptions } from '..';

import { ChartTypePicker } from './chart-type-picker';
import { DimensionPicker } from './dimension-picker';
import { MeasuresPicker } from './measures-picker';
import { TimeGranularityPicker } from './time-granularity-picker';

import { ChartType } from 'features/reports';
import { TimeGranularity, timeDimension } from 'features/reports/utils';

interface OptionsDrawerProps {
  isOpen?: boolean;
  options: ConfigurableChartOptions;
  onOptionsChange?: (options: ConfigurableChartOptions) => void;
}

export const OptionsDrawer = ({
  isOpen,
  options,
  onOptionsChange = noop,
}: OptionsDrawerProps) => {
  const theme = useTheme();

  const onMeasuresChange = useCallback(
    (measures: string[]) => {
      onOptionsChange({
        /**
         * We would have like to use a spread here but it's not possible
         * to reference a partial object in the dependencies array.
         */
        dimension: options.dimension,
        type: options.type,
        granularity: options.granularity,
        previousPeriod: options.previousPeriod,
        measures,
      });
    },
    [
      onOptionsChange,
      options.dimension,
      options.type,
      options.granularity,
      options.previousPeriod,
    ]
  );
  const onDimensionChange = (dimension: string) => {
    if (dimension === timeDimension.name) {
      onOptionsChange({ ...options, dimension, granularity: 'day' });
    } else {
      onOptionsChange({ ...options, dimension, granularity: undefined });
    }
  };
  const onTypeChange = (type: ChartType) => {
    onOptionsChange({ ...options, type });
  };
  const onGranularityChange = (granularity?: TimeGranularity) => {
    onOptionsChange({ ...options, granularity });
  };

  return (
    <Box
      className="chart-options"
      sx={{
        // Stack uses margin but here we want to control better the overflow
        paddingTop: isOpen ? 5 : 0,
        marginTop: '0 !important',
        overflow: 'hidden',
        // since we control the overflow and we want a transition we calculate the actual value
        height: isOpen ? `calc(56px + ${theme.spacing(5)})` : 0,
        transition: 'height 0.2s ease-in-out, padding-top 0.2s ease-in-out',
      }}
    >
      <Stack spacing={2} direction="row">
        <MeasuresPicker value={options.measures} onChange={onMeasuresChange} />
        <DimensionPicker
          value={options.dimension ?? ''}
          onChange={onDimensionChange}
        />
        {options.dimension === timeDimension.name && (
          <TimeGranularityPicker
            value={options.granularity}
            onChange={onGranularityChange}
          />
        )}
        {/* TODO: https://infogrid.atlassian.net/browse/ST-310 */}
        {/*
            <FormControlLabel
              control={<Checkbox />}
              label={t('Compare to previous period')}
            />
        */}
        <Box flex="1" />
        <ChartTypePicker value={options.type} onChange={onTypeChange} />
      </Stack>
    </Box>
  );
};
