export const NAMESPACE = {
  COMMON: 'common',
  BUILDINGS: 'buildings',
  HOME: 'home',
  LOGIN: 'login',
  REPORTS: 'reports',
  RESPOND: 'respond',
  TASKS: 'tasks',
} as const;

export const DEFAULT_NAMESPACE = NAMESPACE.COMMON;

export type Namespace = (typeof NAMESPACE)[keyof typeof NAMESPACE];
