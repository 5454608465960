import { QueryResult, RawDataRow, useCube } from 'cube';
import { ConfigurableChartOptions } from 'features/reports';
import { useBuilder } from 'features/reports/configurable-chart/utils';
import { getEffectiveTimePeriod, timeDimension } from 'features/reports/utils';

interface UseSummaryStatisticsProps {
  duration: number;
  locations?: string[];
  measures: string[];
}

type ParsedDataRow = Record<string, number | null>;

const parseRawDataRow = (row: RawDataRow): ParsedDataRow => {
  return Object.entries(row).reduce<ParsedDataRow>((newRow, [key, value]) => {
    // The statistics should only be numeric, however RawDatRow includes booleans as a valid type,
    // so we ignore them here
    if (typeof value === 'boolean') {
      return newRow;
    }

    newRow[key] = typeof value === 'string' ? parseFloat(value) : value;

    return newRow;
  }, {} as ParsedDataRow);
};

export const useSummaryStatistics = ({
  duration,
  locations = [],
  measures,
}: UseSummaryStatisticsProps) => {
  const currentTimeRange = getEffectiveTimePeriod(new Date(), duration);

  const previousTimeRange = getEffectiveTimePeriod(
    currentTimeRange.date_from,
    duration
  );

  const currentStatisticsBuilder = useBuilder(
    {
      timeRange: {
        timeFrom: currentTimeRange.date_from,
        timeTo: currentTimeRange.date_to,
      },
      buildings: locations,
      workingHours: 'all',
    },
    {
      measures,
      dimension: timeDimension.name,
    } as ConfigurableChartOptions
  );

  const previousStatisticsBuilder = useBuilder(
    {
      timeRange: {
        timeFrom: previousTimeRange.date_from,
        timeTo: previousTimeRange.date_to,
      },
      buildings: locations,
      workingHours: 'all',
    },
    {
      measures,
      dimension: timeDimension.name,
    } as ConfigurableChartOptions
  );

  const { data: currentData, isLoading: isLoadingCurrentData } =
    useCube<QueryResult>(
      'home-page-current-statistics',
      currentStatisticsBuilder
    );

  const { data: previousData, isLoading: isLoadingPreviousData } =
    useCube<QueryResult>(
      'home-page-previous-statistics',
      previousStatisticsBuilder
    );

  const rawCurrentStatistics = currentData?.rawData()[0] ?? {};
  const rawPreviousStatistics = previousData?.rawData()[0] ?? {};

  return {
    isLoading: isLoadingCurrentData || isLoadingPreviousData,
    currentStatistics: parseRawDataRow(rawCurrentStatistics),
    previousStatistics: parseRawDataRow(rawPreviousStatistics),
  };
};
