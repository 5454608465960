import { Stack, Button, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUserEditContext } from '../context';

import { JOB_ROLE_LABELS, JOB_ROLES } from 'features/task-management/constants';
import SETTINGS from 'settings';

const userManagementHref = `${SETTINGS.LEGACY_WEBCLIENT}/organization/accounts/`;

export const UserConfigurationStep1 = () => {
  const { t } = useTranslation('tasks');
  const { userDetail } = useUserEditContext();

  const jobRole =
    JOB_ROLE_LABELS[userDetail.job_role] || JOB_ROLE_LABELS[JOB_ROLES.OTHER];

  return (
    <Stack data-cy="user-config-step-1">
      <Typography data-cy="user-profile-name" variant="h3" gutterBottom>
        {userDetail.name}
      </Typography>
      <Typography data-cy="user-profile-banner">
        {t(jobRole)} - {userDetail.phone_number || t('No Phone Number')} -{' '}
        {userDetail.email}
        <Link
          href={`${userManagementHref}${userDetail.id}`}
          rel="noopener"
          target="_blank"
        >
          <Button
            data-cy="user-profile-edit"
            variant="text"
            sx={{ marginLeft: 2 }}
          >
            {t('Edit profile')}
          </Button>
        </Link>
      </Typography>
    </Stack>
  );
};
