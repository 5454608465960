import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { Logo } from '../../logo';

interface PageLoaderProps {
  label?: string;
}
export const PageLoader = ({ label }: PageLoaderProps) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const defaultLabel = t('Loading...');

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Logo size={80} animated />
      <Typography
        variant="body2"
        color={theme.palette.text.disabled}
        sx={{ marginTop: theme.spacing(2) }}
        data-cy="page-loader-label"
      >
        {label ?? defaultLabel}
      </Typography>
    </Box>
  );
};
