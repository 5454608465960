import { Typography } from '@mui/material';

import { useUserEditContext } from '../context';

export const UserConfigurationStep3 = () => {
  const { userDetail } = useUserEditContext();

  return (
    <Typography data-cy="user-profile-step3-stub">
      Step 3 - {userDetail.id}
    </Typography>
  );
};
