import { CubeDescriptor, SMARTCLEANINGPRO_SCHEMA } from 'cube';
import { registerTranslationKey } from 'packages/i18n';

export const DEFAULT_SCHEMA = SMARTCLEANINGPRO_SCHEMA as CubeDescriptor;
export const availableMeasures = Object.keys(DEFAULT_SCHEMA.MEASURES).map(
  (measureId) => {
    const key = measureId as keyof typeof DEFAULT_SCHEMA.MEASURES;
    return {
      id: DEFAULT_SCHEMA.MEASURES[key].name.toString(),
      label: DEFAULT_SCHEMA.MEASURES[key].title.toString(),
      format:
        DEFAULT_SCHEMA.MEASURES[key].format ||
        DEFAULT_SCHEMA.MEASURES[key].meta?.format,
    };
  }
);
export const timeDimension =
  DEFAULT_SCHEMA.TIME_DIMENSIONS['TIMESTAMP_QUARTER_HOUR'];
export const availableDimensions = [
  ...Object.keys(DEFAULT_SCHEMA.DIMENSIONS)
    .filter((dimensionId) => {
      const key = dimensionId as keyof typeof DEFAULT_SCHEMA.DIMENSIONS;
      return !DEFAULT_SCHEMA.DIMENSIONS[key].meta?.isFilter;
    })
    .map((dimensionId) => {
      const key = dimensionId as keyof typeof DEFAULT_SCHEMA.DIMENSIONS;
      return {
        id: DEFAULT_SCHEMA.DIMENSIONS[key].name.toString(),
        label: DEFAULT_SCHEMA.DIMENSIONS[key].title.toString(),
      };
    }),
  {
    id: timeDimension.name.toString(),
    label: timeDimension.title.toString(),
  },
];

export interface TimeRange {
  timeFrom: Date;
  timeTo: Date;
}
export type TimeGranularity = 'hour' | 'day' | 'week' | 'month';
export const TimeGranularities: { label: string; id: TimeGranularity }[] = [
  {
    label: registerTranslationKey('Hours', { ns: 'reports' }),
    id: 'hour',
  },
  {
    label: registerTranslationKey('Days', { ns: 'reports' }),
    id: 'day',
  },
  {
    label: registerTranslationKey('Weeks', { ns: 'reports' }),
    id: 'week',
  },
  {
    label: registerTranslationKey('Months', { ns: 'reports' }),
    id: 'month',
  },
];

export interface Column {
  field: string;
  headerName: string;
  columnType: string;
  format?: 'duration' | 'percent' | 'float' | 'hour' | 'day';
}
