import { LoadingButton } from '@mui/lab';
import { Button, MenuItem, MenuItemProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Report, useDeleteReport } from '../../../api/reports';

import { Modal } from 'design-system/components/modal';

interface ReportDeleteMenuItemProps extends MenuItemProps {
  report: Report;
  onDeleted?: () => void;
}

export const ReportDeleteMenuItem = forwardRef<
  HTMLLIElement,
  ReportDeleteMenuItemProps
>(({ report, onDeleted = () => {}, ...props }, ref) => {
  const theme = useTheme();
  const { t } = useTranslation('reports');
  const { trigger: onDelete, isMutating: isDeleting } = useDeleteReport(() => {
    onDeleted();
    setIsConfirmationOpen(false);
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  return (
    <>
      <MenuItem
        ref={ref}
        data-cy={`menu-delete-${report.id}`}
        sx={{ color: theme.palette.error.main }}
        onClick={() => setIsConfirmationOpen(true)}
        disabled={isDeleting}
        {...props}
      >
        {t('Delete')}
      </MenuItem>
      <Modal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title={t('Confirm report deletion')}
        actions={
          <>
            <Button
              data-cy="confirmation-cancel"
              variant="text"
              onClick={() => setIsConfirmationOpen(false)}
              disabled={isDeleting}
            >
              {t("No, don't delete")}
            </Button>
            <LoadingButton
              data-cy="confirmation-delete"
              variant="contained"
              color="error"
              onClick={() => onDelete({ id: report.id.toString() })}
              loading={isDeleting}
            >
              {t('Yes, delete')}
            </LoadingButton>
          </>
        }
      >
        <Typography variant="body1">
          {t(
            'Are you sure you want to delete the report {{report_name}}? This cannot be undone.',
            { report_name: report.name }
          )}
        </Typography>
      </Modal>
    </>
  );
});

ReportDeleteMenuItem.displayName = 'ReportDeleteMenuItem';
