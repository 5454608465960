import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CircleLoader } from '..';

import { Building, useBuildings } from 'api/hierarchy';

interface LocationSelectorProps {
  value: string[];
  onChange: (value: string[], isAllSelected: boolean) => void;
  isAllLocationsSelected?: boolean;
}

export const LocationSelector = ({
  value,
  onChange,
  isAllLocationsSelected = false,
}: LocationSelectorProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data: buildings = [], isLoading } = useBuildings();

  const isAllSelected =
    !isLoading && (isAllLocationsSelected || value.length === buildings.length);

  const onSelectAll = () => {
    if (isAllSelected) {
      onChange([], false);
    } else {
      onChange(
        buildings.map((building) => building.id),
        true
      );
    }
  };

  const onToggle = (building: Building) => {
    const isBuildingSelected = value.find((id) => id === building.id);
    if (isBuildingSelected) {
      const selectedBuildingIds = value.filter((id) => id !== building.id);
      onChange(
        selectedBuildingIds,
        selectedBuildingIds.length === buildings.length
      );
    } else {
      const selectedBuildingIds = [...value, building.id];
      onChange(
        selectedBuildingIds,
        selectedBuildingIds.length === buildings.length
      );
    }
  };

  return (
    <List
      data-cy="location-selector"
      sx={{ border: `1px solid ${theme.palette.divider}` }}
      disablePadding
    >
      <ListItem divider data-cy="building-selected-all">
        <ListItemButton>
          <ListItemIcon onClick={onSelectAll}>
            <Checkbox edge="start" checked={isAllSelected} />
          </ListItemIcon>
          <ListItemText id="all" primary={t('All buildings')} />
        </ListItemButton>
      </ListItem>
      {isLoading ? (
        <CircleLoader sx={{ minHeight: 200 }} />
      ) : (
        buildings.map((building) => (
          <ListItem
            key={building.id}
            data-cy={`building-selected-${building.id}`}
          >
            <ListItemButton>
              <ListItemIcon onClick={() => onToggle(building)}>
                <Checkbox
                  edge="start"
                  checked={
                    !!value.find((id) => id === building.id) || isAllSelected
                  }
                />
              </ListItemIcon>
              <ListItemText id={building.id} primary={building.name} />
            </ListItemButton>
          </ListItem>
        ))
      )}
    </List>
  );
};
