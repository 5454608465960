import {
  CubeSchema,
  LOCATIONHIERARCHIES_SCHEMA,
  QueryResult,
  queryBuilder,
  useCube,
} from '../cube';

export interface Building {
  name: string;
  id: string;
  floorCount: number;
}

export const useBuildings = () => {
  const builder = queryBuilder<CubeSchema>(LOCATIONHIERARCHIES_SCHEMA);
  builder.addDimension(LOCATIONHIERARCHIES_SCHEMA.DIMENSIONS.BUILDING_ID.name);
  builder.addDimension(
    LOCATIONHIERARCHIES_SCHEMA.DIMENSIONS.BUILDING_NAME.name
  );
  builder.addMeasure(LOCATIONHIERARCHIES_SCHEMA.MEASURES.FLOOR_COUNT.name);
  builder.addFilter(
    LOCATIONHIERARCHIES_SCHEMA.DIMENSIONS.BUILDING_ID.name,
    'set'
  );
  const cube = useCube<QueryResult>('get-hierarchy', builder);
  return {
    ...cube,
    data: cube.data?.rawData().map<Building>((item) => ({
      id:
        item[
          LOCATIONHIERARCHIES_SCHEMA.DIMENSIONS.BUILDING_ID.name
        ]?.toString() ?? '',
      name:
        item[
          LOCATIONHIERARCHIES_SCHEMA.DIMENSIONS.BUILDING_NAME.name
        ]?.toString() ?? '',
      floorCount: parseInt(
        item[
          LOCATIONHIERARCHIES_SCHEMA.MEASURES.FLOOR_COUNT.name
        ]?.toString() ?? '0'
      ),
    })),
  };
};
