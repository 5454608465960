export const convertIntToUuid = (num: number | bigint) => {
    const hexed = num.toString(16).padStart(32, '0');

    return [
        hexed.substring(0, 8),
        hexed.substring(8, 12),
        hexed.substring(12, 16),
        hexed.substring(16, 20),
        hexed.substring(20, 32),
    ].join('-');
};
