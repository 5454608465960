import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PercentageChangeContent } from './percentage-change-content';

import { FormatType, valueFormatters } from 'design-system/formatting';

export interface SummaryCardProps {
  currentValue: number;
  'data-cy'?: string;
  previousValue: number | null;
  title: string;
  type: FormatType;
}

export const SummaryCard = ({
  currentValue,
  'data-cy': dataCy,
  previousValue,
  title,
  type,
}: SummaryCardProps) => {
  const { t } = useTranslation();
  return (
    <Box data-cy={dataCy} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography data-cy="summary-card-title" variant="h6">
        {title}
      </Typography>
      <Typography
        data-cy="summary-card-value"
        sx={{ fontSize: 40, fontWeight: 600 }}
      >
        {valueFormatters[type](currentValue, t)}
      </Typography>
      <PercentageChangeContent
        currentValue={currentValue}
        previousValue={previousValue}
      />
    </Box>
  );
};
