import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: boolean;
  minHeight?: string | number;
  secondaryHeader?: React.ReactNode;
}
export const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  actions,
  maxWidth = 'sm',
  fullWidth = false,
  minHeight,
  secondaryHeader,
}: ModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      // This fixes a bug where some keys will be swallowed,
      // if the modal is in a <Menu/>
      onKeyDown={(event) => {
        if (event.key !== 'Escape') {
          event.stopPropagation();
        }
      }}
      fullScreen={fullScreen}
      data-cy="modal"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={{ sx: { borderRadius: 1, minHeight } }}
    >
      <DialogTitle
        variant="h4"
        sx={{ padding: theme.spacing(3) }}
        data-cy="modal-title"
      >
        {title}
      </DialogTitle>
      <IconButton
        data-cy="modal-close"
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: theme.spacing(2),
          top: theme.spacing(2.5),
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {secondaryHeader && (
        <>
          <Box sx={{ margin: theme.spacing(0, 3) }}>{secondaryHeader}</Box>
          <Divider sx={{ marginBottom: theme.spacing(1) }} />
        </>
      )}
      <DialogContent
        data-cy="modal-content"
        sx={{ padding: theme.spacing(2, 3) }}
      >
        {children}
      </DialogContent>
      {!!actions && (
        <DialogActions
          data-cy="modal-actions"
          sx={{ padding: theme.spacing(3) }}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};
