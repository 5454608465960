import {
  ArrowDownward,
  ArrowUpward,
  HorizontalRule,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const calculatePercentage = (
  currentValue: number,
  previousValue: number | null
): number | null => {
  // This includes both null and zero intentionally
  if (!previousValue) {
    return null;
  }

  return Math.round(((currentValue - previousValue) / previousValue) * 100);
};

const getIcon = (percentage: number | null) => {
  if (percentage === null) {
    return null;
  }

  if (percentage === 0) {
    return <HorizontalRule color="warning" />;
  }

  if (percentage > 0) {
    return <ArrowUpward color="success" />;
  }

  return <ArrowDownward color="error" />;
};

const getText = (percentage: number | null, t: TFunction) => {
  if (percentage === null) {
    return t('no data to compare');
  }

  if (percentage === 0) {
    return t('no change vs last period');
  }

  if (percentage > 0) {
    return t('up {{value}}% vs last period', { value: Math.abs(percentage) });
  }

  return t('down {{value}}% vs last period', { value: Math.abs(percentage) });
};

interface PercentageChangeContentProps {
  currentValue: number;
  previousValue: number | null;
}

export const PercentageChangeContent = ({
  currentValue,
  previousValue,
}: PercentageChangeContentProps) => {
  const { t } = useTranslation('home');

  const percentage = calculatePercentage(currentValue, previousValue);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        height: 24,
      }}
    >
      {getIcon(percentage)}
      <Typography
        color="textSecondary"
        data-cy="summary-card-comparison"
        sx={{ ml: 0.5 }}
        variant="body2"
      >
        {getText(percentage, t)}
      </Typography>
    </Box>
  );
};
