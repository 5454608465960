import { LoadingButton } from '@mui/lab';
import { Button, MenuItem, MenuItemProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'design-system/components/modal';

interface ConfigurableChartDeleteMenuItemProps extends MenuItemProps {
  title: string;
  onDelete: () => void;
}

export const ConfigurableChartDeleteMenuItem = forwardRef<
  HTMLLIElement,
  ConfigurableChartDeleteMenuItemProps
>(({ title, onDelete, ...props }, ref) => {
  const theme = useTheme();
  const { t } = useTranslation('reports');

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  return (
    <>
      <MenuItem
        ref={ref}
        data-cy="chart-menu-delete"
        sx={{ color: theme.palette.error.main }}
        onClick={() => setIsConfirmationOpen(true)}
        {...props}
      >
        {t('Delete')}
      </MenuItem>
      <Modal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title={t('Confirm chart deletion')}
        actions={
          <>
            <Button
              data-cy="delete-chart-confirmation-cancel"
              variant="text"
              onClick={() => setIsConfirmationOpen(false)}
            >
              {t("No, don't delete")}
            </Button>
            <LoadingButton
              data-cy="delete-chart-confirmation"
              variant="contained"
              color="error"
              onClick={() => {
                onDelete();
                setIsConfirmationOpen(false);
              }}
            >
              {t('Yes, delete')}
            </LoadingButton>
          </>
        }
      >
        <Typography variant="body1">
          {t(
            'Are you sure you want to delete the chart {{chart_name}}? This cannot be undone.',
            { chart_name: title }
          )}
        </Typography>
      </Modal>
    </>
  );
});

ConfigurableChartDeleteMenuItem.displayName = 'ConfigurableChartDeleteMenuItem';
