import { Box, Typography } from '@mui/material';
import { maxBy, meanBy, minBy, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { QueryResult, RawDataCell } from 'cube';
import {
  FormatType,
  formatDatetime,
  valueFormatters,
} from 'design-system/formatting';
import {
  DEFAULT_SCHEMA,
  TimeGranularity,
  availableMeasures,
  formatLabel,
  timeDimension,
} from 'features/reports/utils';

const averagedFormats = ['percent'];

interface MeasureHeadlineProps {
  title: string;
  value: string | RawDataCell | undefined;
}
const MeasureHeadline = ({ title, value }: MeasureHeadlineProps) => {
  return (
    <Box sx={{ textWrap: 'nowrap' }}>
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" data-cy="headline-value">
        {value}
      </Typography>
    </Box>
  );
};

interface MeasureHeadlinesProps {
  measure: string;
  dimension: string;
  granularity?: TimeGranularity;
  data?: QueryResult;
}
export const MeasureHeadlines = ({
  measure,
  dimension,
  granularity,
  data,
}: MeasureHeadlinesProps) => {
  const { t } = useTranslation('reports');

  // This is to get only the subset of data that relates to that measure+dimension
  // arguably this could be done prior to calling that component
  // but the parent was bloated enough already.
  const normalizedData = data?.rawData().map((row) => ({
    key: row[dimension],
    value: row[measure],
  }));

  const measureMetadata = availableMeasures.find((m) => m.id === measure);
  const name = measureMetadata?.label?.toLowerCase();
  const format = measureMetadata?.format ?? 'number';

  const total = sumBy(normalizedData, (d) => parseFloat(String(d.value)));
  const average = meanBy(normalizedData, (d) => parseFloat(String(d.value)));
  const value = averagedFormats.includes(format) ? average : total;

  const least = minBy(normalizedData, (d) => d.value)?.key;
  const most = maxBy(normalizedData, (d) => d.value)?.key;

  const formattedValue =
    !value && value !== 0
      ? '-'
      : valueFormatters[format as FormatType](value, t);

  const formatCategory = (category: RawDataCell | undefined) => {
    if (!category) {
      return '-';
    }
    if (
      // this array condition should be based on format rather than dimensions
      [
        DEFAULT_SCHEMA.DIMENSIONS.SPACE_TYPE.name,
        DEFAULT_SCHEMA.DIMENSIONS.TIMESTAMP_DAY_OF_WEEK.name,
        DEFAULT_SCHEMA.DIMENSIONS.TIMESTAMP_HOUR_OF_DAY.name,
      ].includes(dimension)
    ) {
      return formatLabel(t, String(category), dimension);
    }

    if (dimension === timeDimension.name) {
      return formatDatetime(String(category), granularity);
    }

    return category;
  };

  const formattedLeast = formatCategory(least);
  const formattedMost = formatCategory(most);

  return (
    <>
      <MeasureHeadline
        title={
          averagedFormats.includes(format)
            ? t('Average {{name}}', { name })
            : t('Total {{name}}', { name })
        }
        value={formattedValue}
      />
      <MeasureHeadline
        title={t('Most {{name}}', { name })}
        value={formattedMost}
      />
      <MeasureHeadline
        title={t('Least {{name}}', { name })}
        value={formattedLeast}
      />
    </>
  );
};
