import { Backdrop, Box, Container, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useSignOut } from 'react-auth-kit';
import { useNavigation } from 'react-router-dom';

import { useUser } from '../api/user';
import {
  SALES_DEMO_ORG_ID,
  getCurrentOrganizationId,
  isOrganizationIdSet,
  setOrganizationId,
} from '../api/utils';
import { PageLoader } from '../design-system/components';
import { MobileNavigation } from '../design-system/components/mobile-navigation/mobile-navigation';
import { Navigation } from '../design-system/components/navigation/navigation';
import {
  PageHeader,
  PageHeaderProps,
} from '../design-system/components/page-header';

import { AppContainer } from './app-container';

interface PageContainerProps {
  headerProps?: PageHeaderProps;
  isLoading?: boolean;
  children: React.ReactNode;
}
export const PageContainer = ({
  headerProps,
  isLoading,
  children,
}: PageContainerProps) => {
  const theme = useTheme();
  const signOut = useSignOut();
  const { isLoading: isLoadingUser, data, error, mutate } = useUser();
  const currentOrgId = getCurrentOrganizationId();
  const navigation = useNavigation();
  const isNavigating = navigation.state === 'loading';

  useEffect(() => {
    if (error) {
      signOut();
      return;
    }
    if (!isLoadingUser && !isOrganizationIdSet()) {
      // log out if user has access to no org
      if (!data || !data?.organizational_roles.length) {
        signOut();
        return;
      }

      let orgId = data?.organizational_roles[0].id;
      if (data?.organizational_roles.find((o) => o.id === SALES_DEMO_ORG_ID)) {
        // if user has access to the sales demo org, use that
        orgId = SALES_DEMO_ORG_ID;
      }
      setOrganizationId(orgId);
      /*
        We are using `_deepCompareHack` here to force a re-render as `mutate()` use deep compare.

        We are doing this because upon the very first load (before `infogrid_organization` was set)
        the Organization (based upon the very first render of `useUser()`) will be undefined and does not propagate/re-render (after it has been),
        which will likely impact subsequent API calls that need this to have been set.
        We could probably replace this with:
        - a context?
        - a hook separate from the API call?
        - an event based solution?
      */
      // @ts-expect-error _deepCompareHack does not exist on User
      mutate({ ...data, _deepCompareHack: true });
    }
  }, [isLoadingUser, error, data, signOut, currentOrgId, mutate]);

  const isLoadingAny = isLoading || isLoadingUser || isNavigating;
  return (
    <AppContainer>
      <Navigation />
      <Box
        sx={{
          zIndex: 0,
          overflow: 'auto',
          [theme.breakpoints.down('md')]: {
            // total height - navigation - mobile navigation
            minHeight: 'calc(100dvh - 64px - 56px)',
          },
        }}
      >
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.background.default,
          }}
          open={isLoadingAny}
        >
          <PageLoader />
        </Backdrop>
        {!isLoadingAny && (
          <>
            {headerProps && <PageHeader {...headerProps} />}
            <Container
              maxWidth="lg"
              sx={{
                padding: theme.spacing(4, 0),
              }}
            >
              <Stack
                spacing={4}
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    // areas with a background don't need spacing, but the titles and co do
                    '& > :not(.MuiPaper-root)': {
                      padding: theme.spacing(0, 2),
                    },
                  },
                }}
              >
                {children}
              </Stack>
            </Container>
          </>
        )}
      </Box>
      <MobileNavigation />
    </AppContainer>
  );
};
