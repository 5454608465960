import { Box, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface TaskCardItemProps {
  children: ReactNode;
  title: string;
}

export const TaskCardItem = ({ children, title }: TaskCardItemProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: 0, md: 1 },
        py: { xs: 0.5, md: 0 },
      }}
    >
      <Typography
        sx={{
          fontWeight: theme.typography.fontWeightBold,
          textTransform: 'uppercase',
        }}
        variant="subtitle2"
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
