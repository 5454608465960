import {
  Button,
  Stack,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';

import { toShortDate } from 'design-system/formatting';
import {
  TimePeriodId,
  TimePeriods,
  getEffectiveTimePeriod,
} from 'features/reports/utils';

interface TimePeriodPickerProps {
  buttonsOnly?: boolean;
  disableCustom?: boolean;
  onChange: (value: TimePeriodId) => void;
  value: TimePeriodId;
}

export const TimePeriodPicker = ({
  buttonsOnly = false,
  disableCustom = false,
  onChange = noop,
  value,
}: TimePeriodPickerProps) => {
  const theme = useTheme();
  const {
    i18n: { language },
    t,
  } = useTranslation('reports');

  const duration = TimePeriods.find((type) => value === type.id)!.value ?? 0;
  const { date_from, date_to } = getEffectiveTimePeriod(new Date(), duration);

  const formattedDateRange =
    duration === 1
      ? `${toShortDate(date_from, language)} (00:00 - 23:59)`
      : `${toShortDate(date_from, language)} - ${toShortDate(date_to, language)}`;

  const periods = disableCustom
    ? TimePeriods.filter((tp) => tp.id !== 'custom')
    : TimePeriods;

  return (
    <Stack sx={{ width: 'fit-content' }} direction="column" spacing={2}>
      {!buttonsOnly && (
        <Typography variant="subtitle1">{t('Time period')}</Typography>
      )}
      <Stack direction="column" spacing={1}>
        <Stack spacing={1} direction="row">
          {periods.map((item) => (
            <ToggleButton
              key={item.id}
              data-cy={`time-period-${item.id}`}
              color="primary"
              value={item.id}
              disabled={item.id === 'custom'}
              onChange={(_e, newVal) => onChange(newVal)}
              selected={value === item.id}
              sx={{
                width: item.value ? 56 : null,
                backgroundColor: theme.palette.common.white,

                '&:hover': {
                  backgroundColor: theme.palette.common.white,
                },
              }}
            >
              {item.label}
            </ToggleButton>
          ))}
        </Stack>

        {!buttonsOnly && (
          <Button
            data-cy="time-period-range"
            variant="outlined"
            size="small"
            fullWidth
            disabled
            sx={{
              backgroundColor: theme.palette.common.white,
              height: 24,
              '&.Mui-disabled': {
                color: theme.palette.text.secondary,
              },
            }}
          >
            {formattedDateRange}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
