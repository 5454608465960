import { Stack, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUserEditContext } from '../context';

import { useBuildings } from 'api/hierarchy';
import { CircleLoader } from 'design-system/components';
import { JOB_ROLE_LABELS, JOB_ROLES } from 'features/task-management/constants';

export const UserConfigurationStep4 = () => {
  const { t } = useTranslation('tasks');
  const { userDetail, locationIds } = useUserEditContext();
  const { data: buildings = [], isLoading } = useBuildings();

  const jobRole =
    JOB_ROLE_LABELS[userDetail.job_role] || JOB_ROLE_LABELS[JOB_ROLES.OTHER];

  return (
    <Stack data-cy="user-config-step-4">
      <Typography variant="h3" gutterBottom>
        {t('Summary')}
      </Typography>
      <Typography
        data-cy="user-profile-summary-name"
        variant="subtitle1"
        sx={{ mt: 2 }}
      >
        {userDetail.name}
      </Typography>
      <Typography data-cy="user-profile-summary-banner">
        {t(jobRole)} - {userDetail.phone_number || t('No Phone Number')} -{' '}
        {userDetail.email}
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        {t('Locations')}
      </Typography>
      <Box data-cy="user-profile-summary-locations">
        {isLoading ? (
          <CircleLoader sx={{ minHeight: 200 }} />
        ) : (
          buildings
            .filter((building) => locationIds.includes(building.id))
            .map((building) => (
              <Typography
                key={`user-profile-summary-locations-${building.id}`}
                data-cy={`user-profile-summary-locations-${building.id}`}
              >{`${building.name} - ${t('All floors')}`}</Typography>
            ))
        )}
      </Box>
    </Stack>
  );
};
