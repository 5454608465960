import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { options } from './configs/options';
import { testOptions } from './configs/testOptions';

if (process.env.NODE_ENV === 'test') {
  // do not load translation dicts and backend if in test mode
  // If default export not required, can fix and remove rule
  // eslint-disable-next-line import/no-named-as-default-member
  i18n.use(initReactI18next).init(testOptions);
} else {
  // If default export not required, can fix and remove rule
  // eslint-disable-next-line import/no-named-as-default-member
  i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);
}

export default i18n;
