import { Box, Card, Typography } from '@mui/material';

/* eslint-disable import/no-unresolved */
import BarChartThumb from '../../../assets/bar-chart.svg?react';
import BarLineChartThumb from '../../../assets/bar-line-chart.svg?react';
import CustomChartThumb from '../../../assets/custom-chart.svg?react';
import HeatMapChartThumb from '../../../assets/heat-map.svg?react';
import LineChartThumb from '../../../assets/line-chart.svg?react';

import { palette } from 'design-system';
/* eslint-enable import/no-unresolved */

export interface ReportCardProps {
  chartType: 'barLine' | 'line' | 'heatMap' | 'bar' | 'custom';
  title: string;
  description: string;
  onClick: () => void;
}

const chartTypes = {
  barLine: BarLineChartThumb,
  line: LineChartThumb,
  heatMap: HeatMapChartThumb,
  bar: BarChartThumb,
  custom: CustomChartThumb,
};

export const ReportCard = ({
  chartType,
  title,
  description,
  onClick,
}: ReportCardProps) => {
  const Thumbnail = chartTypes[chartType];

  return (
    <Card
      className="clickable"
      sx={{ flex: 1 }}
      onClick={onClick}
      data-cy="report-card"
    >
      <Box>
        <Thumbnail data-cy={`report-card-thumb-${chartType}`} width="100%" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        px={3}
        paddingBottom={3}
      >
        <Typography
          data-cy="report-card-title"
          variant="h5"
          color={palette.palette.primary.main}
        >
          {title}
        </Typography>
        <Typography
          data-cy="report-card-description"
          variant="body2"
          color={palette.palette.text.secondary}
        >
          {description}
        </Typography>
      </Box>
    </Card>
  );
};
