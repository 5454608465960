/// <reference types="vite-plugin-svgr/client" />
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/styled-engine';
// eslint-disable-next-line import/no-unresolved
import InfogridLogo from 'assets/logo.svg?react';

import { palette } from 'design-system';

// those 3 animations are animating the 3 parts of the logo:
// - bottom left corner circle part
// - larger circle part
// - grid part
const animatedPart1 = keyframes`
  0% {
    fill: ${palette.palette.secondary.main};
  }
  1% {
    fill: ${palette.palette.primary.main};
  }
  10% {
    fill: ${palette.palette.secondary.main};
  }
  100% {
    fill: ${palette.palette.secondary.main};
  }
`;
const animatedPart2 = keyframes`
  0% {
    fill: ${palette.palette.secondary.main};
  }
  1% {
    fill: ${palette.palette.secondary.main};
  }
  2% {
    fill: ${palette.palette.primary.main};
  }
  10% {
    fill: ${palette.palette.secondary.main};
  }
  100% {
    fill: ${palette.palette.secondary.main};
  }
`;
const animatedPart3 = keyframes`
  0% {
    fill: ${palette.palette.secondary.main};
  }
  2% {
    fill: ${palette.palette.secondary.main};
  }
  3% {
    fill: ${palette.palette.primary.main};
  }
  10% {
    fill: ${palette.palette.secondary.main};
  }
  100% {
    fill: ${palette.palette.secondary.main};
  }
`;

interface LogoProps {
  size?: number;
  animated?: boolean;
}
export const Logo = ({ size = 32, animated }: LogoProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        lineHeight: 0,
        color: theme.palette.secondary.main,
        ...(animated
          ? {
              '& svg g path:nth-child(1)': {
                animation: `${animatedPart1} 5s linear infinite`,
              },
              '& svg g path:nth-child(2)': {
                animation: `${animatedPart2} 5s linear infinite`,
              },
              '& svg g path:nth-child(3)': {
                animation: `${animatedPart3} 5s linear infinite`,
              },
            }
          : undefined),
      }}
    >
      <InfogridLogo style={{ width: size, height: size }} />
    </Box>
  );
};
