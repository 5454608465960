import { PaginatedResponse, TeamUser, User, UserDetail } from './types';
import {
  getCurrentOrganizationId,
  usePutCoreApi,
  useGetCoreAPI,
  useGetCoreAPIImmutable,
} from './utils';

export const useUser = () => {
  return useGetCoreAPIImmutable<User>('user/me');
};

export const useUsers = () => {
  return useGetCoreAPIImmutable<PaginatedResponse<TeamUser[]>>(
    // We are unlikely to have more than 1000 users in an organization at this point, we can revisit later.
    `org/${getCurrentOrganizationId()}/users/all-users?page_size=1000`
  );
};
export const useCurrentOrganization = () => {
  const { isLoading, data } = useUser();
  return {
    isLoading,
    data: data?.organizational_roles.find(
      (o) => o.id.toString() === getCurrentOrganizationId()
    ),
  };
};

export const useUserDetail = (userId: number) => {
  return useGetCoreAPIImmutable<UserDetail>(`user/profile-info/${userId}`);
};

interface UserFloor {
  id: string;
  name: string;
}

export interface UserBuilding {
  id: string;
  name: string;
  floors: { [key: string]: UserFloor };
}

export interface UserLocationAssociation {
  id: number;
  full_name: string;
  locations: {
    buildings: { [key: string]: UserBuilding };
  };
}

interface UserLocationParams {
  page: number;
  pageSize: number;
}

export const useUserLocations = (params: UserLocationParams) =>
  useGetCoreAPI<PaginatedResponse<UserLocationAssociation[]>>(
    'smart-cleaning-pro/users/locations',
    params
  );

export interface UpdateUserLocationsBody {
  location_ids: number[];
}

export const useUpdateUserLocations = (
  userId: number,
  callback: () => void = () => {}
) => {
  const { trigger: onUpdate, isMutating: isUpdating } =
    usePutCoreApi<UpdateUserLocationsBody>(
      `smart-cleaning-pro/users/${userId}/locations`,
      callback
    );

  const updateReport = async (body: UpdateUserLocationsBody) =>
    await onUpdate({ body });

  return { trigger: updateReport, isMutating: isUpdating };
};
