import { LoadingButton } from '@mui/lab';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import dashedBorderBackground from 'assets/dashed-border-background.svg';

interface AddChartCardProps {
  onClick?: () => void;
  isCreating?: boolean;
}
export const AddChartCard = ({ onClick, isCreating }: AddChartCardProps) => {
  const { t } = useTranslation('reports');
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: `${theme.palette.primary.main}0A`,
        padding: theme.spacing(5),
        justifyContent: 'center',
        display: 'flex',
        backgroundImage: `url(${dashedBorderBackground})`,
      }}
    >
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={onClick}
        data-cy="add-chart"
        loading={isCreating}
      >
        {t('Add chart')}
      </LoadingButton>
    </Box>
  );
};
