import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BaseTaskCard } from '../base-task-card';
import { TaskCardItem } from '../task-card-item';

import {
  toDistanceFromNowString,
  toShortDateTime,
} from 'design-system/formatting';
import { TASK_STATUSES } from 'features/task-management/constants';

export interface OpenTaskCardProps {
  buildingName: string | null;
  dateCreated: string;
  floorName: string | null;
  title: string;
}

export const OpenTaskCard = ({
  buildingName,
  dateCreated,
  floorName,
  title,
}: OpenTaskCardProps) => {
  const {
    i18n: { language },
    t,
  } = useTranslation('tasks');

  const dateCreatedString = toShortDateTime(new Date(dateCreated), language);

  const distanceToNowString = toDistanceFromNowString(
    new Date(dateCreated),
    language
  );

  return (
    <BaseTaskCard
      buildingName={buildingName}
      data-cy="open-task-card"
      floorName={floorName}
      status={TASK_STATUSES.OPEN}
      title={title}
    >
      <TaskCardItem title={t('Date created')}>
        <Typography variant="body2">
          {`${dateCreatedString} (${distanceToNowString})`}
        </Typography>
      </TaskCardItem>
    </BaseTaskCard>
  );
};
