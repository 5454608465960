import {
  Box,
  Paper,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import noop from 'lodash/noop';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Column, TimeGranularity, BuildingHourId } from '../utils';

import { Actions } from './actions';
import { HeatmapChart } from './heatmap-chart';
import { MeasureHeadlines } from './measure-headlines';
import { OptionsDrawer } from './options-drawer';
import { generateChartLabels, useBuilder } from './utils';

import { QueryResult, useCube } from 'cube';
import { ChartType, CubeChart, CubeTable } from 'features/reports';
import { TimeRange, timeDimension } from 'features/reports/utils';

export interface ConfigurableChartOptions {
  measures: string[];
  type: ChartType;
  dimension?: string;
  // granularity is expected if `dimension` is `time`
  granularity?: TimeGranularity;
  // segment must be different a different (time not included)
  segment?: string;
  previousPeriod?: boolean;
}

export interface ConfigurableChartFilters {
  timeRange: TimeRange;
  buildings?: string[];
  workingHours: BuildingHourId;
}

interface ConfigurableChartProps {
  title: string;
  options: ConfigurableChartOptions;
  filters: ConfigurableChartFilters;
  id: string;
  titleVariant?: TypographyProps['variant'];
  areOptionsOpen?: boolean;
  canvasHeight?: number;
  isReadOnly?: boolean;
  requestKey?: string;
  showHeadlines?: boolean;
  onAreOptionsOpenChange?: (areOptionsOpen: boolean) => void;
  onOptionsChange?: (options: ConfigurableChartOptions) => void;
  onTitleChange?: (title: string) => void;
  onDuplicateChart?: () => void;
  onDeleteChart?: (id: string) => void;
}

export const ConfigurableChart = ({
  title,
  titleVariant = 'h4',
  areOptionsOpen,
  canvasHeight,
  onAreOptionsOpenChange = noop,
  options,
  onOptionsChange = noop,
  filters,
  id,
  isReadOnly = false,
  onTitleChange = noop,
  onDuplicateChart = noop,
  onDeleteChart = noop,
  requestKey,
  showHeadlines = false,
}: ConfigurableChartProps) => {
  const theme = useTheme();
  const { t } = useTranslation('reports');

  const ref = useRef<HTMLDivElement>(null);

  const chartEnabled =
    options.measures.length > 0 &&
    options.dimension !== undefined &&
    (options.dimension !== timeDimension.name || !!options.granularity);
  const swrKey = requestKey
    ? `configurable-chart-${requestKey}`
    : 'configurable-chart';
  const hasDataToShow =
    !!options.dimension?.length && !!options.measures.length;

  const builder = useBuilder(filters, options);

  const { data, isLoading } = useCube<QueryResult>(swrKey, builder, {
    enabled: chartEnabled,
  });

  const columns =
    data?.tableColumns().map((tableColumn) => ({
      field: tableColumn.key,
      headerName: tableColumn.shortTitle,
      columnType: String(tableColumn.type),
      format: tableColumn.format || tableColumn.meta?.format,
    })) ?? [];

  const series = data?.series()[0];

  const labels = generateChartLabels(series, t, options.dimension);

  const datasets =
    data?.series().map((serie) => {
      const serieColumn: Column | undefined = columns.find(
        (column) => column.field === serie.key
      );

      return {
        serieColumn,
        label: serie.shortTitle,
        data: serie.series.map((item) => item.value),
      };
    }) ?? [];

  return (
    <Paper
      ref={ref}
      sx={{
        padding: { xs: theme.spacing(5, 3), md: theme.spacing(5) },
      }}
      data-cy="configurable-chart"
    >
      <Stack spacing={5}>
        <Stack spacing={2} direction="row">
          <Typography variant={titleVariant} flex="1">
            {title}
          </Typography>

          {!isReadOnly && (
            <Box>
              <Actions
                areOptionsOpen={areOptionsOpen}
                onAreOptionsOpenChange={onAreOptionsOpenChange}
                options={options}
                id={id}
                title={title}
                onTitleChange={onTitleChange}
                onDuplicateChart={onDuplicateChart}
                onDeleteChart={onDeleteChart}
                columns={columns}
                data={data}
                chartRef={ref}
              />
            </Box>
          )}
        </Stack>

        {!isReadOnly && (
          <OptionsDrawer
            isOpen={areOptionsOpen}
            onOptionsChange={onOptionsChange}
            options={options}
          />
        )}

        {showHeadlines && options.measures.length > 0 && (
          <Box
            gap={4}
            display="grid"
            gridTemplateColumns="repeat(3, min-content)"
            gridAutoRows="auto"
          >
            {options.measures.map((measure) => (
              <MeasureHeadlines
                key={`measure-headline-${measure}`}
                dimension={options.dimension!}
                measure={measure}
                data={data}
                granularity={options.granularity}
              />
            ))}
          </Box>
        )}

        {!isLoading && !hasDataToShow && (
          <Box
            data-cy="chart-no-data-selected"
            sx={{
              alignItems: 'center',
              border: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              height: canvasHeight,
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                color: theme.palette.text.disabled,
                textTransform: 'uppercase',
              }}
              variant="subtitle2"
            >
              {t('Select a measure and dimension to generate a chart')}
            </Typography>
          </Box>
        )}

        {hasDataToShow && options.type === 'bar' && (
          <CubeChart
            height={canvasHeight}
            isLoading={isLoading}
            labels={labels}
            datasets={datasets}
            unit={options.granularity}
            type="bar"
          />
        )}
        {hasDataToShow && options.type === 'line' && (
          <CubeChart
            height={canvasHeight}
            isLoading={isLoading}
            labels={labels}
            datasets={datasets}
            unit={options.granularity}
            type="line"
          />
        )}
        {hasDataToShow && options.type === 'table' && (
          <CubeTable
            columns={columns}
            isLoading={isLoading}
            rows={data?.rawData() ?? []}
            unit={options.granularity}
          />
        )}
        {hasDataToShow && options.type === 'heatmap' && (
          <HeatmapChart
            height={canvasHeight}
            columns={columns}
            isLoading={isLoading}
            labels={series?.series.map((s) => s.x) ?? []}
            datasets={datasets}
          />
        )}
      </Stack>
    </Paper>
  );
};
