import SearchIcon from '@mui/icons-material/Search';
import { TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SearchInputProps {
  id: string;
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
  isLoading?: boolean;
}
export const SearchInput = ({
  id,
  placeholder,
  value,
  onChange,
}: SearchInputProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <TextField
      id={id}
      data-cy={`search-${id}`}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      size="medium"
      placeholder={placeholder ?? t('Search...')}
      InputProps={{
        endAdornment: <SearchIcon data-cy="search-icon" />,
        className: `${value ? 'search-input-expanded' : ''}`,
        sx: {
          width: 200,
          maxWidth: '100%',
          transition: `${theme.transitions.create(['width'], {
            duration: theme.transitions.duration.standard,
            delay: 0,
          })}`,
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        },
      }}
    />
  );
};
