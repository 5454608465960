/*
    THIS FILE IS NOT IDENTICAL TO CURRENT `webclient`
*/

import type { LoadResponseResult, Query, ResultSet } from "@cubejs-client/core";
import { getCurrentOrganizationId } from "../api/utils";
import SETTINGS from '../settings';
import { createRequest } from "./axios";
import { toResultSet } from "./toResultSet";
import type {
  CubeSchema,
  HasAbortSignal,
  QueryBuilder,
  QueryRunner,
} from "./types";

const CUBE_LONG_RUNNING_ERROR = "Continue wait";

interface CubeLongRunningError {
  error?: string;
}

const fetchResultSet = async (
  query: Query,
  signal: AbortSignal | undefined,
  endpointKey?: string
): Promise<ResultSet<Record<string, string>>> => {
  const response = await createRequest<
    LoadResponseResult<Record<string, string>>
  >({
    method: "post",
    url: `${SETTINGS.COREAPI_API_URL}mpp/cube/v1/load/${
      endpointKey ?? ""
    }`,
    signal,
    options: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("_auth")}`,
        "X-Infogrid-Organization": getCurrentOrganizationId()
      },
      data: {
        query,
      },
    },
  });

  const loadResponse = response.data;

  if (
    (loadResponse as CubeLongRunningError)?.error === CUBE_LONG_RUNNING_ERROR
  ) {
    return fetchResultSet(query, signal, endpointKey);
  }

  return toResultSet(loadResponse);
};

export const cubeQueryRunner: QueryRunner = {
  runQuery: async <T extends CubeSchema = CubeSchema>(
    builder: QueryBuilder<T>,
    { signal }: HasAbortSignal,
    endpointKey?: string
  ) => {
    const query = builder.buildQuery();

    return fetchResultSet(query, signal, endpointKey);
  },
};
