import { registerTranslationKey } from 'packages/i18n';

export type BuildingHourId = 'building_specific' | 'weekdays' | 'all';

export type BuildingHourValue = {
  id: BuildingHourId;
  label: string;
};

export const BuildingHours: BuildingHourValue[] = [
  {
    id: 'building_specific',
    label: registerTranslationKey('Working hours', { ns: 'reports' }),
  },
  {
    id: 'weekdays',
    label: registerTranslationKey('Weekdays only (24 hours)', {
      ns: 'reports',
    }),
  },
  {
    id: 'all',
    label: registerTranslationKey('Every day (24 hours)', { ns: 'reports' }),
  },
];
