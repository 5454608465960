import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from '@mui/material';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Chart,
  CreateChartBody,
  useCharts,
  useCreateChart,
  useDeleteChart,
  useReport,
  useUpdateChart,
} from 'api/reports';
import { PageContainer } from 'components/page-container';
import { convertIntToUuid } from 'cube';
import {
  AddChartCard,
  ConfigurableChart,
  ConfigurableChartOptions,
  ExportReportButton,
  ReportConfigurationEditModal,
  ReportTitleSecondary,
} from 'features/reports';
import { getEffectiveTimePeriod } from 'features/reports/utils';
import { registerTranslationKey } from 'packages/i18n';

interface ChartModel {
  id: string;
  title: string;
  areOptionsOpen?: boolean;
  options: ConfigurableChartOptions;
}

const chartToModel = (chart: Chart, areOptionsOpen?: boolean): ChartModel => ({
  id: chart.id,
  title: chart.configuration.title,
  areOptionsOpen,
  options: {
    dimension: chart.configuration.dimension,
    measures: chart.configuration.measures,
    type: chart.configuration.type,
    granularity: chart.configuration.granularity,
  },
});

const modelToChart = (model: ChartModel): Chart => ({
  id: model.id,
  configuration: {
    title: model.title,
    dimension: model.options.dimension,
    measures: model.options.measures,
    type: model.options.type,
    granularity: model.options.granularity,
  },
});

const defaultNewChart: CreateChartBody = {
  configuration: {
    title: registerTranslationKey('New chart', { ns: 'reports' }),
    measures: [],
    type: 'bar',
  },
};

export const ReportPage = () => {
  const { t } = useTranslation('reports');
  const params = useParams();

  // we shoulnd't be here if the id param isn't present
  // the error handler will catch it
  const reportId = parseInt(params.id!);

  const [charts, setCharts] = useState<ChartModel[]>([]);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const { data: report, mutate: mutateReport, isLoading } = useReport(reportId);
  const { trigger: onCreate, isMutating: isCreating } =
    useCreateChart(reportId);
  const { trigger: onUpdate } = useUpdateChart(reportId);
  const { trigger: onDelete } = useDeleteChart(reportId);
  const { data: chartList, isLoading: areChartsLoading } = useCharts(reportId);

  let { date_to, date_from } = getEffectiveTimePeriod(
    new Date(),
    report?.duration ?? 0
  );

  if (!report?.rolling_date && report?.date_to && report?.date_from) {
    date_to = parseISO(report.date_to);
    date_from = parseISO(report.date_from);
  }

  const chartFilters = {
    timeRange: { timeFrom: date_from, timeTo: date_to },
    workingHours: report?.building_working_hours || 'all',
    buildings: report?.building_ids.map((id) => convertIntToUuid(id)),
  };

  useEffect(() => {
    chartList?.count &&
      setCharts(chartList?.results.map((c) => chartToModel(c)) ?? []);
  }, [chartList, areChartsLoading]);

  const addChart = () => {
    onCreate(defaultNewChart).then((response) => {
      return setCharts([...charts, chartToModel(response, true)]);
    });
  };

  const onDeleteChart = (chartId: string) => {
    setCharts((prevCharts) => prevCharts.filter((c) => c.id !== chartId));
    onDelete({ id: chartId });
  };

  const onDuplicateChart = (chartId: string) => {
    const chartToDuplicate = modelToChart(
      charts.find((chart) => chart.id === chartId) as ChartModel
    );
    onCreate({
      configuration: {
        ...chartToDuplicate.configuration,
        title: `${chartToDuplicate.configuration.title} - ${t('COPY')}`,
      },
    }).then((response) => {
      return setCharts([...charts, chartToModel(response, true)]);
    });
  };

  const onOptionsChange = (
    chartId: string,
    options: ConfigurableChartOptions
  ) => {
    setCharts((prevCharts) =>
      prevCharts.map((c) => (c.id === chartId ? { ...c, options } : c))
    );
    onUpdate(chartId, {
      configuration: {
        title: charts.find((chart) => chart.id === chartId)?.title,
        dimension: options.dimension,
        measures: options.measures,
        type: options.type,
        granularity: options.granularity,
      },
    } as CreateChartBody);
  };

  const onAreOptionsOpenChange = (chartId: string, isOpen: boolean) => {
    setCharts((prevCharts) =>
      prevCharts.map((c) =>
        c.id === chartId ? { ...c, areOptionsOpen: isOpen } : c
      )
    );
  };

  const onTitleChange = (chartId: string, title: string) => {
    setCharts((prevCharts) =>
      prevCharts.map((c) => (c.id === chartId ? { ...c, title } : c))
    );
    onUpdate(chartId, {
      configuration: {
        ...charts.find((chart) => chart.id === chartId)?.options,
        title: title,
      },
    } as CreateChartBody);
  };

  return (
    <PageContainer
      isLoading={isLoading}
      headerProps={{
        primary: report?.name ?? '',
        backButton: { href: '/reports', label: t('All reports') },
        actions: report && (
          <>
            <IconButton
              color="primary"
              data-cy="edit-report-button"
              onClick={() => setIsEditOpen(true)}
            >
              <EditIcon />
            </IconButton>
            <ExportReportButton reportName={report.name} />
          </>
        ),
        secondary: report && <ReportTitleSecondary report={report} />,
      }}
    >
      {report && (
        <ReportConfigurationEditModal
          report={report}
          isOpen={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          onSave={mutateReport}
        />
      )}
      {charts.map((chart) => (
        <Box key={chart.id}>
          <ConfigurableChart
            canvasHeight={400}
            title={chart.title}
            titleVariant="h3"
            filters={chartFilters}
            options={chart.options}
            areOptionsOpen={chart.areOptionsOpen}
            onAreOptionsOpenChange={(isOpen) =>
              onAreOptionsOpenChange(chart.id, isOpen)
            }
            onOptionsChange={(newOptions) =>
              onOptionsChange(chart.id, newOptions)
            }
            onTitleChange={(newTitle) => onTitleChange(chart.id, newTitle)}
            id={chart.id}
            onDeleteChart={() => onDeleteChart(chart.id)}
            onDuplicateChart={() => onDuplicateChart(chart.id)}
            showHeadlines
          />
        </Box>
      ))}
      <AddChartCard onClick={addChart} isCreating={isCreating} />
    </PageContainer>
  );
};
