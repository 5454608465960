import { useTranslation } from 'react-i18next';

import { DropdownInput } from 'design-system/components';
import { availableMeasures } from 'features/reports/utils';
import { registerTranslationKey } from 'packages/i18n';

interface MeasuresPicker {
  value: string[];
  onChange: (newValue: string[]) => void;
}

// Get only measures with the same format
const getSelectableMeasures = (selectedMeasures: string[]) => {
  if (selectedMeasures.length === 0) {
    return availableMeasures;
  }
  const selectedMeasuresFormat = selectedMeasures.flatMap((measure) =>
    availableMeasures
      .filter((availableMeasure) => availableMeasure.id === measure)
      .map((measure) => measure.format)
  );

  return availableMeasures.map((measure) => {
    return {
      ...measure,
      disabledReason: !selectedMeasuresFormat.includes(measure.format)
        ? registerTranslationKey(
            'Only measures with the same unit can be selected together. Please remove other measures to select this one.',
            { ns: 'reports' }
          )
        : undefined,
    };
  });
};

export const MeasuresPicker = ({ value, onChange }: MeasuresPicker) => {
  const { t } = useTranslation('reports');
  return (
    <DropdownInput
      id="measures"
      label={t('Measures')}
      value={value}
      onChange={onChange}
      values={getSelectableMeasures(value)}
      supportsAll={false}
    />
  );
};
