const SETTINGS = {
  SMART_CLEANING_URL: 'https://demo-smart-cleaning.infogrid.io/',
  COREAPI_API_URL: 'https://demo-scp.infogrid.io/api/',
  AUTH0_DOMAIN: 'infogrid.eu.auth0.com',
  AUTH0_CLIENT_ID: 'Gb3Y3R80nDkJUWLak14rrSSIMbjDS4uI',
  AUTH0_AUDIENCE: 'https://office.app.infogrid.io',
  LEGACY_WEBCLIENT: 'https://app.infogrid.io',

  // TODO: Use Vite and dotenv for settings from env vars
};

export default Object.freeze(SETTINGS);
