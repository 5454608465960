import { SxProps } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownInput } from '../dropdown-input';

import { Building, useBuildings } from 'api/hierarchy';
import { convertUuidToInt } from 'cube';

interface BuildingPickerProps {
  label?: string;
  value?: Building[];
  onChange: (value: Building[]) => void;
  allLabel?: string;
  menuPosition?: 'left' | 'right';
  sx?: SxProps;
}

export const BuildingPicker = ({
  label,
  value,
  onChange,
  allLabel,
  menuPosition,
  sx,
}: BuildingPickerProps) => {
  const { t } = useTranslation();
  const { data: buildings = [], isLoading } = useBuildings();

  const mappedBuildings = useMemo(
    () =>
      buildings.map((b) => ({
        id: convertUuidToInt(b.id).toString(),
        name: b.name,
        floorCount: b.floorCount,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buildings.length]
  );

  const [selectedBuildings, setSelectedBuildings] = useState<string[]>(
    value?.map((v) => convertUuidToInt(v.id).toString()) ?? []
  );

  useEffect(() => {
    onChange(
      mappedBuildings.filter((b) =>
        selectedBuildings.includes(b.id.toString())
      ) ?? []
    );
  }, [selectedBuildings, mappedBuildings, onChange]);

  return (
    <DropdownInput
      id="buildings"
      label={label ?? t('Buildings')}
      allLabel={allLabel ?? t('All buildings')}
      value={selectedBuildings}
      onChange={setSelectedBuildings}
      isLoading={isLoading}
      values={mappedBuildings.map((building) => ({
        id: building.id.toString(),
        label: building.name,
      }))}
      menuPosition={menuPosition}
      sx={sx}
    />
  );
};
