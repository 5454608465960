import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TeamUser } from '../../../api/types';
import { useUsers } from '../../../api/user';
import { DropdownInput } from '../dropdown-input/dropdown-input';

interface UserPickerProps {
  label?: string;
  value?: TeamUser[];
  onChange?: (value?: TeamUser[]) => void;
  allLabel?: string;
  menuPosition?: 'left' | 'right';
}

export const UserPicker = ({
  label,
  value,
  onChange = () => {},
  allLabel,
  menuPosition,
}: UserPickerProps) => {
  const { t } = useTranslation();
  const { data: users, isLoading } = useUsers();

  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    value?.map((v) => v.id.toString()) ?? []
  );

  useEffect(() => {
    onChange(
      users?.results.filter((u) => selectedUsers.includes(u.id.toString())) ??
        []
    );
  }, [selectedUsers, users, onChange]);
  return (
    <DropdownInput
      id="users"
      label={label ?? t('Users')}
      allLabel={allLabel ?? t('All users')}
      value={selectedUsers}
      onChange={setSelectedUsers}
      isLoading={isLoading}
      values={
        users?.results.map(
          (user) =>
            ({
              id: user.id.toString(),
              label: user.name,
            }) ?? []
        ) ?? []
      }
      menuPosition={menuPosition}
    />
  );
};
