import { registerTranslationKey } from 'packages/i18n';

type ReportChartType = 'barLine' | 'line' | 'heatMap' | 'bar' | 'custom';
export type ReportTypeId =
  | 'cleaning-performance'
  | 'team-review'
  | 'space-usage'
  | 'roi'
  | 'custom';
export type ReportTypeValue = {
  id: ReportTypeId;
  label: string;
  title: string;
  description: string;
  chartType: ReportChartType;
};

export const ReportTypes: ReportTypeValue[] = [
  {
    id: 'cleaning-performance',
    label: registerTranslationKey('Cleaning Performance', { ns: 'reports' }),
    title: registerTranslationKey('Create cleaning performance report', {
      ns: 'reports',
    }),
    description: registerTranslationKey(
      'Report on tasks completed, time taken to clean different spaces.',
      { ns: 'reports' }
    ),
    chartType: 'barLine',
  },
  {
    id: 'team-review',
    label: registerTranslationKey('Team Review', { ns: 'reports' }),
    title: registerTranslationKey('Create team review report', {
      ns: 'reports',
    }),
    description: registerTranslationKey(
      'Look back on cleaning outcomes by Team and review optimisation opportunities.',
      { ns: 'reports' }
    ),
    chartType: 'line',
  },
  {
    id: 'space-usage',
    label: registerTranslationKey('Space Usage', { ns: 'reports' }),
    title: registerTranslationKey('Create space usage report', {
      ns: 'reports',
    }),
    description: registerTranslationKey(
      'Understand how buildings have been used and identify busy/quiet areas and times.',
      { ns: 'reports' }
    ),
    chartType: 'heatMap',
  },
  {
    id: 'roi',
    label: registerTranslationKey('ROI', { ns: 'reports' }),
    title: registerTranslationKey('Create ROI report', { ns: 'reports' }),
    description: registerTranslationKey(
      'Review how much time and money your operations have saved compared to traditional cleaning.',
      { ns: 'reports' }
    ),
    chartType: 'line',
  },
  {
    id: 'custom',
    label: registerTranslationKey('Custom', { ns: 'reports' }),
    title: registerTranslationKey('Create custom report', { ns: 'reports' }),
    description: registerTranslationKey(
      'Start with a blank report to create your own.',
      { ns: 'reports' }
    ),
    chartType: 'custom',
  },
];

export type ReportDateType = 'rolling' | 'fixed';
